import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Modal,
  ModalClose,
  ModalDialog,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/joy";
import * as React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PaymentService } from "../../services/payment.service";
import { Toggler } from "../utils";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import * as Hero from "@heroicons/react/24/outline";
import { theme } from "../Theme";
import { useQuery } from "@tanstack/react-query";
import { KycPaymentGatewayService } from "../../services/kyc-payment-gateway.service";
import { KycPaymentGatewayStatus } from "../../types/kyc";
import { formatCurrencyByCode } from "../../utils/formatPrice";
import { ApplicableAdminFeeTypes } from "../../types/applicable-admin-fee.enum";
interface IProps {
  open: boolean;
  setOpenPaymentModal: any;
  handleContinue: any;
  handleSkip: any;
  control: any;
  setValue: any;
  onlinePaymentOnly?: boolean;
  paymentAmount?: number | null;
  paymentCurrency?: string;
  paymentCountry?: string;
  paymentMethods?: any;
  transactionType?: "credit" | "order";
  sellerId?: string | null;
  applicableAdminFee?: ApplicableAdminFeeTypes,
  handleChangePaymentFee?: (arg0: number) => void
}

const MethodIcons = [
  {
    name: "va",
    icon: (
      <Hero.CubeTransparentIcon
        style={{
          width: "1.5rem",
          height: "1.5rem",
          color: theme.colorSchemes.light.palette.neutral[600],
        }}
      />
    ),
  },
  {
    name: "e-wallet",
    icon: (
      <Hero.WalletIcon
        style={{
          width: "1.5rem",
          height: "1.5rem",
          color: theme.colorSchemes.light.palette.neutral[600],
        }}
      />
    ),
  },
  {
    name: "retail",
    icon: (
      <Hero.TagIcon
        style={{
          width: "1.5rem",
          height: "1.5rem",
          color: theme.colorSchemes.light.palette.neutral[600],
        }}
      />
    ),
  },
  {
    name: "qris",
    icon: (
      <Hero.QrCodeIcon
        style={{
          width: "1.5rem",
          height: "1.5rem",
          color: theme.colorSchemes.light.palette.neutral[600],
        }}
      />
    ),
  },
  {
    name: "credit-card",
    icon: (
      <Hero.CreditCardIcon
        style={{
          width: "1.5rem",
          height: "1.5rem",
          color: theme.colorSchemes.light.palette.neutral[600],
        }}
      />
    ),
  },
];

const MethodDescriptions = [
  {
    name: "va",
    description:
      "You can use virtual account with selected bank to proceed the transaction",
  },
  {
    name: "e-wallet",
    description:
      "You can choose payment with e-wallet to proceed the transaction",
  },
  {
    name: "retail",
    description:
      "You can go to the near retail store to proceed the transaction",
  },
  {
    name: "qris",
    description: "You can scan this QR code to proceed the transaction",
  },
  {
    name: "credit-card",
    description:
      "You can use a credit or debit card to proceed the transaction",
  },
];

const MethodIcon = ({ method }: { method: string }) => {
  return (
    MethodIcons.find((m) => m.name === method)?.icon ?? (
      <Hero.TagIcon style={{ width: "1.5rem", height: "1.5rem" }} />
    )
  );
};
export default function PaymentModal({
  open,
  setOpenPaymentModal,
  handleContinue,
  handleSkip,
  control,
  setValue,
  onlinePaymentOnly = false,
  paymentAmount = 0,
  paymentCurrency = "IDR",
  paymentMethods = null,
  transactionType = "order",
  applicableAdminFee = ApplicableAdminFeeTypes.CHARGE_TO_SELLER,
  handleChangePaymentFee = () => { },
  sellerId
}: IProps) {
  console.log("Amount", paymentAmount)
  const { t } = useTranslation();
  const { data, refetch } = useQuery({
    queryKey: ["kyc-payment-gateway-status-payment"],
    queryFn: () => KycPaymentGatewayService.getVerificationStatus(sellerId),
  })
  const [adminfee, setAdminFee] = React.useState<number>(0)
  var [availableOnlinePayments, setAvailableOnlinePayments] =
    React.useState<any>([]);
  if (
    paymentMethods &&
    import.meta.env.VITE_ONLINE_PAYMENT_ENABLED === "true"
  ) {
    var [availableOnlinePayments, setAvailableOnlinePayments] =
      React.useState<any>(paymentMethods);
  }

  React.useEffect(() => {
    if (
      import.meta.env.VITE_ONLINE_PAYMENT_ENABLED === "true" &&
      !paymentMethods
    ) {
      PaymentService.getAvailbleOnlinePaymentMethods(paymentCurrency).then((res) => {
        setAvailableOnlinePayments(res.data);
      });
    }
    refetch()
  }, []);

  const handleCalculatePaymentFee = (method: string) => {
    console.log("METHOD", method)
    if (applicableAdminFee === ApplicableAdminFeeTypes.CHARGE_TO_SELLER) {
      handleChangePaymentFee?.(0)
      return
    }
    const methodAndOptions = method.split(":")
    const selectedMethod = availableOnlinePayments.find((item: any) => item.method === methodAndOptions[0])
    console.log("FEE", selectedMethod, method)
    if (selectedMethod) {
      // Use method fee first
      var fee = selectedMethod.feeType === "amount" ? selectedMethod.fee : (selectedMethod.fee / 100) * (paymentAmount ?? 0)
      if (methodAndOptions.length == 2) {
        const optionsFee = selectedMethod.options.find((op: any) => op.code === methodAndOptions[1])
        // If there is an option fee, ignore the method fee
        if (optionsFee && optionsFee.fee) {
          fee = optionsFee.feeType === "amount" ? optionsFee.fee : (optionsFee.fee / 100) * (paymentAmount ?? 0)
        }
      }
      setAdminFee(fee)
      handleChangePaymentFee?.(fee)
      return
    }
    setAdminFee(0)
    handleChangePaymentFee?.(0)
  }
  return (
    <React.Fragment>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpenPaymentModal(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ModalDialog
          layout="center"
          maxWidth={"lg"}
          sx={{
            width: "100%",
            maxWidth: "600px",
            borderRadius: "md",
            p: 2,
            overflow: "scroll",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Stack flex={1} spacing={4} mt={4} mb={2} px={4}>
            <Typography
              textColor={"primary.solidActiveBg"}
              textAlign={"center"}
              level="h4"
              fontWeight={600}
            >
              {t("order.paymentModal.choosePayment")}
            </Typography>
            <form>
              <Stack sx={{ maxWidth: "100%" }}>
                {/* payment detail */}
                <Stack
                  sx={{ width: "100%" }}
                  justifyContent={"center"}
                  alignItems={"start"}
                >
                  <Typography fontSize={"md"} fontWeight={"lg"}>
                    {t("order.paymentModal.yourPurchase")}
                  </Typography>
                  <Box
                    sx={{ width: "100%" }}
                    padding={"1rem"}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                  >
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"space-between"}
                    >
                      <Typography fontSize={"sm"}>
                        {t("commons.order.subtotal")}
                      </Typography>
                      <Typography fontSize={"sm"}>
                        {formatCurrencyByCode(paymentAmount ?? 0, paymentCurrency)}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"space-between"}
                    >
                      <Typography fontSize={"sm"}>
                        Admin Fee
                      </Typography>
                      <Typography fontSize={"sm"}>
                        {formatCurrencyByCode(adminfee ?? 0, paymentCurrency)}
                      </Typography>
                    </Stack>
                    <Divider />
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"space-between"}
                    >
                      <Typography fontSize={"sm"} fontWeight={"lg"}>
                        {t("commons.order.grandTotal")}
                      </Typography>
                      <Typography fontSize={"sm"} fontWeight={"lg"}>
                        {formatCurrencyByCode((paymentAmount ?? 0) + adminfee, paymentCurrency)}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
                <br />
                <Typography fontSize={"md"} fontWeight={"lg"}>
                  {t("order.paymentModal.paymentMethod")}
                </Typography>
                <Typography fontSize={"sm"} fontWeight={"sm"}>
                  {t("order.paymentModal.paymentMethodPrompt")}
                </Typography>
                <br />
                <Controller
                  name={"paymentMethod"}
                  defaultValue={""}
                  control={control}
                  render={({ field: { value } }) => (
                    <FormControl>
                      <Card
                        sx={{
                          width: "100%",
                          backgroundColor: "#F9FAFB",
                          borderColor: "transparent",
                          borderRadius: "24px",
                        }}
                        variant="outlined"
                      >
                        <RadioGroup
                          defaultValue="medium"
                          name="radio-buttons-group"
                          onChange={(_event) => {
                            setValue("paymentMethod", _event.target.value);
                            handleCalculatePaymentFee(_event.target.value);
                          }}
                          value={value}
                        >
                          {(data?.status === KycPaymentGatewayStatus.VALIDATED || transactionType === "credit") &&
                            availableOnlinePayments &&
                            availableOnlinePayments
                              .filter((p: any) => p.isActive)
                              .map((item: any) => (
                                <>
                                  {item?.options &&
                                    item?.options?.length > 0 ? (
                                    <>
                                      <Toggler
                                        renderToggle={({
                                          open,
                                          setOpen,
                                          sxClasses,
                                        }) => (
                                          <Stack
                                            direction={"row"}
                                            justifyContent={"space-between"}
                                            onClick={() => setOpen(!open)}
                                            sx={sxClasses}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                alignItems: "start",
                                                justifyContent: "center",
                                                gap: 1,
                                              }}
                                            >
                                              {item.icon
                                                ? <img
                                                  src={item.icon}
                                                  style={{
                                                    width: "2rem",
                                                    height: "2rem",
                                                    backgroundColor:
                                                      "#F9FAFB",
                                                    objectFit:
                                                      "contain",
                                                  }}
                                                />
                                                : <MethodIcon
                                                  method={item.method}
                                                />}
                                              <div>
                                                <Typography
                                                  sx={sxClasses}
                                                  fontSize={"sm"}
                                                  fontWeight={"lg"}
                                                  onClick={() => setOpen(!open)}
                                                >
                                                  {item.name}
                                                </Typography>
                                                <Typography
                                                  fontSize={"sm"}
                                                  fontWeight={"sm"}
                                                >
                                                  {MethodDescriptions.find(
                                                    (desc) =>
                                                      desc.name === item.method
                                                  )?.description ?? ""}
                                                </Typography>
                                              </div>
                                            </Box>
                                            <KeyboardArrowDownOutlined
                                              sx={{
                                                transform: open
                                                  ? "rotate(180deg)"
                                                  : "none",
                                              }}
                                            />
                                          </Stack>
                                        )}
                                      >
                                        <Stack
                                          marginLeft={"2rem"}
                                          marginBottom={"1rem"}
                                          spacing={2}
                                          marginTop={"1rem"}
                                        >
                                          {item.options
                                            .filter((o: any) => o.isActive)
                                            .map((option: any) => (
                                              <>
                                                <Radio
                                                  value={`${item.method}:${option.code}`}
                                                  label={
                                                    <Typography
                                                      startDecorator={
                                                        <img
                                                          src={option.icon?.includes("http") ? option.icon : `${import.meta.env
                                                            .VITE_SPACES_HOST
                                                            }${option.icon}`}
                                                          style={{
                                                            width: "2rem",
                                                            height: "2rem",
                                                            backgroundColor:
                                                              "#F9FAFB",
                                                            objectFit:
                                                              "contain",
                                                          }}
                                                        />
                                                      }
                                                      color="neutral"
                                                      fontSize={"sm"}
                                                      fontWeight={"lg"}
                                                    >
                                                      {option.name}
                                                    </Typography>
                                                  }
                                                  color="neutral"
                                                  sx={{
                                                    flexDirection:
                                                      "row-reverse",
                                                  }}
                                                  slotProps={{
                                                    input: {
                                                      "aria-describedby":
                                                        "ep-helper-text",
                                                    },
                                                  }}
                                                />
                                              </>
                                            ))}
                                        </Stack>
                                      </Toggler>
                                      <Divider />
                                    </>
                                  ) : (
                                    <>
                                      <Radio
                                        value={item.method}
                                        color="neutral"
                                        slotProps={{
                                          input: {
                                            "aria-describedby":
                                              "ep-helper-text",
                                          },
                                        }}
                                        sx={{ flexDirection: "row-reverse" }}
                                        label={
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "start",
                                              gap: 1,
                                            }}
                                          >
                                            {item.icon
                                              ? <img
                                                src={item.icon}
                                                style={{
                                                  width: "2rem",
                                                  height: "2rem",
                                                  backgroundColor:
                                                    "#F9FAFB",
                                                  objectFit:
                                                    "contain",
                                                }}
                                              />
                                              : <MethodIcon
                                                method={item.method}
                                              />}
                                            <div>
                                              <Typography
                                                color="neutral"
                                                fontSize={"sm"}
                                                fontWeight={"lg"}
                                              >
                                                {item.name}
                                              </Typography>
                                              <br />
                                              <Typography
                                                color="neutral"
                                                fontSize={"sm"}
                                                fontWeight={"sm"}
                                              >
                                                {MethodDescriptions.find(
                                                  (desc) =>
                                                    desc.name === item.method
                                                )?.description ?? ""}
                                              </Typography>
                                            </div>
                                          </Box>
                                        }
                                      />
                                      <br />
                                      <Divider />
                                    </>
                                  )}
                                  <br />
                                </>
                              ))}
                          {!onlinePaymentOnly && (
                            <>
                              <Radio
                                value="loan-payment"
                                label={
                                  <>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "start",
                                        gap: 1,
                                      }}
                                    >
                                      <Hero.CreditCardIcon
                                        style={{
                                          width: "1.5rem",
                                          height: "1.5rem",
                                          color:
                                            theme.colorSchemes.light.palette
                                              .neutral[600],
                                        }}
                                      />
                                      <div>
                                        <Typography
                                          color="neutral"
                                          fontSize={"sm"}
                                          fontWeight={"lg"}
                                        >
                                          {" "}
                                          {t("order.paymentModal.loanLabel")}
                                        </Typography>
                                        <br />
                                        <Typography
                                          color="neutral"
                                          fontSize={"sm"}
                                          fontWeight={"sm"}
                                        >
                                          {t("order.paymentModal.loanDesc")}
                                        </Typography>
                                      </div>
                                    </Box>
                                    <br />
                                    <Divider />
                                  </>
                                }
                                sx={{ flexDirection: "row-reverse" }}
                                color="neutral"
                                slotProps={{
                                  input: {
                                    "aria-describedby": "rfl-helper-text",
                                  },
                                }}
                              />
                              <br />
                              <Radio
                                value="offline-payment"
                                color="neutral"
                                slotProps={{
                                  input: {
                                    "aria-describedby": "op-helper-text",
                                  },
                                }}
                                sx={{ flexDirection: "row-reverse" }}
                                label={
                                  <>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "start",
                                        gap: 1,
                                      }}
                                    >
                                      <Hero.CreditCardIcon
                                        style={{
                                          width: "1.5rem",
                                          height: "1.5rem",
                                          color:
                                            theme.colorSchemes.light.palette
                                              .neutral[600],
                                        }}
                                      />
                                      <div>
                                        <Typography
                                          color="neutral"
                                          fontSize={"sm"}
                                          fontWeight={"lg"}
                                        >
                                          {" "}
                                          {t("order.paymentModal.offlineLabel")}
                                        </Typography>
                                        <br />
                                        <Typography
                                          color="neutral"
                                          fontSize={"sm"}
                                          fontWeight={"sm"}
                                        >
                                          {t("order.paymentModal.offlineDesc")}
                                        </Typography>
                                      </div>
                                    </Box>
                                    <br />
                                    <Divider />
                                  </>
                                }
                              />
                            </>
                          )}
                        </RadioGroup>
                      </Card>
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack
                spacing={1}
                mt={6}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Button
                  sx={{ maxWidth: "200px" }}
                  fullWidth
                  variant="outlined"
                  onClick={handleSkip}
                >
                  {t("commons.cancelBtn")}
                </Button>
                <Button
                  sx={{ maxWidth: "200px" }}
                  fullWidth
                  size="lg"
                  onClick={handleContinue}
                >
                  {t("commons.continueBtn")}
                </Button>
              </Stack>
            </form>
          </Stack>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
