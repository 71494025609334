import { Typography } from "@mui/joy";
import Coin from "../../../../components/Icons/Coin";
import Reciept from "../../../../components/Icons/Reciept";
import { ICredit } from "../../../../types/credits";
import useContainer from "./useContainer";
import PaymentModal from "../../../../components/payments/PaymentModal";
import PaymentInfoModal from "../../../../components/payments/PaymentInfoModal";
import { useTranslation } from "react-i18next";

interface IProps {
  credit: ICredit;
}

export function CreditItem({ credit }: IProps) {
  const { t } = useTranslation();
  const {
    byNowHandler,
    isPending,
    showPaymentModal,
    setShowPaymentModal,
    control,
    setValue,
    showPaymentInfoModal,
    setShowPaymentInfoModal,
    paymentInfo,
  } = useContainer();
  return (
    <>
      <div
        key={credit.id}
        className="flex items-center justify-between border border-[rgba(99, 107, 116, 0.3)] rounded-2xl p-4"
      >
        <div className="flex flex-col gap-2">
          {credit.discount > 0 && (
            <div className="bg-[#CDD7E1] px-2 gap-1 flex items-center rounded-full h-6 w-fit">
              <Reciept />
              <Typography fontWeight={700} fontSize={12}>
                {t("credits.creditSection.creditItem.discount")}
              </Typography>
            </div>
          )}
          <div className="flex items-center gap-2">
            <Coin />
            <Typography fontWeight={600} fontSize={16}>
              {credit.noOfCredits}
            </Typography>
          </div>
          <div className="flex items-center">
            {credit.discount > 0 && (
              <div className="flex items-center text-[#9FA6AD] gap-1 mr-[4px] line-through">
                {credit.isCurrencyPrefix && (
                  <Typography fontWeight={400} fontSize={14}>
                    {credit.currency}
                  </Typography>
                )}
                <Typography fontWeight={400} fontSize={14}>
                  {credit.price}
                </Typography>
                {!credit.isCurrencyPrefix && (
                  <Typography fontWeight={400} fontSize={14}>
                    {credit.currency}
                  </Typography>
                )}
              </div>
            )}
            <div className="flex items-center gap-1">
              {credit.isCurrencyPrefix && (
                <Typography fontWeight={700} fontSize={18}>
                  {credit.currency}
                </Typography>
              )}
              <Typography fontWeight={700} fontSize={18}>
                {credit.discount > 0
                  ? Math.round(
                      credit.price * (1 - credit.discount / 100) * 100
                    ) / 100
                  : credit.price}
              </Typography>
              {!credit.isCurrencyPrefix && (
                <Typography fontWeight={700} fontSize={18}>
                  {credit.currency}
                </Typography>
              )}
            </div>
          </div>
        </div>
        <button
          disabled={isPending}
          onClick={() => {
            // byNowHandler(credit)
            setShowPaymentModal(true);
          }}
          className="bg-[#499873] py-[10px] px-4 rounded-full"
        >
          <Typography fontWeight={600} fontSize={16} textColor={"common.white"}>
            {t("credits.creditSection.creditItem.buyNowBtn")}
          </Typography>
        </button>
      </div>
      <PaymentModal
        open={showPaymentModal}
        onlinePaymentOnly={true}
        handleContinue={() => {
          setShowPaymentModal(false);
          byNowHandler(credit);
        }}
        handleSkip={() => setShowPaymentModal(false)}
        control={control}
        setOpenPaymentModal={setShowPaymentModal}
        setValue={setValue}
        paymentCurrency={credit.currency}
        transactionType="credit"
        paymentAmount={
          credit.discount > 0
            ? Math.round(credit.price * (1 - credit.discount / 100) * 100) / 100
            : credit.price
        }
      />
      {paymentInfo && (
        <PaymentInfoModal
          open={showPaymentInfoModal}
          setOpenModal={setShowPaymentInfoModal}
          paymentInfo={paymentInfo}
          currency={credit.currency}
        />
      )}
    </>
  );
}
