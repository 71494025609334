import { Button } from "@mui/joy";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Helmet } from "react-helmet";
import OtpInput from "react-otp-input";
import { If, IfElse } from "../../components/Condition";
import Shared from "../../layouts/Shared";
import useContainer from "./useContainer";
import "./verify.css";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { Trans, useTranslation } from "react-i18next";

export default function Verify() {
  const { t } = useTranslation();

  const {
    phone,
    resendOTPCode,
    setOtpInput,
    formatTime,
    otpInput,
    maxTries,
    seconds,
    disabled,
    isError,
    isLoading,
  } = useContainer();

  return (
    <>
      <Shared sxProps={{ backgroundColor: "transparent" }} title="">
        <Helmet
          link={[{ href: "https://linkzasia.com/auth/otp", rel: "canonical" }]}
          meta={[{ content: "OTP", name: "description" }]}
          title="OTP - Linkz"
        />
        <Box paddingY={2}>
          <Typography
            textAlign={"center"}
            fontSize={"18px"}
            fontWeight={600}
            py={2}
            textColor={"primary.solidActiveBg"}
          >
            {t("landing.verify.title")}
          </Typography>
          <Typography
            my={2}
            py={2}
            textColor={"text.primary"}
            fontSize={16}
            textAlign={"center"}
            fontWeight={600}
          >
            <Trans
              i18nKey={"landing.verify.prompt"}
              values={{ phoneNumber: phone }}
            >
              We’ve sent an SMS with an activation code
              <>
                <br />
              </>
              to your mobile no. {phone}
            </Trans>
          </Typography>
          <form>
            <Stack direction="row" justifyContent="center" py={2} pb={4}>
              <OtpInput
                inputType="number"
                containerStyle={{ width: "100%" }}
                value={otpInput}
                onChange={setOtpInput}
                numInputs={6}
                renderSeparator={<span className="w-2"></span>}
                renderInput={(props, i) => (                  
                  <input
                    id={"otp-input-" + i}
                    {...props}
                    disabled={disabled}
                    className={
                      isError
                        ? "focus:outline-none active:outline-none"
                        : "focus:outline-[#499873] active:outline-[#499873]"
                    }
                    style={{
                      textAlign: "center",
                      borderRadius: "8px",
                      border: `1px solid ${isError ? "#C41C1C" : "#CDD7E1"}`,
                      background: "#FFF",
                      boxShadow: "0px 1px 2px 0px rgba(21, 21, 21, 0.08)",
                      display: "flex",
                      width: "59.4px",
                      height: "64px",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      flexShrink: 0,
                    }}
                  />
                )}
              />
            </Stack>
            <If condition={isError}>
              <Typography
                textColor={"danger.500"}
                textAlign={"center"}
                fontSize={"16px"}
                fontWeight={400}
                pb={4}
              >
                {t("landing.verify.invalid")}
              </Typography>
            </If>
            <Stack flexDirection={"row"} alignItems={"center"}>
              <Stack flex={1} rowGap={1}>
                <Typography
                  fontSize={16}
                  textColor={"text.secondary"}
                  fontWeight={600}
                >
                  {t("landing.verify.requestAgain")}
                </Typography>
                <Typography
                  fontSize={16}
                  textColor={"text.secondary"}
                  fontWeight={600}
                >
                  {t("landing.verify.requestChances", { chances: maxTries })}
                  {maxTries === 0 && t("landing.verify.requestChancesNull")}
                </Typography>
              </Stack>
              <IfElse
                condition={seconds !== 0 && maxTries > 0}
                ifBlock={
                  <Typography
                    textColor={"text.primary"}
                    fontSize={"16px"}
                    fontWeight={600}
                  >
                    {formatTime()}
                  </Typography>
                }
                elseBlock={
                  <If condition={maxTries > 0}>
                    <Button
                      onClick={resendOTPCode}
                      size="lg"
                      sx={{ margin: "auto" }}
                      variant="plain"
                    >
                      {t("landing.verify.resendBtn")}
                    </Button>
                  </If>
                }
              />
            </Stack>
          </form>
        </Box>
      </Shared>
      <LoadingModal isLoading={isLoading} />
    </>
  );
}
