import { Box, Card, Option, Select, Stack, Typography } from "@mui/joy";
import { BarChart } from "@mui/x-charts";
import { useTranslation } from "react-i18next";
import LoadingModal from "../../../components/LoadingModal/LoadingModal";
import { getActiveBusiness } from "../../../store/session";
import { formatCurrency } from "../../../utils/formatPrice";
import useContainer from "./useContainer";

export default function DahsboardChart() {
  const {
    handleSelectChange,
    handleChange,
    totals,
    isLoading,
    state,
    data,
    xLabels,
  } = useContainer();
  const business: any = getActiveBusiness();
  const { t } = useTranslation();
  const { saleData, purchaseData, profitData } = data;
  const series = [
    ...(state["sale"] ? [{ data: saleData, id: "saleId" }] : []),
    ...(state["purchase"] ? [{ data: purchaseData, id: "purchaseId" }] : []),

    ...(state["profit"] ? [{ data: profitData, id: "profitId" }] : []),
  ];
  if (isLoading) {
    console.log({ isLoading });
    return <LoadingModal isLoading={isLoading} />;
  }
  return (
    <Stack gap={4} className="bg-[#FBFCFE] rounded-3xl p-6">
      <Stack gap={4}>
        <Typography fontSize={24} textColor={"text.primary"} fontWeight={700}>
          {t("dashboard.overview.title")}
        </Typography>

        <Select
          defaultValue="last-7-days"
          className="w-fit"
          onChange={handleSelectChange}
        >
          <Option value="last-7-days">
            {t("dashboard.overview.dateRange.options.last-7-days")}
          </Option>
          <Option value="last-7-weeks">
            {t("dashboard.overview.dateRange.options.last-7-weeks")}
          </Option>
          <Option value="last-7-months">
            {t("dashboard.overview.dateRange.options.last-7-months")}
          </Option>
        </Select>
        <div className="flex flex-col gap-3 md:items-center md:flex-row">
          <StatisticsCard
            label={t("dashboard.overview.dateRange.label.totalSales")}
            amount={`${formatCurrency(
              totals.saleTotal,
              business?.companyCountry
            )}`}
          />
          <StatisticsCard
            label={t("dashboard.overview.dateRange.label.totalPurchase")}
            amount={`${formatCurrency(
              totals.purchaseTotal,
              business?.companyCountry
            )}`}
          />
          <StatisticsCard
            label={t("dashboard.overview.dateRange.label.totalProfit")}
            amount={`${formatCurrency(
              totals.profitTotal,
              business?.companyCountry
            )}`}
          />
        </div>
      </Stack>
      <hr />
      <div>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <div className="flex flex-col gap-4 md:flex-row ">
            <Stack direction={"row"} alignItems={"center"} gap={"9px"}>
              <Box width={6} height={6} className="rounded-full bg-[#408565]" />
              <Typography fontSize={14} textColor={"neutral.500"}>
                {t("dashboard.overview.sales")}
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={"9px"}>
              <Box width={6} height={6} className="rounded-full bg-[#B41F1C]" />
              <Typography fontSize={14} textColor={"neutral.500"}>
                {t("dashboard.overview.purchase")}
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={"9px"}>
              <Box width={6} height={6} className="rounded-full bg-[#D48434]" />
              <Typography fontSize={14} textColor={"neutral.500"}>
                {t("dashboard.overview.grossProfit")}
              </Typography>
            </Stack>
          </div>
          <div className="flex flex-col gap-4 md:flex-row">
            <Stack direction={"row"} alignItems={"center"} gap={"9px"}>
              <input
                onChange={(e) => handleChange("sale", e.target.checked)}
                className="w-4 h-4"
                style={{ accentColor: "#475467" }}
                type="checkbox"
                id="gross-sale"
                checked={state["sale"]}
              />
              <label htmlFor="gross-sale">
                <Typography fontSize={14} textColor={"neutral.500"}>
                  {t("dashboard.overview.sales")}
                </Typography>
              </label>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={"9px"}>
              <input
                onChange={(e) => handleChange("purchase", e.target.checked)}
                className="w-4 h-4"
                style={{ accentColor: "#475467" }}
                type="checkbox"
                id="gross-purchase"
                checked={state["purchase"]}
              />
              <label htmlFor="gross-purchase">
                <Typography fontSize={14} textColor={"neutral.500"}>
                  {t("dashboard.overview.purchase")}
                </Typography>
              </label>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={"9px"}>
              <input
                onChange={(e) => handleChange("profit", e.target.checked)}
                className="w-4 h-4"
                style={{ accentColor: "#475467" }}
                type="checkbox"
                id="gross-profit"
                checked={state["profit"]}
              />
              <label htmlFor="gross-profit">
                <Typography fontSize={14} textColor={"neutral.500"}>
                  {t("dashboard.overview.grossProfit")}
                </Typography>
              </label>
            </Stack>
          </div>
        </Stack>
        <BarChart
          key={"bar-chart"}
          tooltip={{ trigger: "none" }}
          colors={[
            ...(state["sale"] ? ["#408565"] : []),
            ...(state["purchase"] ? ["#B41F1C"] : []),
            ...(state["profit"] ? ["#D48434"] : []),
          ]}
          height={400}
          series={series}
          xAxis={[{ data: xLabels, scaleType: "band" }]}
        />
      </div>
    </Stack>
  );
}

function StatisticsCard({ label, amount }: { label: string; amount: string }) {
  return (
    <div className="md:w-[292px] w-full">
      <Card>
        <Stack gap={3}>
          <Typography
            fontWeight={500}
            fontSize={16}
            textColor={"text.secondary"}
          >
            {label}
          </Typography>
          <Typography fontWeight={700} fontSize={24} textColor={"text.primary"}>
            {amount}
          </Typography>
        </Stack>
      </Card>
    </div>
  );
}
