import { useCallback, useState } from "react";
import { InventoryService } from "../../services/inventory.service";
import { CartDataType } from "../../types/cart";
import { CatalogType } from "../../types/catalog";
import { CatalogInventoryItemType, PaginationType } from "../../types/item";

const useContainer = () => {
  //#region ----- local states
  const [items, setItems] = useState<CatalogInventoryItemType[]>([]);
  const [catalog, setCatalog] = useState<CatalogType>();
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [confirmDuplicate, setConfirmDuplicate] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [unauthorized, setUnauthorized] = useState(false);
  const [currentItemsCount, setCurrentItemsCount] = useState(0);
  const [fillerItems, setFillerItems] = useState<number>(0);
  const [pagination, setPagination] = useState<PaginationType>({
    current: 1,
    limit: 10,
    max: 1,
  });
  const [cartItems, setCartItems] = useState<CartDataType | undefined>();
  const [showToast, setShowToast] = useState(false);

  //#region ----- misc
  const fetchAllItems = useCallback(
    ({
      catalogId,
      paging = true,
      limit = 12,
      offset = 0,
      searchTerm,
      inventoryType,
      isReset = true,
    }: {
      catalogId: string;
      paging?: boolean;
      limit?: number;
      offset?: number;
      searchTerm?: string | null;
      inventoryType?: string | null;
      isReset?: boolean;
    }) => {
      // console.log("IS THIS BEING TRIGGERED", offset);
      InventoryService.getCatalogItems({
        catalogId,
        paging,
        limit,
        offset,
        searchTerm,
        inventoryType,
      })
        .then((res) => {
          // console.log(res);
          if (isReset) {
            setItems(res.data);
            setHasMore(true);
          } else {
            setItems((prev) => [...prev, ...res.data]);
          }

          if (res.pagination) {
            setPagination(res.pagination);
          }

          if (res.data.length === 0) {
            setHasMore(false);
          }

          if (res.data.length % 3 !== 0) {
            setFillerItems(res.data.length % 3 > 1 ? 1 : 2);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    },
    [items, hasMore, pagination]
  );

  //#region ----- handler functions
  // TODO: function is outdated and unused
  const handlePaging = (action: string | number) => {
    const pagingConfig = {
      limit: 50,
      offset: 0,
    };

    if (typeof action === "string") {
      switch (action) {
        case "first":
          pagingConfig.offset = 0;
          break;

        case "previous":
          pagingConfig.offset = pagination.limit * (pagination.current - 2);
          break;

        case "next":
          pagingConfig.offset = pagination.limit * pagination.current;
          break;

        case "last":
          pagingConfig.offset =
            pagination.max * pagination.limit - pagination.limit;
          break;
      }
    } else {
      pagingConfig.offset = (action - 1) * pagination.limit;
    }

    if (catalog) {
      try {
        fetchAllItems({
          catalogId: catalog?.id,
          paging: true,
          ...pagingConfig,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return {
    fetchAllItems,
    handlePaging,
    items,
    setItems,
    catalog,
    setCatalog,
    pagination,
    setPagination,
    isLoading,
    setIsLoading,
    showAlert,
    setShowAlert,
    alertMessage,
    setAlertMessage,
    hasMore,
    setHasMore,
    showError,
    setShowError,
    unauthorized,
    setUnauthorized,
    currentItemsCount,
    setCurrentItemsCount,
    cartItems,
    setCartItems,
    showToast,
    setShowToast,
    confirmDelete,
    setConfirmDelete,
    showShareModal,
    setShowShareModal,
    confirmDuplicate,
    setConfirmDuplicate,
    fillerItems,
    setFillerItems,
  };
};

export default useContainer;
