import { LinkIcon } from "@heroicons/react/24/solid";
import {
  Box,
  Button,
  MenuItem,
  Snackbar,
  SnackbarOrigin,
  Typography,
  VariantProp,
} from "@mui/joy";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  url: string;
  variant?: string;
  isInList?: boolean;
  element?: ReactNode;
}

interface State extends SnackbarOrigin {
  open: boolean;
}

/* TODO: make smooth transition */
const CopyLinkButton = ({
  url,
  variant = "plain",
  isInList = false,
}: Props) => {
  const { t } = useTranslation();

  const [state, setState] = useState<State>({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState: SnackbarOrigin) => () => {
    navigator.clipboard.writeText(url);
    setState({ ...newState, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  if (variant === "dropdown") {
    return (
      <>
        <MenuItem
          sx={{
            width: "100%",
            gap: "1rem",
          }}
          onClick={handleClick({ vertical: "bottom", horizontal: "center" })}
        >
          <LinkIcon
            // color="primary"
            style={{
              width: 18,
              height: 18,
            }}
          />
          {t("commons.copyLinkBtn")}
        </MenuItem>

        <Snackbar
          variant="solid"
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
          sx={{
            width: "40%",
            maxWidth: 800,
          }}
        >
          <Box width={"100%"}>
            <Typography
              textAlign={"center"}
              textColor={"common.white"}
              fontSize={14}
            >
              {t("commons.copyLinkSnackbarTitle")}
            </Typography>
          </Box>
        </Snackbar>
      </>
    );
  }

  return (
    <>
      <Button
        type="button"
        variant={variant as VariantProp}
        startDecorator={
          <LinkIcon
            // color="primary"
            style={{
              width: 18,
              height: 18,
            }}
          />
        }
        // size="sm"
        className={`lg:mt-0 min-w-[125px] ${
          isInList &&
          "justify-start text-nowrap border-[#475467] text-[#475467] hover:text-white hover:bg-[#475467] hover:border-[#475467]"
        }`}
        onClick={handleClick({ vertical: "bottom", horizontal: "center" })}
      >
        <p className="whitespace-nowrap">{t("commons.copyLinkBtn")}</p>
      </Button>

      <Snackbar
        variant="solid"
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        sx={{
          width: "40%",
          maxWidth: 800,
        }}
      >
        <Box width={"100%"}>
          <Typography
            textAlign={"center"}
            textColor={"common.white"}
            fontSize={14}
          >
            {t("commons.copyLinkSnackbarTitle")}
          </Typography>
        </Box>
      </Snackbar>
    </>
  );
};
export default CopyLinkButton;
