import { privateAxios } from "../lib/api";
import {
  CatalogInventoryItemType,
  CreateItemType,
  IItemResponse,
  InventoryItemImages,
  ItemType,
  UpdateItemSKUType,
} from "../types/item";
import { ApiConstants } from "../utils/constants";

export class ItemService {
  static async getAllInventoryItems({
    catalogId,
    paging = false,
    limit = 50,
    offset = 0,
    inventoryType,
    searchTerm,
  }: {
    catalogId: string;
    paging?: boolean;
    limit?: number;
    offset?: number;
    inventoryType?: string | null;
    searchTerm?: string | null;
  }): Promise<IItemResponse> {
    const response = await privateAxios.get(
      `${ApiConstants.itemsURL}/catalog/${catalogId}`,
      {
        params: {
          paging,
          limit,
          offset,
          inventoryType,
          searchTerm,
        },
      }
    );

    return response.data as IItemResponse;
  }

  static async getInventoryItems({
    businessId,
    paging = false,
    limit = 50,
    offset = 0,
    inventoryType,
    sortBy = "id",
    sortDirection = "desc",
    searchTerm,
  }: {
    businessId: string;
    paging?: boolean;
    limit?: number;
    offset?: number;
    inventoryType?: string | null;
    sortBy?: string;
    sortDirection?: "asc" | "desc";
    searchTerm?: string | null;
  }): Promise<IItemResponse> {
    const response = await privateAxios.get(
      `${ApiConstants.itemsURL}/items/${businessId}`,
      {
        params: {
          paging,
          limit,
          offset,
          inventoryType,
          sortBy,
          sortDirection,
          searchTerm,
        },
      }
    );

    return response.data as IItemResponse;
  }

  static async getInventoryItemsByIds({
    ids,
  }: {
    ids: string[];
  }): Promise<CatalogInventoryItemType[]> {
    const response = await privateAxios.get(`${ApiConstants.itemsURL}/by-ids`, {
      params: {
        ids,
      },
    });

    return response.data as CatalogInventoryItemType[];
  }

  static async getInventoryItemsSKU({
    businessId,
  }: {
    businessId: string;
  }): Promise<ItemType[]> {
    const response = await privateAxios.get(`${ApiConstants.itemsURL}/sku`, {
      params: {
        businessId,
      },
    });

    return response.data as ItemType[];
  }

  static async getInventoryItemById({ id }: { id: string }): Promise<ItemType> {
    const response = await privateAxios.get(`${ApiConstants.itemsURL}/${id}`);

    return response.data as ItemType;
  }

  static async verifyItem({
    item,
    catalog,
  }: {
    item: string;
    catalog: string;
  }): Promise<Boolean> {
    const response = await privateAxios.get(
      `${ApiConstants.itemsURL}/verify-item?itemId=${item}&catalogId=${catalog}`
    );

    return response.data;
  }

  static async createInventoryItem({
    item,
  }: {
    item: CreateItemType;
  }): Promise<{ data: ItemType }> {
    const response = await privateAxios.post(`${ApiConstants.itemsURL}`, item);

    return response.data as { data: ItemType };
  }

  static async createBatchInventoryItems({
    items,
  }: {
    items: CreateItemType[];
  }): Promise<{ data: ItemType[]; duplicates: ItemType[] }> {
    const response = await privateAxios.post(
      `${ApiConstants.itemsURL}/batch`,
      items
    );

    return response.data as { data: ItemType[]; duplicates: ItemType[] };
  }

  static async patchInventoryItem({
    id,
    updateInventoryItemDto,
  }: {
    id: string;
    updateInventoryItemDto: CreateItemType;
  }): Promise<number> {
    const response = await privateAxios.patch(
      `${ApiConstants.itemsURL}/${id}`,
      updateInventoryItemDto
    );

    return response.data as number;
  }

  static async patchInventoryItemThumbnail({
    id,
    thumbnail,
    images,
  }: {
    id: string;
    thumbnail: string | null;
    images: InventoryItemImages;
  }): Promise<ItemType> {
    const response = await privateAxios.patch(
      `${ApiConstants.itemsURL}/${id}/thumbnail`,
      {
        thumbnail,
        images,
      }
    );

    return response.data as ItemType;
  }

  static async patchInventoryItemSKU({
    businessId,
    updateSkuDto,
  }: {
    businessId: string;
    updateSkuDto: UpdateItemSKUType[];
  }): Promise<ItemType[]> {
    const response = await privateAxios.patch(
      `${ApiConstants.itemsURL}/sku`,
      updateSkuDto,
      {
        params: {
          businessId,
        },
      }
    );

    return response.data as ItemType[];
  }

  static async patchInventoryItemQuantity({
    businessId,
    updateQuantityDto,
  }: {
    businessId: string;
    updateQuantityDto: { id: string; quantity: number | null }[];
  }): Promise<number> {
    const response = await privateAxios.patch(
      `${ApiConstants.itemsURL}/quantity`,
      updateQuantityDto,
      {
        params: {
          businessId,
        },
      }
    );

    return response.data as number;
  }

  // static async getSignedUrlForImageUpload({
  //   id,
  //   filename,
  // }: {
  //   id: string;
  //   filename: string;
  // }): Promise<ItemType> {
  //   const response = await privateAxios.get(
  //     `${ApiConstants.itemsURL}/${id}/signed-url?filename=${filename}`
  //   );

  //   return response.data as ItemType;
  // }

  static async getInventoryItemField({
    fields,
    businessId,
    inventoryType,
    searchTerm,
    isDistinct = true,
  }: {
    fields: string[];
    businessId: string;
    inventoryType?: string;
    searchTerm?: string;
    isDistinct?: boolean;
  }) {
    const response = await privateAxios.get(`${ApiConstants.itemsURL}/fields`, {
      params: {
        businessId,
        fields,
        inventoryType,
        searchTerm,
        isDistinct,
      },
    });

    return response.data;
  }

  static async getInventoryItemListByBusinessIdAsSeller(
    sellerId: string | null
  ) {
    return await privateAxios.get(
      `${ApiConstants.itemsURL}/seller/${sellerId}`
    );
  }

  static async getInventoryItemListByBusinessIdAsBuyer(
    buyerId: string,
    sellerId: string
  ) {
    return await privateAxios.get(
      `${ApiConstants.itemsURL}/buyer/${buyerId}/seller/${sellerId}`
    );
  }

  static async deleteInventoryItem({ id }: { id: string }): Promise<number> {
    const response = await privateAxios.delete(
      `${ApiConstants.itemsURL}/${id}`
    );

    return response.data as number;
  }
}
