import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Input,
  Sheet,
  Snackbar,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import XLSX from "xlsx-js-style";
import CreateCatalogModal from "../../components/Inventory/CreateCatalogModal";
import DownloadTemplateButton from "../../components/Inventory/Excel/DownloadTemplateButton";
import DuplicateSKUModal from "../../components/Inventory/Excel/DuplicateSKUModal";
import InventoryUploadDropzone from "../../components/Inventory/Excel/InventoryUploadDropzone";
import UploadTemplateButton from "../../components/Inventory/Excel/UploadTemplateButton";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { SuccessModal } from "../../components/SuccessModal";
import { ItemService } from "../../services/item.service";
import { getActiveBusiness, useAuthStore } from "../../store/session";
import { CreateItemType, ItemType } from "../../types/item";
import {
  convertExcelToInventoryItems,
  validateDuplicateSku,
  validateExcel,
} from "../../utils/excel";
import UploadImageBtn from "../../components/Inventory/Items/UploadImageBtn";
import NotFound from "../NotFound/NotFound";
import useContainer from "./useContainer";
import { IfElse } from "../../components/Condition";
import UpdateQuantityModal from "../../components/Inventory/UpdateQuantityModal";
import CreateInventoryDropzone from "../../components/Inventory/CreateInventoryDropzone";
import InfoSnackbar from "./components/InfoSnackbar";
import { getCountryAndCurrency, getCountryInfo } from "../../utils/country";
import PageTitleBox from "./components/PageTitleBox";
import DropdownButton from "../../components/buttons/DropdownButton";
import {
  BarsArrowDownIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import TablePagination from "./components/TablePagination";

function ListInventory() {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;
  const isFullView = currentPath.includes("full-view");

  const [searchParams, setSearchParams] = useSearchParams();
  const [flexiColumns, setFlexiColumns] = useState<string[]>();
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [showDuplicateModal, setShowDuplicateModal] = useState<{
    show: boolean;
    useDatabaseSku: boolean;
    onClick: (data: ItemType[]) => void;
  }>({ show: false, useDatabaseSku: true, onClick: () => {} });
  const [payload, setPayload] = useState<ItemType[]>([]);
  const [selectedQuantityItem, setSelectedQuantityItem] = useState<
    { show: boolean; item: ItemType } | undefined
  >();
  const [databaseSku, setDatabaseSku] = useState<string[]>([]);
  const [duplicateSKU, setDuplicateSKU] = useState<{
    processedData: CreateItemType[];
    duplicates: string[];
  }>();
  const {
    handlePaging,
    handleExcelUpload,
    handleError,
    handleUpdateSku,
    handleItemFlexiCols,
    fetchInventoryItems,
    isLoading,
    setIsLoading,
    showAlert,
    setShowAlert,
    alertMessage,
    setAlertMessage,
    showCreateModal,
    setShowCreateModal,
    pagination,
    setPagination,
    inventoryItems,
    setInventoryItems,
    searchTerm,
    setSearchTerm,
    showToast,
    setShowToast,
  } = useContainer();

  const { session } = useAuthStore.getState();

  const initiateInventoryItems = useCallback((businessId: string) => {
    ItemService.getInventoryItems({
      businessId: businessId,
      paging: true,
      limit: 50,
      offset: 0,
      sortBy: "sku",
    })
      .then((itemRes) => {
        setShowAlert(false);
        setInventoryItems(itemRes.data);
        if (itemRes.pagination) {
          setPagination(itemRes.pagination);
        }
        return ItemService.getInventoryItemField({
          fields: ["flexiColumns", "inventoryType"],
          businessId,
        });
      })
      .then((filterRes) => {
        const { allFlexiColumns } = handleItemFlexiCols(filterRes);
        setFlexiColumns(allFlexiColumns);

        const cats: string[] = [];
        for (const element of filterRes) {
          cats.push(element.inventoryType);
        }

        const uniqueValues = cats.filter(
          (item, index) => cats.indexOf(item) === index
        );
        setCategories(uniqueValues);

        return ItemService.getInventoryItemField({
          fields: ["id", "sku", "productName"],
          businessId,
          isDistinct: false,
        });
      })
      .then((skuRes) => {
        const allSku = skuRes.map((item: ItemType) => item.sku);
        setDatabaseSku(allSku);
        const validatedDuplicates = validateDuplicateSku(
          skuRes as CreateItemType[]
        );

        if (validatedDuplicates.length > 0 && verifySKUReminder()) {
          setDuplicateSKU({
            processedData: skuRes as CreateItemType[],
            duplicates: validatedDuplicates,
          });
          setShowDuplicateModal({
            show: true,
            useDatabaseSku: false,
            onClick: (data: ItemType[]) => {
              setIsLoading(true);
              handleUpdateSku(businessId, data);
            },
          });
          localStorage.setItem(
            "duplicateSKUReminder",
            new Date().getTime().toString()
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  }, []);

  const query = searchParams.get("q");

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;
    if (businessId) {
      initiateInventoryItems(businessId);
      if (query && !searchTerm) {
        fetchInventoryItems({
          businessId,
          paging: true,
          limit: pagination.limit,
          offset: 0,
          sortBy: "sku",
          sortDirection: sortDirection,
          searchTerm: query,
          inventoryType: selectedFilter,
        });
      }
    }
  }, [getActiveBusiness()?.id]);

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;

    if (businessId) {
      if (query && !searchTerm) {
        return;
      }
      fetchInventoryItems({
        businessId,
        paging: true,
        limit: pagination.limit,
        offset: 0,
        sortBy: "sku",
        sortDirection: sortDirection,
        searchTerm: searchTerm,
        inventoryType: selectedFilter,
      });
    }
  }, [searchTerm, selectedFilter, sortDirection]);

  useEffect(() => {
    const category = searchParams.get("category");
    const _sortDirection = searchParams.get("sortDirection");
    if (category) {
      setSelectedFilter(category);
    }
    if (_sortDirection) {
      setSortDirection(_sortDirection as any);
    }
    if (query) {
      setSearchTerm(query);
    }
    return () => {};
  }, []);

  if (!session) {
    return <NotFound />;
  }

  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      setIsLoading(true);
      var reader = new FileReader();
      reader.readAsArrayBuffer(acceptedFiles[0]);
      reader.onload = function (event) {
        if (reader.result) {
          const fileContent = event.target?.result;
          const workbook = XLSX.read(fileContent, { type: "binary" });

          const sheetName = workbook.SheetNames[0];
          const excelArray: Array<Array<string | number>> =
            XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
              header: 1,
              blankrows: false,
            });
          const validatedExcel = validateExcel(excelArray);

          if (validatedExcel.isValid) {
            const { data } = convertExcelToInventoryItems(
              excelArray,
              getActiveBusiness(),
              session?.account.id
            );

            if (data.length > 0) {
              const duplicates = validateDuplicateSku(data, databaseSku);
              if (duplicates.length > 0) {
                setDuplicateSKU({ processedData: data, duplicates });
                setShowDuplicateModal({
                  show: true,
                  useDatabaseSku: true,
                  onClick: (data: CreateItemType[]) => {
                    setIsLoading(true);
                    handleExcelUpload(data);
                  },
                });
              } else {
                handleExcelUpload(data);
              }
            } else {
              handleError(t("catalog.inventory.invalidExcel"));
            }
          } else {
            handleError(
              validatedExcel.message ?? t("catalog.inventory.invalidExcel")
            );
          }
        }
      };
    }
  };

  const verifySKUReminder = () => {
    const reminder = localStorage.getItem("duplicateSKUReminder");

    if (reminder) {
      const currentTime = new Date().getTime();
      const hoursDifference = (currentTime - +reminder) / (1000 * 60 * 60);

      return hoursDifference > 24;
    }

    return true;
  };

  const handleSelectAll = () => {
    const businessId = getActiveBusiness()?.id;

    if (businessId) {
      ItemService.getInventoryItemField({
        fields: ["id"],
        businessId,
        inventoryType: selectedFilter,
        searchTerm,
      })
        .then((res) => {
          const ids: string[] = res.map((i: { id: string }) => i.id);
          setSelectedItems(ids);
        })
        .catch((error) => console.error(error));
    }
  };

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    console.log(event.target.checked);
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, inventoryItems[index].id]);
    } else {
      setSelectedItems(
        selectedItems.filter((i) => i !== inventoryItems[index].id)
      );
    }
  };

  const handleCreateItem = () => {
    // had to cast to any to get bankInfo
    const business = getActiveBusiness() as any;
    if (!business) {
      return;
    }

    ItemService.createBatchInventoryItems({
      items: [
        {
          sku: "-",
          currency:
            business.bankInfo[0]?.currency ||
            getCountryInfo(business.countryCode, "LOCALE"),
          price: 0,
          quantity: 0,
          minimumQuantity: 1,
          productName: "-",
          productDescription: "-",
          inventoryType: "-",
          business: business.id ?? null,
          flexiColumns: { data: [] },
          isActive: true,
          isDeleted: false,
          createdBy: session?.account.id,
        },
      ],
    })
      .then((res) => {
        if (res) {
          window.location.href = `/client/inventory/${res.data[0].id}/edit?mode=create`;
        }
      })
      .catch((error) => console.error(error));
  };

  const { tableData } = handleItemFlexiCols(inventoryItems, flexiColumns);

  return (
    <React.Fragment>
      {!isLoading && (
        <Sheet
          sx={{
            backgroundColor: "transparent",
            overflowX: "hidden",
            padding: isFullView ? { xs: "1rem", lg: "4rem" } : 0,
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <IfElse
            condition={inventoryItems.length > 0 || searchTerm !== ""}
            ifBlock={
              <>
                <InfoSnackbar
                  message={
                    <Typography
                      textColor={"#101828"}
                      fontSize={16}
                      fontWeight={400}
                    >
                      {t("catalog.inventory.uploadRemarks")}
                    </Typography>
                  }
                />
                {/* download template */}
                <Grid container spacing={2} width={{ xs: "90vw", lg: "100%" }}>
                  <Grid xs={12} md={6}>
                    <span className="text-sm lg:text-base">
                      {t("catalog.inventory.downloadTemplatePrompt")}
                    </span>
                  </Grid>
                  <Grid xs={12} mdOffset={1} md={5}>
                    <div className="flex w-full overflow-auto gap-2 hide-scrollbar lg:justify-end">
                      <DownloadTemplateButton />
                      <UploadTemplateButton
                        onDrop={onDrop}
                        label={t("catalog.reuploadBtn2")}
                      />
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            elseBlock={
              <>
                <InfoSnackbar
                  message={
                    <Typography
                      textColor={"#101828"}
                      fontSize={16}
                      fontWeight={500}
                    >
                      <Trans
                        i18nKey={
                          "catalog.inventory.empty.inventoryCurrencyPrompt"
                        }
                        values={{
                          currency: getCountryAndCurrency(getActiveBusiness()),
                        }}
                      >
                        The currency for inventory is automatically set based on
                        the seller’s region: <b>Indonesia - IDR</b>
                      </Trans>
                    </Typography>
                  }
                />
                {/* no inventory title */}
                <PageTitleBox
                  title={t("breadcrumb.inventory")}
                  subtitle={t("catalog.inventory.empty.emptySubtitle")}
                />
              </>
            }
          />

          {inventoryItems.length > 0 || searchTerm !== "" ? (
            <Stack
              direction={"column"}
              gap={"2rem"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {/* searchbar */}
              <div className="w-full md:w-1/2 lg:mr-auto">
                <Input
                  defaultValue={searchTerm}
                  // variant="linkz-input-search"
                  size="sm"
                  name="searchFormData.search"
                  placeholder={t("catalog.inventory.searchPlaceholder")}
                  startDecorator={
                    <MagnifyingGlassIcon width={24} height={24} />
                  }
                  fullWidth
                  sx={{
                    flexGrow: 1,
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newParams = new URLSearchParams(searchParams);
                    const query = event.target.value;
                    if (query) {
                      newParams.set("q", query);
                    } else {
                      newParams.delete("q");
                    }
                    setSearchParams(newParams);
                    setSearchTerm(event.target.value);
                  }}
                />
              </div>

              {/* toolbar */}
              <Stack
                direction={{ xs: "column", md: "row" }}
                gap={{ xs: "10px", md: 0 }}
                justifyContent={"space-between"}
                width={{ xs: "90vw", md: "100%" }}
              >
                <div
                  className={`flex gap-2 w-[90vw] overflow-auto lg:w-full items-center hide-scrollbar`}
                >
                  <Stack>
                    <DropdownButton
                      element={
                        <Button
                          variant="outlined"
                          endDecorator={
                            <FilterAltOutlinedIcon
                              style={{ width: 18, height: 18 }}
                            />
                          }
                          className={`min-w-[8.6875rem] whitespace-nowrap`}
                        >
                          {t("catalog.inventory.allFilterBtn")}
                        </Button>
                      }
                      options={[
                        {
                          id: "all.filter",
                          action: () => {
                            const newParams = new URLSearchParams(searchParams);
                            newParams.delete("category");
                            setSearchParams(newParams);
                            setSelectedFilter("");
                          },
                          label: t("catalog.inventory.allFilterBtn"),
                        },
                        ...categories.map((cat) => {
                          return {
                            id: cat,
                            action: () => {
                              const newParams = new URLSearchParams(
                                searchParams
                              );
                              newParams.set(
                                "category",
                                encodeURIComponent(cat)
                              );
                              setSearchParams(newParams);
                              setSelectedFilter(cat);
                            },
                            label: cat.toLowerCase(),
                          };
                        }),
                      ]}
                    />
                  </Stack>
                  <Button
                    variant="outlined"
                    endDecorator={<BarsArrowDownIcon width={18} height={18} />}
                    className="min-w-[10.8125rem] whitespace-nowrap hover:text-white"
                    onClick={() => {
                      const newParams = new URLSearchParams(searchParams);
                      newParams.set(
                        "sortDirection",
                        sortDirection === "asc" ? "desc" : "asc"
                      );
                      setSearchParams(newParams);
                      setSortDirection(
                        sortDirection === "asc" ? "desc" : "asc"
                      );
                    }}
                  >
                    {t(
                      `catalog.inventory.sort${
                        sortDirection === "asc" ? "Ascending" : "Descending"
                      }Btn`
                    )}
                  </Button>
                  {!isFullView && (
                    <Link to={`/full-view/inventory`} target="_blank">
                      <Button
                        variant="outlined"
                        // startDecorator={<FileUploadOutlinedIcon />}
                        className="min-w-[9rem] whitespace-nowrap"
                      >
                        {t("catalog.inventory.expandViewBtn")}
                      </Button>
                    </Link>
                  )}
                  <Button
                    // startDecorator={<FileDownloadOutlinedIcon />}
                    disabled={!(selectedItems.length > 0)}
                    className="min-w-[9.2rem] whitespace-nowrap"
                    onClick={() => {
                      const tempPayload: ItemType[] = [];
                      for (let i = 0; i < selectedItems.length; i++) {
                        const item = inventoryItems.find(
                          (inventoryItem) =>
                            inventoryItem.id === selectedItems[i]
                        );

                        if (item) {
                          tempPayload.push(item);
                        }
                      }

                      setPayload(tempPayload);
                      setShowCreateModal(true);
                    }}
                  >
                    {t("catalog.inventory.createCatalogBtn")}
                  </Button>
                </div>

                <Button
                  startDecorator={<PlusIcon width={18} height={18} />}
                  className="ml-auto min-w-[12rem] whitespace-nowrap"
                  onClick={handleCreateItem}
                >
                  {t("catalog.inventory.createNewItemBtn")}
                </Button>
              </Stack>

              {/* items table */}
              <div
                className={`w-[90vw] ${
                  isFullView ? "lg:w-[90vw]" : "lg:w-[78vw]"
                } h-full lg:max-h-[27.5rem] overflow-auto border-2`}
              >
                <Table
                  borderAxis="x"
                  sx={{ "& th": { textAlign: "center" }, minWidth: "100%" }}
                >
                  <thead>
                    <tr key={"header"}>
                      <th key={"edit"} className="w-12 border-r">
                        {t("catalog.items.edit")}
                      </th>
                      <th key={"checkbox"} className="w-12">
                        <Checkbox
                          size="sm"
                          onChange={(event) => {
                            if (event.target.checked) {
                              handleSelectAll();
                            } else {
                              setSelectedItems([]);
                            }
                          }}
                        />
                      </th>
                      <th key={"index"} className="w-12">
                        {t("catalog.items.no")}
                      </th>
                      <th key={"picture"} className="w-24">
                        {t("catalog.items.picture")}
                      </th>
                      <th key={"sku"} className="w-36">
                        {t("catalog.items.sku")}
                      </th>
                      <th key={"productName"} className="w-48">
                        {t("catalog.items.productName")}
                      </th>
                      <th key={"quantity"} className="w-24">
                        {t("catalog.items.quantity")}
                      </th>
                      <th key={"currency"} className="w-24">
                        {t("catalog.items.currency")}
                      </th>
                      <th key={"price"} className="w-36">
                        {t("catalog.items.price")}
                      </th>
                      <th key={"unit_of_measure"} className="w-24">
                        {t("catalog.items.uom")}
                      </th>
                      <th key={"inventoryType"} className="w-36">
                        {t("catalog.items.category")}
                      </th>
                      {flexiColumns?.map((col) => (
                        <th key={col} className="w-28">
                          {col}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((d, index) => {
                      const isSelected = selectedItems.includes(
                        inventoryItems[index].id
                      );
                      return (
                        <tr
                          key={inventoryItems[index].id}
                          className={`${isSelected ? "bg-primary-50 " : ""}`}
                        >
                          <td className="text-center border-r">
                            <div className="w-full flex justify-center">
                              <Link
                                to={`${inventoryItems[index].id}/edit`}
                                className="hover:text-primary-500"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.4142 2.58579C16.6332 1.80474 15.3668 1.80474 14.5858 2.58579L7 10.1716V13H9.82842L17.4142 5.41421C18.1953 4.63316 18.1953 3.36683 17.4142 2.58579Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2 6C2 4.89543 2.89543 4 4 4H8C8.55228 4 9 4.44772 9 5C9 5.55228 8.55228 6 8 6H4V16H14V12C14 11.4477 14.4477 11 15 11C15.5523 11 16 11.4477 16 12V16C16 17.1046 15.1046 18 14 18H4C2.89543 18 2 17.1046 2 16V6Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </Link>
                            </div>
                          </td>
                          <td className="text-center">
                            <Checkbox
                              size="sm"
                              checked={isSelected}
                              onChange={(e) => handleChecked(e, index)}
                            />
                          </td>
                          <td className="text-center">
                            {(pagination.current - 1) * pagination.limit +
                              (index + 1)}
                          </td>
                          <td className="text-center">
                            <UploadImageBtn
                              item={inventoryItems[index]}
                              setIsLoading={setIsLoading}
                              setShowToast={setShowToast}
                              setAlertMessage={setAlertMessage}
                            />
                          </td>
                          {d.map((i, idx) => (
                            <td
                              key={
                                inventoryItems[index].id +
                                i.toString() +
                                "_col" +
                                index +
                                Math.random() * 695622
                              }
                              className="text-center break-all"
                            >
                              <IfElse
                                condition={idx === 2}
                                ifBlock={
                                  <IfElse
                                    condition={
                                      Number(i) <=
                                      (inventoryItems[index].lowStockQuantity ||
                                        0)
                                    }
                                    ifBlock={
                                      <button
                                        onClick={() => {
                                          setSelectedQuantityItem({
                                            show: true,
                                            item: inventoryItems[index],
                                          });
                                        }}
                                        className="bg-[#F7D5AC] w-fit h-fit mx-auto rounded-[4px] p-1"
                                      >
                                        {i}
                                      </button>
                                    }
                                    elseBlock={<>{i}</>}
                                  />
                                }
                                elseBlock={<>{i}</>}
                              />
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              {/* pagination */}
              {/* TODO: make this common component reusable */}
              <TablePagination
                handlePaging={handlePaging}
                pagination={pagination}
                setPagination={setPagination}
                withLimit
              />
            </Stack>
          ) : (
            <Stack
              direction={"row"}
              gap={"2rem"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <InventoryUploadDropzone onDrop={onDrop} />
              <Typography
                fontSize={18}
                fontWeight={600}
                sx={{
                  color: "#101828",
                }}
              >
                {t("landing.or")}
              </Typography>
              <CreateInventoryDropzone onClick={handleCreateItem} />
            </Stack>
          )}
        </Sheet>
      )}

      {/* modals */}
      <SuccessModal
        title={t("commons.successModalTitle")}
        content={alertMessage}
        open={showAlert}
      />
      <LoadingModal isLoading={isLoading} />
      <CreateCatalogModal
        show={showCreateModal}
        businessId={getActiveBusiness()?.id ?? ""}
        onClose={() => {
          setShowCreateModal(false);
        }}
        items={selectedItems}
        payload={payload}
      />
      {duplicateSKU && (
        <DuplicateSKUModal
          show={showDuplicateModal.show}
          useDatabaseSku={showDuplicateModal.useDatabaseSku}
          items={duplicateSKU}
          databaseSku={databaseSku}
          onClose={() => {
            setIsLoading(false);
            setShowDuplicateModal({
              show: false,
              useDatabaseSku: true,
              onClick: () => {},
            });
          }}
          onClick={showDuplicateModal.onClick}
        />
      )}
      <Snackbar
        variant="solid"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showToast}
        key={"asjfkangdlknakjrsh"}
        sx={{
          width: "40%",
          maxWidth: 800,
        }}
      >
        <Box width={"100%"}>
          <Typography
            textAlign={"center"}
            textColor={"common.white"}
            fontSize={14}
          >
            {alertMessage}
          </Typography>
        </Box>
      </Snackbar>
      {selectedQuantityItem?.item && (
        <UpdateQuantityModal
          show={selectedQuantityItem?.show ?? false}
          onClose={() => {
            setSelectedQuantityItem(undefined);
          }}
          item={[selectedQuantityItem?.item]}
        />
      )}
    </React.Fragment>
  );
}

export default ListInventory;
