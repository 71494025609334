import { useQuery } from "@tanstack/react-query";
import { BusinessService } from "../../services/business.service";
import { useAuthStore } from "../../store/session";

const useContainer = () => {
  const { session } = useAuthStore();
  const { isPending, data } = useQuery({
    queryKey: ["business-credits", session?.activeBusiness.id],
    queryFn: () => BusinessService.getBusinessCredit(),
  });

  return {
    isPending,
    data,
  };
};

export default useContainer;
