import { useEffect, useState } from "react";
import { FlexiColsType } from "../../../types/item";
import { Grid, Typography } from "@mui/joy";

interface Props {
  flexiCols: FlexiColsType | null;
  groupSize?: number;
}

const AttributesCollection = ({ flexiCols, groupSize = 6 }: Props) => {
  const [groupedCols, setGroupedCols] = useState<any[]>([]);

  useEffect(() => {
    if (flexiCols && flexiCols.data.length > 0) {
      const result = [];
      for (let i = 0; i < flexiCols.data.length; i += groupSize) {
        result.push(flexiCols.data.slice(i, i + groupSize));
      }

      setGroupedCols(result);
    }
  }, [flexiCols]);

  if (flexiCols) {
    return (
      <Grid container spacing={2} gap={1} sx={{ width: "100%" }}>
        {groupedCols.map((col, index) => (
          <Grid key={index} xs={12} lg={3}>
            {col.map((item: any, index: number) => {
              return (
                <div key={item.columnName + index} className="flex my-[2px]">
                  <Typography
                    fontSize={{ xs: 14, lg: 16 }}
                    fontWeight={500}
                    sx={{ color: "black" }}
                  >
                    {item.columnName}:{" "}
                  </Typography>

                  <span className="ml-auto lg:ml-0">
                    <Typography
                      fontSize={{ xs: 14, lg: 16 }}
                      fontWeight={500}
                      sx={{ color: "black" }}
                    >
                      {item.cellValue}
                    </Typography>
                  </span>
                </div>
              );
            })}
          </Grid>
        ))}
      </Grid>
    );
  }
};

export default AttributesCollection;
