import { privateAxios, publicAxios } from "../lib/api";
import { ApiConstants } from "../utils/constants";

export class AuthService {
  static async signin(payload: ISigninPayload) {
    return await publicAxios.post(ApiConstants.signinURL, payload);
  }

  static async signinSSO(payload: ISignInSSO) {
    return await publicAxios.post(ApiConstants.signinSSOURL, payload);
  }

  static async signup(payload: ISignupPayload) {
    return await publicAxios.post(ApiConstants.signupURL, payload);
  }

  static async signupInvitationWithRole(
    payload: ISignupInvitationWithRolePayload
  ) {
    return await publicAxios.post(
      ApiConstants.signupInvitationWithRoleURL,
      payload
    );
  }

  static async signupInvitationWithoutRole(
    payload: ISignupInvitationWithoutRolePayload
  ) {
    return await publicAxios.post(
      ApiConstants.signupInvitationWithoutRoleURL,
      payload
    );
  }

  static async signupInvitationBusinessConnect(
    payload: ISignupInvitationBusinessConnectPayload
  ) {
    return await publicAxios.post(
      ApiConstants.signupInvitationBusinessConnectURL,
      payload,
      { params: { token: payload.token } }
    );
  }

  static async signupSSO(payload: ISignupSSOPayload) {
    return await publicAxios.post(ApiConstants.signupSSOURL, payload);
  }

  static async signupSSOWithRoleInvitation(
    payload: ISignupSSOWithRoleInvitationPayload
  ) {
    return await publicAxios.post(
      ApiConstants.signupSSOInvitationWithRoleURL,
      payload
    );
  }

  static async signupSSOWithoutRoleInvitation(
    payload: ISignupSSOWithoutRoleInvitationPayload
  ) {
    return await publicAxios.post(
      ApiConstants.signupSSOInvitationWithoutRoleURL,
      payload
    );
  }

  static async signupSSOBusinessConnectInvitation(
    payload: ISignupSSOBusinessConnectPayload
  ) {
    return await publicAxios.post(
      ApiConstants.signupSSOBusinessConnectURL,
      payload,
      {
        params: { token: payload.token },
      }
    );
  }

  static async otpVerify(payload: IOtpVerificationPayload) {
    return await publicAxios.post(ApiConstants.otpVerificationURL, payload);
  }

  static async resendOTP(payload: IResendOtpVerificationPayload) {
    return await publicAxios.post(ApiConstants.resendOTPURL, payload);
  }

  static async forgotPassword(payload: IForgotPassword) {
    return await publicAxios.post(ApiConstants.forgotPassword, payload);
  }

  static async resendPasswordResetCode(payload: IForgotPassword) {
    return await publicAxios.post(
      ApiConstants.ResendPasswordResetCode,
      payload
    );
  }

  static async verifyResetPasswordCode(payload: IVerifyPasswordResetCode) {
    return await publicAxios.post(
      ApiConstants.verifyPasswordResetCode,
      payload
    );
  }

  static async resetPassword({
    payload,
    token,
  }: {
    payload: IResetPassword;
    token: string;
  }) {
    return await publicAxios.post(ApiConstants.resetPassword, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async invitationTokenCheck(payload: IInvitationTokenCheckPayload) {
    return await publicAxios.post(ApiConstants.invitationTokenCheck, payload);
  }

  static async changePassword(payload: IChangePassword) {
    return await privateAxios.post(ApiConstants.changePassword, payload);
  }

  static async chooseBusinessAfterLogin(businessId: string) {
    return await publicAxios.post(
      `${ApiConstants.chooseBusinessURL}/${businessId}`
    );
  }

  static async logout() {
    return await privateAxios.get(ApiConstants.logoutURL);
  }
}
