/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Dropdown from "@mui/joy/Dropdown";
import FormControl from "@mui/joy/FormControl";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { Fragment, useEffect, useState } from "react";

import {
  ChevronLeftOutlined,
  ChevronRightOutlined,
  ExpandMoreOutlined,
} from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import OrderListCards from "../../components/OrdersV2/OrderListCards";
import OrderListTable from "../../components/OrdersV2/OrderListTable";
import { Pagination } from "../../components/Pagination";
import DropdownButton from "../../components/buttons/DropdownButton";
import i18next from "../../i18n/config";
import { OrderService } from "../../services/order.service";
import { useAuthStore } from "../../store/session";
import { IOrderList, OrderStatusEnum, orderStatuses } from "../../types/order";
import { BrowserRouterConstants } from "../../utils/constants";
import { TrackerService } from "../../services/tracker.service";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import toast from "react-hot-toast";

const rows = [
  {
    id: "INV-1234",
    date: "Feb 3, 2023",
    status: "Refunded",
    orderType: "sales",
    customer: {
      initial: "O",
      name: "Olivia Ryhe",
      email: "olivia@email.com",
    },
  },
];

// function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// type Order = "asc" | "desc";

// function getComparator<Key extends keyof any>(
//   order: Order,
//   orderBy: Key
// ): (
//   // a: { [key in Key]: string | number | null },
//   // b: { [key in Key]: string | number | null }
//   a: { [key in Key]: any },
//   b: { [key in Key]: any }
// ) => number {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// // with exampleArray.slice().sort(exampleComparator)
// function stableSort<T>(
//   array: readonly T[],
//   comparator: (a: T, b: T) => number
// ) {
//   const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const ThreeDotsRowMenu = ({
  orderId,
  orderStatus,
  handleOnClick,
}: {
  orderId: string;
  orderStatus: string;
  handleOnClick: any;
}) => {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: "plain", color: "neutral", size: "sm" } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        <NavLink
          to={
            BrowserRouterConstants.ClientPrefix +
            BrowserRouterConstants.Orders +
            `/${orderId}`
          }
        >
          <MenuItem> {i18next.t("order.table.rowMenu.edit")}</MenuItem>
        </NavLink>
        <MenuItem onClick={() => handleOnClick("duplicate", orderId)}>
          {" "}
          {i18next.t("order.table.rowMenu.duplicate")}
        </MenuItem>

        {orderStatus === OrderStatusEnum.DRAFT ? (
          <>
            <Divider />

            <MenuItem
              color="danger"
              onClick={() => handleOnClick("delete", orderId)}
            >
              {i18next.t("order.table.rowMenu.delete")}
            </MenuItem>
          </>
        ) : orderStatus !== OrderStatusEnum.CANCELLED &&
          orderStatus !== OrderStatusEnum.COMPLETE ? (
          <>
            <Divider />
            <MenuItem
              color="primary"
              onClick={() => handleOnClick("cancel", orderId)}
            >
              Cancel
            </MenuItem>
          </>
        ) : (
          ""
        )}
      </Menu>
    </Dropdown>
  );
};

export default function ListOrders() {
  const { t } = useTranslation();
  // const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState<readonly (string | number | null)[]>(
    []
  );
  const [loading, setLoading] = useState<any>(true);
  const [open, setOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState<any>({
    open: false,
    title: "",
    content: "",
    onConfirm: () => {},
    onCancel: () => {},
  });
  const { session } = useAuthStore();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  // const [searchTerm, setSearchTerm] = useState<string>();
  const [orderList, setOrderList] = useState<IOrderList[] | any>([]);
  const [selectedOrderTypeFilter, setSelectedOrderTypeFilter] = useState<any>();
  const [selectedOrderStatusFilter, setSelectedOrderStatusFilter] =
    useState<any>();
  const [selectedOrderDateFilter, setSelectedOrderDateFilter] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    currentLast: 0,
    max: 0,
    limit: 20,
    offset: 0,
  });

  const paramStatus = searchParams.get("status");
  const paramDate = searchParams.get("date");
  const paramType = searchParams.get("type");
  const paramQ = searchParams.get("q");

  const initiateOrderForm = (orderType: "purchase" | "sales") => {
    const data = {
      grandTotalAmount: 0,
      paymentTerm: "",
      sellerId: null,
      sellerIds:
        orderType === "sales"
          ? {
              businessId: session?.activeBusiness.id,
              accountId: session?.account.id,
            }
          : null,
      buyerId: null,
      buyerIds:
        orderType === "purchase"
          ? {
              businessId: session?.activeBusiness.id,
              accountId: session?.account.id,
            }
          : null,
      invoiceNumber: "",
      createdById: session?.account.id || null,
      status: OrderStatusEnum.DRAFT,
    };
    // console.log("To initiate: ", data);

    /** Mixpanel Tracking */
    if (orderType === "sales") {
      TrackerService.track(
        TRACKER_CONSTANTS.ORDER.events.SalesOrderButtonClicked
      );
    } else if (orderType === "purchase") {
      TrackerService.track(
        TRACKER_CONSTANTS.ORDER.events.PurchaseOrderButtonClicked
      );
    }

    OrderService.initiateDraft(data)
      .then((res) =>
        navigate(
          BrowserRouterConstants.ClientPrefix +
            BrowserRouterConstants.Orders +
            `/${res.data.id}`
        )
      )
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (paramDate && !selectedOrderDateFilter) {
      setSelectedOrderDateFilter(paramDate);
    }
    if (paramStatus && !selectedOrderStatusFilter) {
      setSelectedOrderStatusFilter(paramStatus);
    }
    if (paramType && !selectedOrderTypeFilter) {
      setSelectedOrderTypeFilter(paramType);
    }
    if (paramQ && !searchTerm) {
      setSearchTerm(paramQ ?? "");
      getAndSetOrderList(paramQ ?? "");
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      session?.activeBusiness &&
      !(
        (paramQ && !searchTerm)
        // (paramDate && !selectedOrderDateFilter) ||
        // (paramType && !selectedOrderTypeFilter) ||
        // (paramStatus && !selectedOrderStatusFilter)
      )
    ) {
      getAndSetOrderList();
    }
  }, [session?.activeBusiness, pagination.offset, pagination.limit]);

  async function getAndSetOrderList(searchTerm?: string) {
    console.log("getAndSetOrderList", session?.activeBusiness);
    setLoading(true);
    try {
      const responseData = await OrderService.getOrderListByBusinessId({
        businessId: session?.activeBusiness.id,
        searchTerm: searchTerm,
        hasPagination: true,
        limit: pagination.limit,
        offset: pagination.offset,
      });
      console.log("Order List Response :::", responseData);
      const initialOrderList = responseData.items.map((item: any) => {
        const orderType =
          item.seller && item.seller.id === session?.activeBusiness?.id
            ? "sales"
            : item.buyer && item.buyer.id === session?.activeBusiness?.id
            ? "purchase"
            : "draft";
        return {
          ...item,
          orderType,
        };
      });
      console.log("Initial Order List :::", initialOrderList);

      setOrderList(initialOrderList);
      setPagination(responseData.pagination);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast(showErrorToast(error), ErrorToastConfig);
    }
  }

  const handleSearchSubmit = () => {};

  const handleOnClick = (action: any, orderId: any) => {
    switch (action) {
      case "duplicate":
        setConfirmationModal({
          open: true,
          title: "Are you sure you want to duplicate this order?",
          content: "",
          btnConfirmText: "Duplicate",
          onConfirm: () => duplicateOrder(orderId),
          onCancel: () => setConfirmationModal({ open: false }),
        });
        break;
      case "delete":
        setConfirmationModal({
          open: true,
          title: "Are you sure you want to delete this draft?",
          content: "This cannot be undone.",
          btnConfirmText: "Delete",
          onConfirm: () => deleteOrder(orderId),
          onCancel: () => setConfirmationModal({ open: false }),
        });
        break;
      case "cancel":
        setConfirmationModal({
          open: true,
          title: "Are you sure you want to cancel this order?",
          content: "This cannot be undone.",
          onConfirm: () => cancelOrder(orderId),
          onCancel: () => setConfirmationModal({ open: false }),
        });
        break;
      default:
        break;
    }
  };

  const duplicateOrder = async (orderId: string) => {
    setLoading(true);
    const duplicatedOrder = await OrderService.duplicateOrder(orderId);
    setLoading(false);
    setConfirmationModal({ open: false });
    if (duplicatedOrder)
      navigate(
        BrowserRouterConstants.ClientPrefix +
          BrowserRouterConstants.Orders +
          `/${duplicatedOrder.id}`
      );
  };

  const cancelOrder = async (orderId: string) => {
    console.log("To Cancel", orderId);
    setLoading(true);
    const deletedTimestamp = await OrderService.cancelOrder(orderId);
    setLoading(false);
    setConfirmationModal({ open: false });
    if (deletedTimestamp) getAndSetOrderList();
  };

  const deleteOrder = async (orderId: string) => {
    setLoading(true);
    const isDeleted = await OrderService.deleteOrder(orderId);
    setLoading(false);
    setConfirmationModal({ open: false });
    if (isDeleted) getAndSetOrderList();
  };

  const handleOnSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // setSearchTerm(e.target.value);
    const newParams = new URLSearchParams(searchParams);
    if (e.target.value) {
      newParams.set("q", e.target.value);
    } else {
      newParams.delete("q");
    }
    setSearchParams(newParams);
    getAndSetOrderList(e.target.value);
  };

  const handleOrderDateFilterChange = (
    _event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("date", newValue);
    } else {
      newParams.delete("date");
    }
    setSearchParams(newParams);
    setSelectedOrderDateFilter(newValue);
  };

  const filterByDate = (orderDate: any) => {
    const now = dayjs();
    switch (selectedOrderDateFilter) {
      case "today":
        return dayjs(orderDate).isSame(now, "day");
      case "yesterday":
        return dayjs(orderDate).isSame(now.subtract(1, "day"), "day");
      case "this_week":
        return dayjs(orderDate).isSame(now, "week");
      case "this_month":
        return dayjs(orderDate).isSame(now, "month");
      default:
        return true;
    }
  };

  const handleOrderStatusFilterChange = (
    _event: React.SyntheticEvent | null,
    newValue: { value: string; label: string; backendStatuses: [] } | null
  ) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("status", newValue.value);
    } else {
      newParams.delete("status");
    }
    setSearchParams(newParams);
    setSelectedOrderStatusFilter(newValue);
  };

  const handleOrderTypeFilterChange = (
    _event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    const newParams = new URLSearchParams(searchParams);
    if (newValue) {
      newParams.set("type", newValue);
    } else {
      newParams.delete("type");
    }
    setSearchParams(newParams);
    setSelectedOrderTypeFilter(newValue);
  };

  const filteredOrderList = orderList?.filter((order: any) => {
    console.log("Order to be filtered :::", selectedOrderStatusFilter, order);
    return (
      (selectedOrderTypeFilter
        ? order.orderType === selectedOrderTypeFilter
        : true) &&
      (selectedOrderStatusFilter
        ? selectedOrderStatusFilter.backendStatuses.includes(order.status)
        : true) &&
      filterByDate(order.updatedAt)
    );
  });

  const handlePaging = (action: string | number) => {
    console.log("Paging Action :::", action);
    if (typeof action === "string") {
      /** when pagination button is string */
      switch (action) {
        case "first":
          setPagination({ ...pagination, offset: 0 });
          break;

        case "previous":
          setPagination({
            ...pagination,
            offset: pagination.offset - pagination.limit,
          });
          break;

        case "next":
          setPagination({
            ...pagination,
            offset: pagination.offset + pagination.limit,
          });
          break;

        case "last":
          setPagination({
            ...pagination,
            offset:
              (Math.floor(pagination.max / pagination.limit) - 1) *
                pagination.limit +
              pagination.limit,
          });
          break;
      }
    } else {
      /** when pagination button is number */
      console.log({ pagination });
      setPagination({
        ...pagination,
        offset: (action - 1) * pagination.limit,
      });
    }
  };

  const renderFilters = () => (
    <Fragment>
      <Stack>
        <FormControl size="sm">
          {/* <FormLabel>Date</FormLabel> */}
          <Select
            value={selectedOrderDateFilter}
            size="sm"
            placeholder={t("order.filters.date.placeholder")}
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
            onChange={handleOrderDateFilterChange}
          >
            <Option value="">{t("order.filters.date.all")}</Option>
            <Option value="today">{t("order.filters.date.today")}</Option>
            <Option value="yesterday">
              {t("order.filters.date.yesterday")}
            </Option>
            <Option value="this_week">
              {t("order.filters.date.thisWeek")}
            </Option>
            <Option value="this_month">
              {t("order.filters.date.thisMonth")}
            </Option>
          </Select>
        </FormControl>
      </Stack>
      <Stack direction={"row"} gap={3}>
        <FormControl size="sm">
          {/* <FormLabel>Category</FormLabel> */}
          <Select
            value={selectedOrderTypeFilter}
            size="sm"
            placeholder={t("order.filters.type.all")}
            onChange={handleOrderTypeFilterChange}
          >
            <Option value="">{t("order.filters.type.all")}</Option>
            <Option value="sales">{t("order.filters.type.sales")}</Option>
            <Option value="purchase">{t("order.filters.type.purchase")}</Option>
          </Select>
        </FormControl>
        <FormControl size="sm">
          {/* <FormLabel>Customer</FormLabel> */}
          <Select
            value={selectedOrderStatusFilter}
            size="sm"
            placeholder={t("order.filters.status.all")}
            onChange={handleOrderStatusFilterChange}
          >
            <Option value="">{t("order.filters.status.all")}</Option>
            {orderStatuses.map((status) => (
              <Option value={status}>{status.label}</Option>
            ))}
          </Select>
        </FormControl>
        <FormControl size="sm">
          {/* <FormLabel>Category</FormLabel> */}
          <Select
            size="sm"
            placeholder={"Limit"}
            value={pagination.limit}
            onChange={(_, value) => {
              // const currentPage = Math.ceil(pagination.currentLast / pagination.limit);
              setPagination({
                ...pagination,
                limit: value,
                // offset: (currentPage - 1) * value,
              });
            }}
          >
            {[20, 50, 100].map((limit) => (
              <Option value={limit}>{limit}</Option>
            ))}
          </Select>
        </FormControl>

        {orderList && orderList.length > 0 && (
          <Stack direction={"row"} alignItems={"center"}>
            <Typography>
              {`${pagination.offset + 1} - ${pagination.currentLast}`}{" "}
              {t("commons.pagination.of") +
                " " +
                pagination.max +
                " " +
                t("commons.pagination.items")}
            </Typography>
            <IconButton
              key={"previous"}
              disabled={pagination.offset <= 0}
              type="button"
              onClick={() => {
                handlePaging("previous");
              }}
            >
              <ChevronLeftOutlined />
            </IconButton>
            <IconButton
              key={"next"}
              disabled={pagination.offset + pagination.limit >= pagination.max}
              type="button"
              onClick={() => {
                handlePaging("next");
              }}
            >
              <ChevronRightOutlined />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Fragment>
  );
  console.log("searchParams = ", searchParams.get("q") || "");
  return (
    <Fragment>
      {/* {isMobile ? "Mobile" : "Tablet"} */}
      {/** Search Filters - Mobile  */}
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 2,
          gap: 3,
          flexDirection: "column",
        }}
      >
        <Stack
          spacing={2}
          direction={"row"}
          component={"form"}
          onSubmit={(e) => {
            e.preventDefault();
            handleSearchSubmit();
          }}
        >
          <Input
            value={searchParams.get("q") || ""}
            variant="linkz-input-search"
            name="searchFormData.search"
            placeholder={t("order.searchPlaceholder")}
            startDecorator={<SearchIcon />}
            sx={{ flexGrow: 1 }}
            onChange={handleOnSearch}
          />
          <IconButton
            size="sm"
            variant="outlined"
            color="neutral"
            sx={{ flexGrow: 1 }}
            onClick={() => setOpen(true)}
          >
            <FilterAltIcon />
          </IconButton>
          <Modal open={open} onClose={() => setOpen(false)}>
            <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
              <ModalClose />
              <Typography id="filter-modal" level="h2">
                {t("order.filters.label")}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {renderFilters()}
                <Button
                  type="submit"
                  color="primary"
                  onClick={() => setOpen(false)}
                >
                  {t("commons.submitBtn")}
                </Button>
              </Sheet>
            </ModalDialog>
          </Modal>
        </Stack>
        <Stack>
          <DropdownButton
            element={
              <Button
                endDecorator={<ExpandMoreOutlined />}
                fullWidth
                // onClick={initiateOrderForm}
              >
                {t("order.createOrderBtn")}
              </Button>
            }
            options={[
              {
                label: t("order.createPurchaseOrder"),
                action: () => {
                  initiateOrderForm("purchase");
                },
              },
              {
                label: t("order.createSalesOrder"),
                action: () => {
                  initiateOrderForm("sales");
                },
              },
            ]}
          />
        </Stack>
      </Sheet>

      {/** Search Filters - Tablet and Above  */}
      <Stack
        className="SearchAndFilters-tabletUp"
        display={{ xs: "none", sm: "flex" }}
        direction={"row"}
        justifyContent={"space-between"}
        sx={{
          borderRadius: "sm",
          py: 2,
          gap: 2,
        }}
        onSubmit={(e) => {
          e.preventDefault();
          handleSearchSubmit();
        }}
      >
        <Stack flexGrow={1}>
          <FormControl sx={{ width: "100%", maxWidth: "460px" }}>
            {/* <FormLabel>Search for order</FormLabel> */}
            <Input
              value={searchParams.get("q") || ""}
              variant="linkz-input-search"
              placeholder={t("order.searchPlaceholder")}
              startDecorator={<SearchIcon />}
              onChange={handleOnSearch}
            />
          </FormControl>
        </Stack>
        <Stack>
          <DropdownButton
            element={
              <Button
                endDecorator={<ExpandMoreOutlined />}
                fullWidth
                // onClick={initiateOrderForm}
                id="btn-create-order"
              >
                {t("order.createOrderBtn")}
              </Button>
            }
            options={[
              {
                label: t("order.createPurchaseOrder"),
                id: "btn-create-purchase-order",
                action: () => {
                  initiateOrderForm("purchase");
                },
              },
              {
                label: t("order.createSalesOrder"),
                id: "btn-create-sales-order",
                action: () => {
                  initiateOrderForm("sales");
                },
              },
            ]}
          />
        </Stack>
      </Stack>
      <Stack
        className="SearchAndFilters-tabletUp"
        display={{ xs: "none", sm: "flex" }}
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        sx={{
          borderRadius: "sm",
          py: 2,
          gap: 2,
        }}
      >
        {renderFilters()}
      </Stack>

      {filteredOrderList && filteredOrderList?.length > 0 ? (
        isMobile ? (
          <OrderListCards
            // isPublicView={false}
            orderList={filteredOrderList}
            ThreeDotsRowMenu={ThreeDotsRowMenu}
            handleOnClick={handleOnClick}
          />
        ) : (
          <>
            <OrderListTable
              isPublicView={false}
              orderList={filteredOrderList}
              selected={selected}
              setSelected={setSelected}
              handleOnClick={handleOnClick}
              rows={rows}
              ThreeDotsRowMenu={ThreeDotsRowMenu}
            />

            {/** Bottom Pagination */}

            <Stack
              direction={"row"}
              width={"100%"}
              justifyContent={"center"}
              alignContent={"center"}
              gap={0}
              mt={8}
            >
              <Pagination
                count={Math.ceil(pagination.max / pagination.limit)}
                page={Math.ceil(pagination.currentLast / pagination.limit)}
                // defaultPage={pagination.currentLast}
                onChange={(_, value) => {
                  console.log(
                    "pagination value",
                    value,
                    pagination.currentLast
                  );
                  handlePaging(value);
                }}
                showFirstButton
                showLastButton
              />
            </Stack>
          </>
        )
      ) : (
        <Box textAlign={"center"} mt={4}>
          <Typography level={"h3"} mb={2}>
            {t("order.table.noOrders")}
          </Typography>
          <Typography> {t("order.table.noOrdersPrompt")}</Typography>
        </Box>
      )}

      {/** Modals */}
      <ConfirmationModal
        title={confirmationModal.title}
        content={confirmationModal.content}
        open={confirmationModal.open}
        btnConfirmText={confirmationModal.btnConfirmText}
        onCancel={confirmationModal.onCancel}
        onConfirm={confirmationModal.onConfirm}
      />
      <LoadingModal isLoading={loading} />
    </Fragment>
  );
}
