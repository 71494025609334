import { AccountService } from "../services/account.service";
import { IOrderRevision, OrderStatusEnum, TaxTypeEnum } from "../types/order";
import { DISCOUNT_TYPE } from "./constants";

export function getOrderType(order: any, businessId: string) {
  const orderType =
    order.seller && order.seller.id === businessId
      ? "sales"
      : order.buyer && order.buyer.id === businessId
      ? "purchase"
      : "draft";

  return orderType;
}

export async function verifyCanEdit(
  order: any,
  businessId: string,
  isOwner: boolean,
  accountId: string
) {
  return AccountService.getAccountInfo(order.createdBy).then((res) => {
    const creatorBusiness = res.data.role[0].business.id;
    const buyerUser = order.buyerUser?.id || null;
    const sellerUser = order.sellerUser?.id || null;

    if (
      order.createdBy === accountId ||
      buyerUser === accountId ||
      sellerUser === accountId
    ) {
      return true;
    }

    if (creatorBusiness === businessId && isOwner) {
      return true;
    } else {
      return false;
    }
  });
}

export function calcDiscountAmountByType(
  discount: number,
  discountType: string,
  amount: number
) {
  if (discountType === DISCOUNT_TYPE.percentage.value) {
    return amount * ((discount || 0) / 100);
  } else {
    return discount || 0;
  }
}

export function calcAmountByTax(tax: number, taxType: string, amount: number) {
  if (taxType === TaxTypeEnum.PPH_GROSS_UP) {
    return amount / (1 - (tax || 0) / 100);
  } else if (
    taxType === TaxTypeEnum.PPH_NPWP ||
    taxType === TaxTypeEnum.PPN_EXCLUSIVE ||
    taxType === TaxTypeEnum.GST
  ) {
    return amount + amount * ((tax || 0) / 100);
  } else {
    /** If Inclusive Tax or No Tax, return the price with discount only */
    return amount;
  }
}

/**
 * verify if order can be adjusted (prevents form editable on processing, etc.)
 * @param order
 * @returns boolean
 */
export function verifyAdjustmentOrder(order: any) {
  const formEditableOrderStatuses = [
    OrderStatusEnum.DRAFT,
    OrderStatusEnum.AWAITING_RESPONSE,
  ];

  return (
    formEditableOrderStatuses.includes(order.status) &&
    order.orderRevisions?.length > 0
  );
}

/**
 * get invoice number with revision version
 * @param order
 * @param revisions
 * @returns string
 */
export function getInvoiceNumber(order: any, revisions: IOrderRevision[]) {
  const activeRev = getActiveRevision(revisions);
  if (revisions?.length > 0 && activeRev !== null) {
    return `${order.invoiceNumber}-${revisions?.length}`;
  }

  if (order.status === OrderStatusEnum.CANCELLED) {
    return `${order.invoiceNumber}-${revisions?.length}`;
  }

  return order.invoiceNumber;
}

/**
 * get active revision if exist
 * @param revisions
 * @returns IOrderRevision | null
 */
export function getActiveRevision(revisions: IOrderRevision[]) {
  return (
    revisions?.find(
      (revision) => revision.content.status !== OrderStatusEnum.CANCELLED
    ) ?? null
  );
}

export function checkTrackingOrderCategory(
  isFromCatalog: boolean,
  orderType: string
) {
  if (isFromCatalog) {
    return "Catalog Order";
  } else if (orderType === "sales") {
    return "Sales Order";
  } else if (orderType === "purchase") {
    return "Purchase Order";
  }
  return "Order";
}

export function getBaseOrderPayloadForTracking(
  payload: any,
  selectedInternalAccount: any,
  orderId: any,
  orderType: string,
  session: any,
  resData: any
) {
  let sellerBuyerInfo = {};
  if (orderType === "purchase") {
    sellerBuyerInfo = {
      "Buyer email": selectedInternalAccount?.email,
      "Buyer name": selectedInternalAccount?.profile?.fullName,
      "Buyer company name": session?.activeBusiness?.companyName,

      "Seller email":
        payload.selectedExternalBusiness?.role?.[0]?.account?.email,
      "Seller name":
        payload.selectedExternalBusiness?.role?.[0]?.account?.profile?.fullName,
      "Seller company name": payload.selectedExternalBusiness?.companyName,
    };
  } else if (orderType === "sales") {
    sellerBuyerInfo = {
      "Seller email": selectedInternalAccount?.email,
      "Seller name": selectedInternalAccount?.profile?.fullName,
      "Seller company name": session?.activeBusiness?.companyName,

      "Buyer email":
        payload.selectedExternalBusiness?.role?.[0]?.account?.email,
      "Buyer name":
        payload.selectedExternalBusiness?.role?.[0]?.account?.profile?.fullName,
      "Buyer company name": payload.selectedExternalBusiness?.companyName,
    };
  }
  return {
    "Order category": checkTrackingOrderCategory(
      payload.isFromCatalog,
      orderType
    ),
    "Type of payment": payload.paymentFlowType,
    "Order ID": orderId,
    "Order No.": resData.invoiceNumber,
    "Grand total value": payload.grandTotalAmount,
    Currency: payload.currency,
    ...sellerBuyerInfo,
  };
}
