import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingModal from "../../LoadingModal/LoadingModal";
import { SuccessModal } from "../../SuccessModal";
import {
  CatalogInventoryItemType,
  CreateCatalogInventoryItemType,
  CreateItemType,
  UOMType,
} from "../../../types/item";
import { FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Modal,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { InventoryService } from "../../../services/inventory.service";
import { theme } from "../../Theme";
import TagsCollection from "../../Catalogue/Item/TagsCollection";
import AttributesCollection from "../../Catalogue/Item/AttributesCollection";
import ItemQuantityManager from "../Form/ItemQuantityManager";
import AdditionalUOMInput from "../Form/AdditionalUOMInput";
import { ItemService } from "../../../services/item.service";

type Props = {
  catalogId: string;
  showModal: boolean;
  catalogInventoryItem: CatalogInventoryItemType;
  onClose?: () => void;
  onUpdate?: (item: CatalogInventoryItemType) => void;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  // width: "90%",
  // height: "96%",
  overflow: "auto",
  // borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
  "&::-webkit-scrollbar": { display: "none" },
};

const EditCatalogueItemModal = ({
  catalogId,
  showModal,
  catalogInventoryItem,
  onClose,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();

  const [additionalUOMs, setAdditionalUOMs] = useState<any[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] =
    useState<CatalogInventoryItemType>(catalogInventoryItem);

  useEffect(() => {
    if (catalogInventoryItem.inventoryItem) {
      const rawUOMs = [
        {
          uom: catalogInventoryItem.inventoryItem.uom2,
          conversion: catalogInventoryItem.inventoryItem.conversion2,
          price:
            catalogInventoryItem.price2 ??
            catalogInventoryItem.inventoryItem.price2,
          field: {
            uom: "uom2",
            conversion: "conversion2",
            price: "price2",
          },
        },
        {
          uom: catalogInventoryItem.inventoryItem.uom3,
          conversion: catalogInventoryItem.inventoryItem.conversion3,
          price:
            catalogInventoryItem.price3 ??
            catalogInventoryItem.inventoryItem.price3,
          field: {
            uom: "uom3",
            conversion: "conversion3",
            price: "price3",
          },
        },
      ];

      // TODO: fix this logic somehow, it's diffiult to read
      const currentUOMs = rawUOMs.filter((uom) => uom.uom !== null);

      setAdditionalUOMs(currentUOMs);
    }
  }, [catalogInventoryItem]);

  const methods = useForm<CreateItemType>({
    defaultValues: {
      ...selectedItem.inventoryItem,
      price:
        selectedItem.price !== null
          ? selectedItem.price
          : selectedItem.inventoryItem.price,
      price2:
        selectedItem.price2 !== null
          ? selectedItem.price2
          : selectedItem.inventoryItem.price2,
      price3:
        selectedItem.price3 !== null
          ? selectedItem.price3
          : selectedItem.inventoryItem.price3,
      currency:
        selectedItem.currency !== null
          ? selectedItem.currency
          : selectedItem.inventoryItem.currency,
    },
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = (data: CreateItemType) => {
    setIsLoading(true);

    const payload: CreateCatalogInventoryItemType = {
      price: data.price,
      price2: data.price2,
      price3: data.price3,
      currency: data.currency,
      catalog: catalogId,
      inventoryItem: catalogInventoryItem.inventoryItem.id,
      isDeleted: false,
    };

    InventoryService.patchCatalogItems({
      itemId: catalogInventoryItem.id,
      updateCatalogInventoryItemDto: payload,
    })
      .then((res) => {
        if (res) {
          setAlertMessage(
            t("catalog.inventory.editCatalogItem.catalogUpdated")
          );
          setShowAlert(true);

          ItemService.getInventoryItemsByIds({
            ids: [catalogInventoryItem.id],
          }).then((res) => {
            setSelectedItem(res[0]);

            if (onUpdate) {
              onUpdate(res[0]);
            }

            console.log(res[0]);
            reset({
              ...res[0].inventoryItem,
              price:
                res[0].price !== null
                  ? res[0].price
                  : res[0].inventoryItem.price,
              price2: 300000,
              price3:
                res[0].price3 !== null
                  ? res[0].price3
                  : res[0].inventoryItem.price3,
              currency:
                res[0].currency !== null
                  ? res[0].currency
                  : res[0].inventoryItem.currency,
            });

            setTimeout(() => {
              setShowAlert(false);
              handleClose();
            }, 1500);
          });
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      open={showModal}
      sx={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="w-screen lg:w-[84vw] h-[96vh] overflow-auto">
        <Box
          sx={{
            ...style,
            width: { xs: "100%", lg: "84%" },
            height: { xs: "100%", lg: "96%" },
            borderRadius: { lg: "32px" },
            px: { lg: 4 },
            py: { xs: 2, lg: 4 },
          }}
          justifyContent={"center"}
        >
          <Typography
            textAlign={"center"}
            fontWeight={700}
            fontSize={{ xs: 16, lg: 20 }}
            id="modal-modal-title"
            sx={{
              color: theme.palette.primary[500],
              py: 2,
              px: 4,
            }}
          >
            {t("catalog.inventory.editCatalogItem.modalTitle")}
          </Typography>
          <div className="w-full flex flex-col lg:mt-8">
            {selectedItem && (
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <section className="w-full border-b lg:border-2 border-gray-200 lg:rounded-lg px-0 py-4 lg:p-8 flex flex-col lg:mb-6">
                    <div className="w-full lg:flex lg:items-start lg:gap-[30px]">
                      <img
                        src={`${
                          selectedItem.inventoryItem?.thumbnail ?? "/union.png"
                        }`}
                        alt="item_image"
                        // width={100}
                        // height={100}
                        className="border-b lg:border border-gray-300 lg:rounded-lg w-full sm:w-[176.5px] sm:h-[176.5px] lg:w-[230px] lg:h-[230px] object-contain flex-shrink-0"
                      />

                      <div className="w-full flex flex-col gap-4 lg:gap-2 p-4 lg:p-0">
                        <Typography
                          fontWeight={700}
                          fontSize={24}
                          sx={{
                            color: "black",
                          }}
                        >
                          {selectedItem.inventoryItem.productName}
                        </Typography>
                        <Typography fontWeight={500} fontSize={20}>
                          SKU: {selectedItem.inventoryItem.sku}
                        </Typography>

                        <TagsCollection
                          tags={selectedItem.inventoryItem.tags}
                        />

                        <AttributesCollection
                          flexiCols={selectedItem.inventoryItem.flexiColumns}
                        />

                        <FormControl
                          sx={{ flex: "1", mt: { xs: "16px", lg: "30px" } }}
                        >
                          <FormLabel sx={{ fontSize: 16 }}>
                            {t("catalog.inventory.editForm.productDescription")}
                          </FormLabel>
                          <Textarea
                            disabled
                            size="sm"
                            placeholder={t(
                              "catalog.inventory.editForm.productDescription"
                            )}
                            minRows={5}
                            defaultValue={
                              selectedItem.inventoryItem.productDescription
                            }
                          />
                        </FormControl>
                      </div>
                    </div>
                  </section>

                  <section className="w-full border-b lg:border-2 border-gray-200 lg:rounded-lg px-4 py-4 lg:p-8 flex flex-col lg:mb-6 lg:gap-[20px]">
                    <ItemQuantityManager
                      item={selectedItem.inventoryItem}
                      allUOMs={[]}
                      isReadOnly
                    />

                    <hr className="w-full h-[1px] my-4 lg:my-0" />

                    <Grid container spacing={2}>
                      {additionalUOMs.map((uom, index) => {
                        return (
                          <AdditionalUOMInput
                            key={uom.uom + index}
                            allUOMs={[]}
                            uom={uom as UOMType}
                            index={index + 1}
                            isReadOnly
                            basePrice={
                              selectedItem.price ??
                              selectedItem.inventoryItem.price
                            }
                          />
                        );
                      })}
                    </Grid>
                  </section>

                  {/* buttons */}
                  <Stack
                    direction={{ xs: "column-reverse", lg: "row" }}
                    justifyContent={"right"}
                    mt={4}
                    px={"16px"}
                    gap={{ xs: "8px", lg: 0 }}
                  >
                    <Button
                      type="button"
                      variant="plain-gray"
                      className="text-sm text-nowrap"
                      onClick={handleClose}
                    >
                      {t("commons.cancelBtn")}
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      sx={{
                        marginLeft: { lg: "12px" },
                        // padding: 1.5,
                      }}
                    >
                      {t("commons.saveBtn")}
                    </Button>
                  </Stack>
                </form>
              </FormProvider>
            )}
          </div>

          {/* modals */}
          <SuccessModal
            title={t("commons.successModalTitle")}
            content={alertMessage}
            open={showAlert}
          />
          <LoadingModal isLoading={isLoading} />
        </Box>
      </div>
    </Modal>
  );
};

export default EditCatalogueItemModal;
