import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom/client";

import { Toaster } from "react-hot-toast";
import App from "./App.tsx";
import ReactGA from "react-ga4";
import "./i18n/config.ts";
import "./main.css";
import { H } from "highlight.run"
import { ErrorBoundary } from '@highlight-run/react';

const queryClient = new QueryClient();

import.meta.env.VITE_GA_MEASUREMENT_ID &&
  ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID);

console.log("VITE_GA_MEASUREMENT_ID :::", import.meta.env.VITE_GA_MEASUREMENT_ID);

H.init(import.meta.env.VITE_LOGGER_PROJECT_ID, {
  serviceName: "linkz-app",
  tracingOrigins: [new URL(import.meta.env.VITE_API_URL).origin],
  integrations: {
    mixpanel: {
      disabled: import.meta.env.VITE_MIXPANEL_DISABLED === "true",
      projectToken: import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN
    }
  },

  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
    disableWebSocketEventRecordings: true,
    urlBlocklist: [
      // insert full or partial urls that you don't want to record here
      // Out of the box, Highlight will not record these URLs (they can be safely removed):
      "https://www.googleapis.com/identitytoolkit",
      "https://securetoken.googleapis.com",
      "https://www.google-analytics.com",
      "https://fonts.googleapis.com",
      "https://cdn.jsdelivr.net",
    ],
  },
});


ReactDOM.createRoot(document.getElementById("root")!).render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <App />
      <Toaster
        toastOptions={{
          style: {
            padding: 0,
            margin: 0,
            backgroundColor: "#FCE4E4",
            minWidth: "560px",
          },
        }}
      />
    </QueryClientProvider>
  </ErrorBoundary>
);
