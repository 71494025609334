import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  Box,
  Button,
  Dropdown,
  Grid,
  Input,
  Menu,
  MenuButton,
  Sheet,
  Snackbar,
  Stack,
  Typography,
} from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SuccessModal } from "../../components/SuccessModal";
import { InventoryService } from "../../services/inventory.service";
import { formatDate } from "../../utils/formatDate";
import useContainer from "./useContainer";
// import LoadingModal from "../../components/LoadingModal/LoadingModal";
import {
  CubeIcon,
  EllipsisVerticalIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import TagsCollection from "../../components/Catalogue/Item/TagsCollection";
import CopyLinkButton from "../../components/CopyLinkButton";
import ConfirmDeleteModal from "../../components/Inventory/ConfirmDeleteModal";
import ConfirmDuplicateModal from "../../components/Inventory/ConfirmDuplicateModal";
import { formatPrice } from "../../utils/formatPrice";
import { CatalogInventoryItemType } from "../../types/item";
import EditCatalogueItemModal from "../../components/Inventory/Items/EditCatalogueItemModal";
import ShareModal from "../../components/Inventory/ShareModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { theme } from "../../components/Theme";
import { getActiveBusiness } from "../../store/session";
import { MenuItem } from "@mui/material";

const ListCatalogueItems = () => {
  const { t } = useTranslation();
  const { catalogId } = useParams();

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CatalogInventoryItemType>();
  // const [isOpen, setIsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // const [isHidePrice, setIsHidePrice] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );
  const [isEditCatalogName, setIsEditCatalogName] = useState(false);

  const loaderRef = useRef(null);

  const {
    fetchAllItems,
    items,
    setItems,
    catalog,
    setCatalog,
    isLoading,
    setIsLoading,
    showAlert,
    showError,
    setShowAlert,
    showToast,
    unauthorized,
    setUnauthorized,
    alertMessage,
    setAlertMessage,
    confirmDelete,
    setConfirmDelete,
    showShareModal,
    setShowShareModal,
    confirmDuplicate,
    setConfirmDuplicate,
    fillerItems,
    pagination,
    hasMore,
  } = useContainer();

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);
  useEffect(() => {
    if (catalogId) {
      setIsLoading(true);
      InventoryService.getOneCatalog({
        catalogId: catalogId,
      })
        .then((res) => {
          if (res.business.id !== getActiveBusiness()?.id) {
            setUnauthorized(true);

            return;
          }

          setCatalog(res);
          // setIsHidePrice(res.hidePrice);
          return fetchAllItems({
            catalogId,
            paging: true,
            limit: 9,
            offset: 0,
            isReset: true,
          });
        })
        .then(() => setIsLoading(false))
        .catch((err) => console.error(err));
    }
  }, [catalogId]);
  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     const target = entries[0];
  //     if (target.isIntersecting && hasMore && catalogId && items.length > 0) {
  //       fetchAllItems({
  //         catalogId: catalogId,
  //         paging: true,
  //         limit: 9,
  //         offset: pagination.limit * pagination.current,
  //         isReset: false,
  //       });
  //     }
  //   });

  //   if (loaderRef.current) {
  //     observer.observe(loaderRef.current);
  //   }

  //   return () => {
  //     if (loaderRef.current) {
  //       observer.unobserve(loaderRef.current);
  //     }
  //   };
  // }, [fetchAllItems]);

  const handleRemove = () => {
    setIsLoading(true);
    InventoryService.removeCatalog({
      catalogId: catalogId || "",
    })
      .then((res) => {
        if (res) {
          setAlertMessage(t("catalog.catalogRemoved"));
          setShowAlert(true);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false);
        window.location.href = "/client/catalogues/list";
      });
  };

  const handleRemoveItem = (id: string) => {
    InventoryService.removeCatalogItem({
      catalogInventoryItemId: id,
    })
      .then((res) => {
        if (res && catalogId) {
          setAlertMessage(t("catalog.inventory.catalogItemRemoved"));
          setShowAlert(true);

          fetchAllItems({
            catalogId,
            paging: true,
            limit: 9,
            offset: 0,
            isReset: true,
          });
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleDuplicate = () => {
    setIsLoading(true);

    const businessId = getActiveBusiness()?.id;

    if (catalogId && businessId) {
      InventoryService.duplicateCatalog({
        catalogId,
      })
        .then((res) => {
          if (res.id) {
            setShowAlert(true);
            setAlertMessage(
              t("catalog.inventory.duplicateCatalog.successMessage")
            );

            setTimeout(() => {
              setShowAlert(false);
            }, 3000);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
          window.location.href = `/client/catalogues/list?type=${
            catalog?.catalogType.toLowerCase() ?? "public"
          }`;
        });
    }
  };

  // const handleToggleHidePrice = (value: boolean) => {
  //   setIsLoading(true);
  //   setIsHidePrice(!value);

  //   if (catalog) {
  //     InventoryService.patchCatalog({
  //       catalog: {
  //         id: catalog.id,
  //         catalogType: catalog.catalogType,
  //         catalogName: catalog.catalogName,
  //         business: catalog.business.id,
  //         hidePrice: !value,
  //       },
  //     })
  //       .catch((error) => console.error(error))
  //       .finally(() => setIsLoading(false));
  //   }
  // };

  const handleUpdateName = (value: string) => {
    setIsLoading(true);

    if (catalog) {
      InventoryService.patchCatalog({
        catalog: {
          id: catalog.id,
          catalogType: catalog.catalogType,
          catalogName: value,
          business: catalog.business.id,
          hidePrice: catalog.hidePrice,
        },
      })
        .then((res) => {
          setCatalog(res);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const CatalogTypeButton = () => {
    return (
      <button
        className={`py-3 px-4 rounded-md ${
          catalog?.catalogType === "PUBLIC"
            ? "bg-primary-200"
            : "bg-gray-700 text-white"
        } capitalize font-semibold items-center flex cursor-default`}
      >
        {catalog?.catalogType === "PUBLIC" ? (
          <LockOpenOutlinedIcon
            style={{
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
        ) : (
          <LockOutlinedIcon
            style={{
              color: "white",
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
        )}

        {catalog?.catalogType.toLowerCase()}
      </button>
    );
  };

  if (unauthorized) {
    return <> {t("catalog.noAccessCatalog")}</>;
  }

  return (
    <Sheet sx={{ backgroundColor: "transparent" }}>
      <div className="w-[90vw] xs:w-full">
        {/* page header */}
        <Grid container spacing={2} mt={4} mb={2}>
          <Grid xs={12} lg={6} display={"flex"} position={"relative"}>
            {!isEditCatalogName ? (
              <h3
                className="text-2xl font-bold"
                onClick={() => setIsEditCatalogName(true)}
              >
                {catalog?.catalogName}
              </h3>
            ) : (
              <Input
                placeholder={t("catalog.inventory.createCatalog.placeholder")}
                defaultValue={catalog?.catalogName}
                type="text"
                className="text-2xl font-bold"
                fullWidth
                autoFocus
                onBlur={(event) => {
                  if (event.target.value !== catalog?.catalogName) {
                    handleUpdateName(event.target.value);
                  }

                  setIsEditCatalogName(false);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    if (event.currentTarget.value !== catalog?.catalogName) {
                      handleUpdateName(event.currentTarget.value);
                    }

                    setIsEditCatalogName(false);
                  }
                }}
              />
            )}
          </Grid>
          <Grid
            xs={12}
            lg={6}
            sx={{
              display: "flex",
              justifyContent: { xs: "left", lg: "right" },
              alignItems: "center",
            }}
          >
            <Typography
              fontSize={14}
              sx={{
                color: theme.colorSchemes.dark.palette.text.secondary,
              }}
            >
              <span className="text-xs lg:text-sm">
                {t("commons.lastUpdated")}:{" "}
                <span className="ml-2">
                  {formatDate(new Date(catalog?.updatedAt ?? ""))}
                </span>
              </span>
            </Typography>
          </Grid>
        </Grid>

        {/* toolbar */}
        <div className="flex items-center my-6">
          <CatalogTypeButton />

          <span className="flex ml-auto">
            <div className="lg:hidden">
              <Stack width={40}>
                <Dropdown
                  open={mobileMenuOpen}
                  onOpenChange={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <MenuButton
                    fullWidth
                    slots={{ root: Button }}
                    slotProps={{
                      root: {
                        variant: "plain",
                        color: "neutral",
                        sx: {
                          "&:hover": {
                            bgcolor: "transparent",
                          },
                          "&:focus": {
                            bgcolor: "transparent",
                          },
                          padding: 0,
                          minWidth: 0,
                        },
                      },
                    }}
                  >
                    <Button
                      variant="outlined"
                      className="min-w-[40px] text-nowrap p-0"
                    >
                      <EllipsisVerticalIcon width={18} height={18} />
                    </Button>
                  </MenuButton>
                  <Menu>
                    <Link
                      to={`/client/catalogues/inventory/${catalogId}/manage`}
                    >
                      <MenuItem
                        sx={{
                          gap: "1rem",
                        }}
                      >
                        <PlusIcon
                          style={{
                            width: 18,
                            height: 18,
                          }}
                        />
                        {t("commons.addItemBtn")}
                      </MenuItem>
                    </Link>
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                      onClick={() => {
                        setShowShareModal(true);
                      }}
                    >
                      <SendOutlinedIcon
                        style={{
                          width: 18,
                          height: 18,
                        }}
                      />
                      {t("catalog.sendBtn")}
                    </MenuItem>
                    <CopyLinkButton
                      url={t("catalog.copyLinkPrompt", {
                        catalogueLink: `${
                          window.location.href.split("/client")[0]
                        }/catalog/${catalogId}/items`,
                      })}
                      variant={"dropdown"}
                    />
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                      onClick={() => {
                        setConfirmDuplicate(true);
                      }}
                    >
                      <ContentCopyOutlinedIcon
                        style={{
                          width: 18,
                          height: 18,
                        }}
                      />
                      {t("catalog.inventory.items.duplicateBtn")}
                    </MenuItem>
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                      onClick={() => {
                        setConfirmDelete(true);
                      }}
                    >
                      <TrashIcon width={18} height={18} />
                      {t("catalog.inventory.removeCatalogBtn")}
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </Stack>
            </div>

            <div className="hidden gap-3 lg:flex">
              <Link to={`/client/catalogues/inventory/${catalogId}/manage`}>
                <Button
                  startDecorator={
                    <PlusIcon
                      style={{
                        width: 18,
                        height: 18,
                      }}
                    />
                  }
                  className="text-nowrap"
                >
                  {t("commons.addItemBtn")}
                </Button>
              </Link>

              <Button
                variant="outlined"
                startDecorator={
                  <SendOutlinedIcon
                    style={{
                      width: 18,
                      height: 18,
                    }}
                  />
                }
                className="w-full mt-4 text-sm lg:mt-0 text-nowrap"
                onClick={() => {
                  setShowShareModal(true);
                }}
              >
                {t("catalog.sendBtn")}
              </Button>
              <CopyLinkButton
                url={t("catalog.copyLinkPrompt", {
                  catalogueLink: `${
                    window.location.href.split("/client")[0]
                  }/catalog/${catalogId}/items`,
                })}
                variant={"outlined"}
              />

              <Stack width={40}>
                <Dropdown
                  open={menuOpen}
                  onOpenChange={() => setMenuOpen(!menuOpen)}
                >
                  <MenuButton
                    fullWidth
                    slots={{ root: Button }}
                    slotProps={{
                      root: {
                        variant: "plain",
                        color: "neutral",
                        sx: {
                          "&:hover": {
                            bgcolor: "transparent",
                          },
                          "&:focus": {
                            bgcolor: "transparent",
                          },
                          padding: 0,
                          minWidth: 0,
                        },
                      },
                    }}
                  >
                    <Button
                      variant="outlined"
                      className="min-w-[40px] text-nowrap p-0"
                    >
                      <EllipsisVerticalIcon width={18} height={18} />
                    </Button>
                  </MenuButton>
                  <Menu>
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                      onClick={() => {
                        setConfirmDuplicate(true);
                      }}
                    >
                      <ContentCopyOutlinedIcon
                        style={{
                          width: 18,
                          height: 18,
                        }}
                      />
                      {t("catalog.inventory.items.duplicateBtn")}
                    </MenuItem>
                    <MenuItem
                      sx={{
                        gap: "1rem",
                      }}
                      onClick={() => {
                        setConfirmDelete(true);
                      }}
                    >
                      <TrashIcon width={18} height={18} />
                      {t("catalog.inventory.removeCatalogBtn")}
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </Stack>
            </div>
          </span>
        </div>

        {/* <div className="flex items-center my-6">
          {catalog && (
            <span className="flex gap-3 ml-auto">
              <Typography fontSize={14}>
                {t("catalog.inventory.editForm.showPrice")}
              </Typography>
              <Switch
                defaultChecked={!isHidePrice}
                size="lg"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleToggleHidePrice(event.target.checked);
                }}
              />
            </span>
          )}
        </div> */}

        {/* items table */}
        <InfiniteScroll
          pageStart={0}
          // initialLoad
          loadMore={(_page: number) => {
            // console.log("LOADMORE TRIGGERED", pagination.limit, page);
            fetchAllItems({
              catalogId: catalogId ?? "",
              paging: true,
              limit: 9,
              offset: pagination.limit * pagination.current,
              isReset: false,
            });
          }}
          hasMore={hasMore}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
        >
          <div className="w-[90vw] xs:w-full lg:w-[78vw]">
            {items.length > 0 ? (
              <>
                <Grid
                  container
                  mt={4}
                  mb={2}
                  // gridAutoRows={"1fr"}
                  spacing={{ xs: 1, lg: 0 }}
                  alignItems={"stretch"}
                >
                  {items.map((i) => {
                    const currency = i.currency ?? i.inventoryItem.currency;
                    const price = i.price ?? i.inventoryItem.price;

                    return (
                      <Grid
                        key={i.id}
                        xs={6}
                        lg={4}
                        flexGrow={{ xs: 0, l: 1 }}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "stretch",
                          justifyContent: "space-between",
                          padding: { xs: "0.25rem", lg: 0 },
                        }}
                      >
                        <div
                          className={`lg:pt-8 lg:px-4 flex flex-col lg:flex-row h-full align-middle w-full rounded-lg lg:rounded-none border lg:border-none border-[${theme.colorSchemes.light.palette.others.disabled}]`}
                        >
                          <Stack gap={{ lg: 1 }}>
                            <div className="flex justify-center w-full">
                              <img
                                src={`${
                                  i.inventoryItem.thumbnail ?? "/union.png"
                                }`}
                                alt="item_image"
                                // width={100}
                                // height={100}
                                className="lg:border-b lg:border border-gray-300 rounded-t-lg lg:rounded-lg lg:mr-4 w-full sm:w-[176.5px] h-[176.5px] lg:w-[100px] lg:h-[100px] object-contain flex-shrink-0"
                              />
                            </div>
                          </Stack>

                          <div className="p-2 lg:p-0">
                            <Stack gap={"0.5rem"}>
                              {/* mobile product name */}
                              <Typography
                                fontSize={14}
                                display={{ lg: "none" }}
                                sx={{
                                  color: "black",
                                  fontWeight: 600,
                                }}
                              >
                                {i.inventoryItem.productName}
                              </Typography>

                              <Typography
                                fontSize={14}
                                display={"flex"}
                                gap={"0.25rem"}
                                sx={{
                                  color:
                                    theme.colorSchemes.dark.palette.text
                                      .secondary,
                                }}
                              >
                                SKU :{" "}
                                <span className="text-black">
                                  {i.inventoryItem.sku}
                                </span>
                              </Typography>

                              {/* hide in mobile */}
                              <Typography
                                display={{ xs: "none", lg: "block" }}
                                fontSize={14}
                                sx={{
                                  color:
                                    theme.colorSchemes.dark.palette.text
                                      .secondary,
                                }}
                              >
                                {t("catalog.supplier.items.productName")} :{" "}
                                <span className="text-black">
                                  {i.inventoryItem.productName}
                                </span>
                              </Typography>

                              <TagsCollection tags={i.inventoryItem.tags} />
                              {/* <div className="gap-2 xs:flex">
                              {i.inventoryItem.tags?.tags.map((t) => (
                                <Chip
                                  key={t}
                                  variant="outlined"
                                  sx={{
                                    color:
                                      theme.colorSchemes.dark.palette
                                        .neutral[400],
                                  }}
                                >
                                  {t}
                                </Chip>
                              ))}
                            </div> */}

                              {/* mobile price */}
                              <Typography
                                mt={"0.5rem"}
                                display={{ xs: "block", lg: "none" }}
                                sx={{
                                  color: "black",
                                  fontSize: 16,
                                  textAlign: "left",
                                  wordBreak: "break-all",
                                  justifyContent: "center",
                                  fontWeight: 600,
                                }}
                              >
                                {`${currency}`} <br />
                                {formatPrice(price, currency)}
                              </Typography>
                            </Stack>
                          </div>

                          {/* mobile buttons */}
                          <div className="px-2 pb-2 mt-auto lg:hidden">
                            <Stack
                              direction={"column"}
                              justifyContent={"center"}
                              gap={"0.25rem"}
                            >
                              <Button
                                type="button"
                                variant="plain-gray"
                                startDecorator={
                                  <TrashIcon
                                    style={{
                                      width: 18,
                                      height: 18,
                                    }}
                                  />
                                }
                                className="text-sm text-nowrap"
                                onClick={() => {
                                  handleRemoveItem(i.id);
                                }}
                              >
                                {t("catalog.inventory.deleteItemBtn")}
                              </Button>
                              <Button
                                type="button"
                                // onClick={handleClick}
                                startDecorator={
                                  <CubeIcon style={{ width: 18, height: 18 }} />
                                }
                                variant="outlined"
                                sx={{
                                  padding: 1.5,
                                }}
                                className="h-12 lg:h-full whitespace-nowrap"
                                onClick={() => {
                                  setSelectedItem(i);
                                  setShowEditModal(true);
                                }}
                              >
                                {t("catalog.supplier.items.seeDetailsBtn")}
                              </Button>
                            </Stack>
                          </div>
                        </div>
                        {!isMobile && (
                          <div className="w-full lg:pt-6 lg:px-4">
                            <Typography
                              mt={1}
                              display={{ xs: "none", lg: "block" }}
                              sx={{
                                fontSize: 16,
                                width: "100%",
                                textAlign: "left",
                                wordWrap: "break-word",
                                justifyContent: "center",
                                fontWeight: 600,
                                wordBreak: "break-all",
                              }}
                            >
                              {`${currency} ${formatPrice(price, currency)}`}
                            </Typography>
                          </div>
                        )}
                        {/* buttons */}
                        <div className="hidden lg:flex">
                          <Stack
                            direction={"row"}
                            justifyContent={"center"}
                            mt={"1rem"}
                            gap={"9px"}
                          >
                            <Button
                              type="button"
                              variant="plain-gray"
                              startDecorator={
                                <TrashIcon
                                  style={{
                                    width: 18,
                                    height: 18,
                                  }}
                                />
                              }
                              className="text-sm text-nowrap"
                              onClick={() => {
                                handleRemoveItem(i.id);
                              }}
                            >
                              {t("catalog.inventory.deleteItemBtn")}
                            </Button>
                            <Button
                              type="button"
                              // onClick={handleClick}
                              startDecorator={
                                <CubeIcon style={{ width: 18, height: 18 }} />
                              }
                              variant="outlined"
                              sx={{
                                padding: 1.5,
                              }}
                              className="whitespace-nowrap"
                              onClick={() => {
                                setSelectedItem(i);
                                setShowEditModal(true);
                              }}
                            >
                              {t("catalog.supplier.items.seeDetailsBtn")}
                            </Button>
                          </Stack>
                        </div>

                        <hr
                          className={`hidden lg:block mt-6 w-full border-b border-dashed border-[${theme.colorSchemes.light.palette.others.disabled}]`}
                        />
                      </Grid>
                    );
                  })}

                  {!isMobile &&
                    Array.from({ length: fillerItems }, () => (
                      <Grid
                        key={Math.random() * 6502}
                        xs={12}
                        lg={4}
                        flexGrow={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "stretch",
                          justifyContent: "space-between",
                        }}
                      >
                        <hr
                          className={`hidden lg:block mt-auto w-full border-b border-dashed border-[${theme.colorSchemes.light.palette.others.disabled}]`}
                        />
                      </Grid>
                    ))}
                </Grid>

                <div ref={loaderRef} className="flex justify-center w-full">
                  {isLoading && "Loading..."}
                </div>
              </>
            ) : (
              <div className="flex w-full mt-10">
                <p className="mx-auto my-auto text-base">
                  {t("catalog.noItemInCatalog")}
                </p>
              </div>
            )}
          </div>
        </InfiniteScroll>

        {/* modal */}
        <SuccessModal
          title={t("commons.successModalTitle")}
          content={alertMessage}
          open={showAlert}
        />
        {/* <LoadingModal isLoading={isLoading} /> */}
        <Snackbar
          variant="solid"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showError}
          key={"topcenter" + Math.random() * 1000}
          sx={{
            width: "40%",
            maxWidth: 800,
          }}
        >
          <Box width={"100%"}>
            <Typography
              textAlign={"center"}
              textColor={"common.white"}
              fontSize={14}
            >
              {alertMessage}
            </Typography>
          </Box>
        </Snackbar>
        <Snackbar
          variant="solid"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showToast}
          key={"topcenter" + Math.random() * 1000}
          sx={{
            width: "40%",
            maxWidth: 800,
            backgroundColor: theme.colorSchemes.light.palette.others.success,
          }}
        >
          <Box width={"100%"}>
            <Typography
              textAlign={"center"}
              textColor={"common.black"}
              fontSize={14}
            >
              {alertMessage}
            </Typography>
          </Box>
        </Snackbar>
        <ShareModal
          show={showShareModal}
          onClose={() => {
            setShowShareModal(false);
          }}
          catalogId={catalogId ?? ""}
          businessId={getActiveBusiness()?.id ?? ""}
          catalogType={catalog?.catalogType.toLowerCase() ?? "private"}
        />
        <ConfirmDeleteModal
          show={confirmDelete}
          onClick={handleRemove}
          onClose={() => {
            setConfirmDelete(false);
          }}
        />
        <ConfirmDuplicateModal
          show={confirmDuplicate}
          catalogType={
            t(`catalog.catalogType.${catalog?.catalogType.toLowerCase()}`) ??
            "private"
          }
          onClick={handleDuplicate}
          onClose={() => {
            setConfirmDuplicate(false);
          }}
        />

        {selectedItem && (
          <EditCatalogueItemModal
            catalogId={catalogId ?? ""}
            showModal={showEditModal}
            catalogInventoryItem={selectedItem}
            onClose={() => {
              setShowEditModal(false);
              setSelectedItem(undefined);
            }}
            onUpdate={(item: CatalogInventoryItemType) => {
              const updatedItems = items.map((i) => {
                if (i.id === item.id) {
                  return { ...item };
                } else {
                  return i;
                }
              });

              setItems(updatedItems);
            }}
          />
        )}
      </div>

      <LoadingModal isLoading={isLoading} />
    </Sheet>
  );
};

export default ListCatalogueItems;
