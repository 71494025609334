import { H } from "highlight.run";

export class TrackerService {
  static identified = false;

  // force is used to force identify even if already identified
  static identify(email: string, payload?: any, force?: boolean) {
    console.log("TRACKER SERVICE IDENTIFY", email, TrackerService.identified, force);
    if (!TrackerService.identified || force) {
      TrackerService.identified = true;
      H.identify(email, payload);
    }
  }

  static track(event: string, payload?: any) {
    console.log("TRACKER SERVICE TRACK", event);
    H.track(event, payload);
  }

  static stop() {
    TrackerService.identified = false;
    H.stop();
  }
}
