import { Button, Typography } from "@mui/joy";
import { calculatePrice, getConversionRate } from "../../../utils/cart";
import { CatalogInventoryItemType } from "../../../types/item";
import { formatPrice } from "../../../utils/formatPrice";

interface Props {
  item: CatalogInventoryItemType;
  hidePrice?: boolean;
  catalogType?: string;
  activeUOM: "uom1" | "uom2" | "uom3";
  isDisabled?: boolean;
  setActiveUOM: (uom: "uom1" | "uom2" | "uom3") => void;
}

const UOMSelector = ({
  item,
  hidePrice = false,
  catalogType = "PUBLIC",
  activeUOM,
  isDisabled = false,
  setActiveUOM,
}: Props) => {
  const currency = item.currency ?? item.inventoryItem.currency;
  // TODO: replace with /public
  const isGuestMode = window.location.pathname.startsWith("/catalog");

  return (
    <div className="hidden lg:flex lg:w-1/2 ml-8">
      <div className="hidden lg:flex flex-col items-center justify-start">
        <div className="flex w-full gap-2">
          {item.inventoryItem.uom1 && (
            <Button
              variant="outlined"
              disabled={isDisabled}
              className={`rounded-lg p-2 min-w-20 h-10 ${
                activeUOM === "uom1" ? "bg-primary-500 text-white" : ""
              }`}
              onClick={() => {
                setActiveUOM("uom1");
              }}
            >
              {item.inventoryItem.uom1}
            </Button>
          )}
          {item.inventoryItem.uom2 && (
            <Button
              variant="outlined"
              disabled={isDisabled}
              className={`rounded-lg p-2 min-w-20 h-10 ${
                activeUOM === "uom2" ? "bg-primary-500 text-white" : ""
              }`}
              onClick={() => {
                setActiveUOM("uom2");
              }}
            >
              {item.inventoryItem.uom2}
            </Button>
          )}
          {item.inventoryItem.uom3 && (
            <Button
              variant="outlined"
              disabled={isDisabled}
              className={`rounded-lg p-2 min-w-20 h-10 ${
                activeUOM === "uom3" ? "bg-primary-500 text-white" : ""
              }`}
              onClick={() => {
                setActiveUOM("uom3");
              }}
            >
              {item.inventoryItem.uom3}
            </Button>
          )}
        </div>

        {!isDisabled && (
          <div className="flex flex-col mt-4">
            <Typography
              className="text-black font-bold"
              fontSize={20}
              sx={{
                wordBreak: "break-all",
              }}
            >
              {`${currency} ${
                isGuestMode
                  ? catalogType === "PRIVATE"
                    ? "-"
                    : hidePrice
                    ? "-"
                    : formatPrice(calculatePrice(item, activeUOM), currency)
                  : hidePrice
                  ? "-"
                  : formatPrice(calculatePrice(item, activeUOM), currency)
              }`}
              {item.inventoryItem[activeUOM] &&
                `/${item.inventoryItem[activeUOM]}`}
            </Typography>

            {activeUOM !== "uom1" && (
              <p className="text-xs font-medium mt-1">
                {`1 ${item.inventoryItem[activeUOM]} (${getConversionRate(
                  activeUOM,
                  item.inventoryItem
                )} ${item.inventoryItem.uom1})`}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UOMSelector;
