import "@fontsource/inter";
import CssBaseline from "@mui/joy/CssBaseline";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import {
  CssVarsProvider as JoyCssVarsProvider,
  StyledEngineProvider,
} from "@mui/joy/styles";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";

import { theme } from "./components/Theme";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Verified from "./pages/Verified";
import Verify from "./pages/Verify/Verify";
// import Home from "./pages/Home";
import Catalogues from "./pages/Catalogues";
// import Home from "./pages/Home";
import { Box } from "@mui/joy";
import BreadcrumbHeader from "./components/BreadcrumbHeader";
import NavSidebar from "./components/NavSidebar/NavSidebar";
import ProtectedRoute from "./components/ProtectedRoute";
import BusinessContact from "./pages/BusinessContact/v2/BusinessContact";
import Carts from "./pages/Carts/Carts";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import Credit from "./pages/Credit/Credit";
import Dashboard from "./pages/Dashboard/Dashboard";
import { AddEmployee } from "./pages/Employee/AddEmployee";
import Employee from "./pages/Employee/Employee";
import UpdateEmployee from "./pages/Employee/UpdateEmployee";
import ForgotPasswordVerify from "./pages/ForgotPasswordVerify/ForgotPasswordVerify";
import ListInventory from "./pages/Inventory/ListInventory";
import Item from "./pages/Item";
import Items from "./pages/Items";
import ListSupplierItems from "./pages/Items/ListSupplierItems";
import Manage from "./pages/Manage";
import NotFound from "./pages/NotFound/NotFound";
import Notis from "./pages/Notis";
// import EditOrders from "./pages/Order/EditOrders";
import EditOrders from "./pages/Order/EditOrdersV2";
// import ListOrders from "./pages/Order/ListOrders";
import AddBusinessContact from "./pages/BusinessContact/v2/AddBusinessContact";
import { EditBusinessContact } from "./pages/BusinessContact/v2/EditBusinessContact";
import ListCatalogue from "./pages/Catalogues/ListCatalogue";
import EditInventory from "./pages/Inventory/EditInventory";
import KYC from "./pages/KYC/KYC";
import ListOrders from "./pages/Order/ListOrdersV2";
import PhoneVerified from "./pages/PhoneVerified/PhoneVerified";
import { CreateBusiness } from "./pages/Profile/Business/CreateBusiness";
import UpdateBusiness from "./pages/Profile/Business/UpdateBusiness";
import MobileOtpVerification from "./pages/Profile/MobileOtpVerification";
import ProfileChangePassword from "./pages/Profile/ProfileChangePassword";
import ProfileFullName from "./pages/Profile/ProfileFullName";
import ProfileMobileNumber from "./pages/Profile/ProfileMobileNumber";
import Profiles from "./pages/Profile/Profiles";
import SetPassword from "./pages/SetPassword/SetPassword";
import Signin from "./pages/Signin/Signin";
import Signup from "./pages/Signup/Signup";
import SignupSSO from "./pages/SignupSSO/SignupSSO";
import ListSuppliers from "./pages/Suppliers/ListSuppliers";
import TermsAndCondition from "./pages/TermsAndCondition/TermsAndCondition";
import Welcome from "./pages/Welcome";
import { initFCM } from "./store/notification";
import { mixpanelProfilePayload, useAuthStore } from "./store/session";
import { BrowserRouterConstants } from "./utils/constants";
import { getBreadcumb } from "./utils/navbar";

import PublicViewOrder from "./pages/Order/PublicViewOrder";
import PaymentLink from "./pages/PaymentLink";
import { useEffect } from "react";
import PublicCarts from "./pages/Carts/PublicCarts";
import ProfileKYC from "./pages/Profile/KYC";
import PublicViewListOutstandingOrders from "./pages/Order/PublicViewListOutstandingOrders";
import { TrackerService } from "./services/tracker.service";
import ManageCatalogueItems from "./pages/Inventory/ManageCatalogueItems";
import ListCatalogueItems from "./pages/Items/ListCatalogueItems";

const PanelLayout = () => {
  const { session } = useAuthStore();
  const isAuthenticated = session !== null;
  const { pathname } = useLocation();

  const breadcumb = getBreadcumb(pathname);
  console.log({ isKYCComplete: session?.isKYCComplete });
  return (
    <Box
      className="flex"
      sx={{
        // display: "grid",
        minHeight: "100dvh",
        // gridTemplateColumns: { xs: "1fr", md: "240px 1fr" },
        transition: "300ms",
      }}
    >
      <div className="max-w-[240px]">
        <NavSidebar />
      </div>
      <Box className="flex-1">
        <Box className="headerContainer" pt={2} pb={0}>
          <div className="px-4 lg:px-8">
            <BreadcrumbHeader paths={breadcumb} />
          </div>
          {/* <Headers /> */}
        </Box>
        <Box className="contentContainer" pt={2} pb={2}>
          <ProtectedRoute redirectRoute="/signin" isValid={isAuthenticated}>
            <ProtectedRoute
              redirectRoute="/kyc"
              isValid={!!session?.isKYCComplete}
            >
              <div className="px-4 lg:px-8">
                <Outlet />
              </div>
            </ProtectedRoute>
          </ProtectedRoute>
        </Box>
      </Box>
      {/* <Footer /> */}
    </Box>
  );
};

const browserRouter = () => {
  const { session } = useAuthStore();
  const isAuthenticated = session !== null;

  return createBrowserRouter([
    {
      path: "/",
      element: <Welcome />,
      errorElement: <NotFound />,
    },
    {
      path: "/kyc",
      element: (
        <ProtectedRoute
          redirectRoute="/client"
          isValid={isAuthenticated && !session?.isKYCComplete}
        >
          <KYC />
        </ProtectedRoute>
      ),
      errorElement: <NotFound />,
    },
    {
      path: "/terms-and-condition",
      element: <TermsAndCondition />,
    },
    {
      path: "/online-payment/:linkId",
      element: <PaymentLink />,
    },
    {
      path: "/catalog/:catalogId/items",
      element: <ListSupplierItems />,
    },
    {
      path: "/full-view/inventory",
      element: <ListInventory />,
    },
    {
      path: "/full-view/inventory/:inventoryItemId/edit",
      element: <EditInventory />,
    },
    {
      path: "/public/cart/:catalogId/items",
      element: <PublicCarts />,
    },
    {
      path: "/public/orders/:orderType/:orderId",
      element: <PublicViewOrder />,
    },
    {
      path: "/public/orders/outstanding/",
      element: <PublicViewListOutstandingOrders />,
    },
    {
      path: BrowserRouterConstants.ClientPrefix,
      element: <PanelLayout />,
      children: [
        { index: true, element: <Dashboard /> },
        // { index: true, element: <ComingSoon /> },
        {
          path: BrowserRouterConstants.CataloguesItems,
          element: <Items />,
        },
        {
          path: BrowserRouterConstants.Credit,
          element: <Credit />,
        },
        {
          path: BrowserRouterConstants.Catalogues,
          element: <Catalogues />,
        },
        {
          path: BrowserRouterConstants.Supplier,
          element: <ListSuppliers />,
        },
        {
          path: BrowserRouterConstants.SupplierItems,
          element: <ListSupplierItems />,
        },
        {
          path: BrowserRouterConstants.CartItems,
          element: <Carts />,
        },
        {
          path: BrowserRouterConstants.Inventory,
          element: <ListInventory />,
        },
        {
          path: BrowserRouterConstants.InventoryEdit,
          element: <EditInventory />,
        },
        {
          path: BrowserRouterConstants.CataloguesList,
          element: <ListCatalogue />,
        },
        {
          path: BrowserRouterConstants.CatalogInventoryItems,
          element: <ListCatalogueItems />,
        },
        {
          path: BrowserRouterConstants.ManageCatalogInventoryItem,
          element: <ManageCatalogueItems />,
        },
        {
          path: BrowserRouterConstants.ItemDetails,
          element: <Item />,
        },
        {
          path: BrowserRouterConstants.Orders,
          element: <ListOrders />,
        },
        {
          path: BrowserRouterConstants.OrdersEdit,
          element: <EditOrders />,
        },
        {
          path: BrowserRouterConstants.Finance,
          element: <ComingSoon />,
        },
        {
          path: BrowserRouterConstants.Notis,
          element: <Notis />,
        },
        {
          path: BrowserRouterConstants.Profile,
          element: <Profiles />,
        },
        {
          path: BrowserRouterConstants.ProfileFullName,
          element: <ProfileFullName />,
        },
        {
          path: BrowserRouterConstants.ProfileMobileNumber,
          element: <ProfileMobileNumber />,
        },
        {
          path: BrowserRouterConstants.ProfileMobileNumberVerification,
          element: <MobileOtpVerification />,
        },
        {
          path: BrowserRouterConstants.ProfileChangePassword,
          element: <ProfileChangePassword />,
        },
        {
          path: BrowserRouterConstants.ProfileBusiness,
          element: <CreateBusiness />,
        },
        {
          path: BrowserRouterConstants.ProfileBusinessUpdate,
          element: <UpdateBusiness />,
        },
        {
          path: BrowserRouterConstants.Employees,
          element: <Employee />,
        },
        {
          path: BrowserRouterConstants.AddEmployee,
          element: <AddEmployee />,
        },
        {
          path: BrowserRouterConstants.UpdateEmployee,
          element: <UpdateEmployee />,
        },
        {
          path: BrowserRouterConstants.BusinessContact,
          element: <BusinessContact />,
        },
        {
          path: BrowserRouterConstants.AddBusinessContact,
          element: <AddBusinessContact />,
        },
        {
          path: BrowserRouterConstants.UpdateBusinessContact,
          element: <EditBusinessContact />,
        },
        {
          path: BrowserRouterConstants.Manage,
          element: <Manage />,
        },
        {
          path: BrowserRouterConstants.ProfileKYC,
          element: <ProfileKYC />,
        },
      ],
    },
    {
      path: BrowserRouterConstants.Signup,
      element: (
        <ProtectedRoute isValid={!isAuthenticated}>
          <Signup />
        </ProtectedRoute>
      ),
    },
    {
      path: BrowserRouterConstants.SignupSSO,
      element: <SignupSSO />,
    },
    {
      path: BrowserRouterConstants.Verify,
      element: <Verify />,
    },
    {
      path: BrowserRouterConstants.Verify,
      element: <Verify />,
    },
    {
      path: BrowserRouterConstants.PasswordResetVerify,
      element: <ForgotPasswordVerify />,
    },
    {
      path: BrowserRouterConstants.Signin,
      element: (
        <ProtectedRoute isValid={!isAuthenticated}>
          <Signin />
        </ProtectedRoute>
      ),
    },
    {
      path: BrowserRouterConstants.Recover,
      element: <ForgotPassword />,
    },
    {
      path: BrowserRouterConstants.SetPassword,
      element: <SetPassword />,
    },
    {
      path: BrowserRouterConstants.Verified,
      element: <Verified />,
    },
    {
      path: BrowserRouterConstants.PhoneVerified,
      element: <PhoneVerified />,
    },
  ]);
};

export default function App() {
  const { session } = useAuthStore();
  const isAuthenticated = session !== null;
  isAuthenticated && initFCM();
  isAuthenticated &&
    TrackerService.identify(
      session?.account.email || "",
      mixpanelProfilePayload(session)
    );
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "userSession") {
        const storedSession = localStorage.getItem("userSession");
        if (storedSession) {
          // console.log("SESSION DATA", JSON.parse(storedSession));
          if (JSON.parse(storedSession).state.session === null) {
            window.location.href = "/signin";
          }
        } else {
          window.location.href = "/signin";
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  /**
   * This is dirty code. It's kind of like extending the MUI theme with Joy UI theme.
   * Necessary for MUI-X components (eg. date-picker)
   */
  const materialTheme = materialExtendTheme({
    colorSchemes: {
      light: {
        palette: {
          common: {
            black: "#101828",
            white: "#FFF",
          },
          primary: {
            50: "#F1F8F4",
            100: "#DDEEE3",
            200: "#BDDDCA",
            300: "#91C4A9",
            400: "#62A583",
            500: "#499873",
            600: "#2F6C51",
            700: "#265641",
            800: "#204536",
            900: "#1B392D",
          },
          success: {
            50: "#f0fdf4",
            100: "#dcfce7",
            200: "#bbf7d0",
            300: "#86efac",
            400: "#4ade80",
            500: "#22c55e",
            600: "#16a34a",
            700: "#15803d",
            800: "#166534",
            900: "#14532d",
          },
          warning: {
            50: "#FEFAF6",
            100: "#FDF0E1",
            200: "#FCE1C2",
            300: "#F3C896",
            400: "#EA9A3E",
            500: "#9A5B13",
            600: "#72430D",
            700: "#492B08",
            800: "#2E1B05",
            900: "#1D1002",
          },
          text: {
            secondary: "#555E68",
          },
        },
      },
      dark: {
        palette: {
          common: {
            black: "#000",
            white: "#FFF",
          },
          primary: {
            50: "#F1F8F4",
            100: "#DDEEE3",
            200: "#BDDDCA",
            300: "#91C4A9",
            400: "#62A583",
            500: "#499873",
            600: "#2F6C51",
            700: "#265641",
            800: "#204536",
            900: "#1B392D",
          },
          success: {
            50: "#f0fdf4",
            100: "#dcfce7",
            200: "#bbf7d0",
            300: "#86efac",
            400: "#4ade80",
            500: "#22c55e",
            600: "#16a34a",
            700: "#15803d",
            800: "#166534",
            900: "#14532d",
          },
          warning: {
            50: "#fff7ed",
            100: "#ffedd5",
            200: "#fed7aa",
            300: "#fdba74",
            400: "#fb923c",
            500: "#f97316",
            600: "#ea580c",
            700: "#c2410c",
            800: "#9a3412",
            900: "#7c2d12",
          },
          text: {
            secondary: "#555E68",
          },
        },
      },
    },
  });
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <StyledEngineProvider injectFirst>
        <JoyCssVarsProvider defaultMode="light" theme={theme}>
          <CssBaseline />
          <Box>
            <RouterProvider router={browserRouter()} />
          </Box>
        </JoyCssVarsProvider>
      </StyledEngineProvider>
    </MaterialCssVarsProvider>
  );
}
