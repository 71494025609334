import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { signInWithPopup } from "firebase/auth";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { countries } from "../../components/Countries";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events";
import i18next from "../../i18n/config";
import { auth, googleProvider } from "../../lib/firebase";
import { InvitationType } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import { TrackerService } from "../../services/tracker.service";
import {
  countryMap,
  getCountryCodeFromCountryName,
} from "../../utils/constants";

export const passwordValidation = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*-.])[A-Za-z\d!@#$%&*-.]{8,}$/
);

const generalSchema = z.object({
  agreed: z.boolean().default(false),
  companyName: z.string(),
  country: z
    .object({
      code: z.string(),
      dial: z.string(),
      label: z.string(),
    })
    .optional(),
  email: z.string().email({
    message: i18next.t("commons.error.form.required", {
      label: i18next.t("landing.signup.email"),
    }),
  }),
  fullName: z.string().min(1).max(100),
  mobile: z
    .string()
    .min(
      1,
      i18next.t("commons.error.form.required", {
        label: i18next.t("landing.signup.phoneNumber"),
      })
    )
    .max(
      100,
      i18next.t("commons.error.form.required", {
        label: i18next.t("landing.signup.phoneNumber"),
      })
    ),
  password: z
    .string()
    .min(8)

    .regex(passwordValidation, i18next.t("commons.error.form.password")),
  countryName: z.string(),
});
const invitationSchema = generalSchema.merge(
  z.object({
    companyName: generalSchema.shape.companyName.optional(),
    countryName: generalSchema.shape.countryName.optional(),
  })
);

const useContainer = () => {
  //#region ----- local states
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [urlSearchParams] = useSearchParams();
  const token = urlSearchParams.get("token");
  const schema = token ? invitationSchema : generalSchema;
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [invitationType, setInvitationType] = useState<InvitationType | null>(
    null
  );
  const callbackUrl = urlSearchParams.get("callbackURL");
  const resource = urlSearchParams.get("resource");
  const channel_type = urlSearchParams.get("channel_type");
  const navigate = useNavigate();
  const {
    control,
    setError,
    formState: { errors, isValid },
    watch,
    handleSubmit,
    register,
    setValue,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const invitationTokenMutation = useMutation({
    mutationFn: AuthService.invitationTokenCheck,
    onSuccess: ({ data }) => {
      if (data.redirectURL) {
        navigate(data.redirectURL);
      } else {
        setInvitationType(data.invitationType);
        setValue("email", data.email);
        if (data.phoneNumber) {
          setValue("mobile", data.phoneNumber.mobile);
          setValue("country", {
            code: data.phoneNumber.countryCode,
            dial: data.phoneNumber.countryCode + `${data.phoneNumber.mobile}`,
            label:
              countries.find((c) => c.code === data.phoneNumber.countryCode)
                ?.label ?? "",
          });
        }
      }
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
      navigate("/signup");
    },
  });

  const {
    agreed,
    password,
    mobile,
    country,
    countryName,
    fullName,
    companyName,
    email,
  } = watch();
  const mutation = useMutation({
    mutationFn: !token
      ? AuthService.signup
      : invitationTokenMutation.data?.data.hasBusinessProfile
      ? AuthService.signupInvitationWithRole
      : invitationTokenMutation.data?.data.hasAccount
      ? AuthService.signupInvitationWithoutRole
      : invitationType == InvitationType.BUSINESS_CONNECTION_INVITATION
      ? AuthService.signupInvitationBusinessConnect
      : AuthService.signup,
    onSuccess: ({ data }) => {
      if (data.token) {
        localStorage.setItem("phoneVerificationToken", data.token);
        localStorage.setItem("email", email);
        localStorage.setItem("countryCode", country?.code.toString() ?? "");
        localStorage.setItem("mobile", mobile);
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          if (callbackUrl) {
            navigate(`/verify?callbackURL=${encodeURIComponent(callbackUrl)}`);
          } else {
            navigate("/verify");
          }
        }, 4000);
      }
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  //#region ----- handler functions
  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }
  const onSubmit: SubmitHandler<z.infer<typeof schema>> = (data) => {
    let payload: any = {};

    if (invitationTokenMutation.data?.data.hasBusinessProfile) {
      payload = {
        fullName: data.fullName,
        localPhoneNumber: data.mobile,
        countryCode: data.country!.code,
        email: data.email,
        password: data.password,
      };
    } else {
      payload = {
        fullName: data.fullName,
        companyName: data.companyName!,
        localPhoneNumber: data.mobile,
        countryCode: data.country!.code,
        email: data.email,
        password: data.password,
        companyCountryCode: data.countryName!,
      };
    }
    const { password, ...filteredPayload } = payload;
    TrackerService.identify(data.email, {
      ...filteredPayload,
    });
    TrackerService.track(
      TRACKER_CONSTANTS.REGISTRATION_VIA_CATALOG.events.UserProceedWithSignUp,
      {
        "Signup method": "Email signup",
      }
    );

    if (invitationType == InvitationType.BUSINESS_CONNECTION_INVITATION) {
      payload.token = token;
    }
    if (payload.localPhoneNumber.startsWith("0")) {
      payload.localPhoneNumber = payload.localPhoneNumber.replace(/^0+/, "");
    }
    payload.email = data.email.toLowerCase();
    mutation.mutate(payload);
  };

  async function googleSignUpHandler() {
    try {
      TrackerService.track(
        TRACKER_CONSTANTS.REGISTRATION_VIA_CATALOG.events.UserProceedWithSignUp,
        {
          "Signup method": "Google signup",
        }
      );
      const result = (await signInWithPopup(auth, googleProvider)) as any;
      const accessToken = result.user.accessToken;
      localStorage.setItem("email", result.user.email);
      navigate(`/signup/sso${token ? `?token=${token}` : ""}`, {
        state: { accessToken },
      });
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  }
  //#endregion

  //#region life cycle method
  useEffect(() => {
    if (token) {
      invitationTokenMutation.mutate({ token });
    }
  }, [token]);

  useEffect(() => {
    if (countryName) {
      const countryCode: any = countryMap[countryName];
      if (countryCode) {
        setValue("country", countryCode);
      }
    }
  }, [countryName]);

  useEffect(() => {
    if (country?.code) {
      const countryName: any = getCountryCodeFromCountryName[country.code];
      if (countryName) {
        setValue("countryName", countryName);
      }
    }
  }, [country?.code]);

  useEffect(() => {
    setTimeout(() => {
      if (resource === "catalog" && channel_type === "email") {
        TrackerService.track(
          TRACKER_CONSTANTS.REGISTRATION_VIA_CATALOG.events.SignupPageVisited,
          {
            "Signup Source": "Email catalog invitation",
          }
        );
      } else if (resource === "Landing Page") {
        TrackerService.track(
          TRACKER_CONSTANTS.REGISTRATION_VIA_CATALOG.events.SignupPageVisited,
          {
            "Signup Source": resource,
          }
        );
      }
    }, 2500);
  }, []);

  //#endregion
  return {
    // functions
    googleSignUpHandler,
    handleSubmit,
    onSubmit,
    register,
    errors,
    control,
    showPassword,
    toggleShowPassword,
    setValue,
    setError,
    setShowTermsModal,
    privacyPolicy: showPrivacyPolicy,
    setPrivacyPolicy: setShowPrivacyPolicy,
    // states
    agreed,
    showTermsModal,
    password,
    token,
    isLoading: mutation.isPending || invitationTokenMutation.isPending,
    fullName,
    showModal,
    companyName,
    email,
    mobile,
    country,
    countryName,
    isValid,
    phoneNo: `${country?.code ?? ""}${country?.dial ?? ""}`,
    alrdyHasBusiness: invitationTokenMutation.isPending
      ? {}
      : invitationTokenMutation.data?.data.hasBusinessProfile,
  };
};

export default useContainer;
