import {
  Box,
  Button,
  Checkbox,
  Chip,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { theme } from "../../components/Theme";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InventoryService } from "../../services/inventory.service";
import { CatalogType } from "../../types/catalog";
import { ItemService } from "../../services/item.service";
import { formatPrice } from "../../utils/formatPrice";
import SeeDetails from "../../components/Inventory/Items/SeeDetails";
import { useAuthStore } from "../../store/session";
import { CartDataType, CartItemDataType } from "../../types/cart";
import { CatalogInventoryItemType } from "../../types/item";
import ConfirmDeleteModal from "../../components/Cart/ConfirmDeleteModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { formatDate } from "../../utils/formatDate";
import {
  calculatePrice,
  createCart,
  deleteCart,
  getCartItems,
  getQuantityFromCart,
  getSubtotal,
  getUOMFromCart,
  updateCartQty,
  updateCartUOM,
  validateCartItems,
  verifyCartItems,
} from "../../utils/cart";
import CartQuantityManager from "../../components/Catalogue/Item/CartQuantityManager";
import ItemsRemovedModal from "../../components/Cart/ItemsRemovedModal";
import LogoImg from "../../assets/linkz-logo-small.png";
import ItemsUpdatedModal from "../../components/Cart/ItemsUpdatedModal";
import { TrackerService } from "../../services/tracker.service";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events";

const PublicCarts = () => {
  const { catalogId } = useParams();
  const { t } = useTranslation();
  const { session } = useAuthStore.getState();

  const [isLoading, setIsLoading] = useState(false);
  const [catalog, setCatalog] = useState<CatalogType>();
  const [subtotal, setSubtotal] = useState(0);
  const [cartItems, setCartItems] = useState<CartDataType>();
  const [items, setItems] = useState<CatalogInventoryItemType[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [canOrder, setCanOrder] = useState(false);
  const [hasTracked, setHasTracked] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );
  const [removedItems, setRemovedItems] = useState<CatalogInventoryItemType[]>(
    []
  );
  const [updatedItems, setUpdatedItems] = useState<
    {
      cartItem: CartItemDataType;
      item: CatalogInventoryItemType;
    }[]
  >([]);
  const [showUpdatedItems, setShowUpdatedItems] = useState(false);

  const wildCheckout = () => {
    if (cartItems && catalogId) {
      createCart({
        payload: cartItems,
        storageType: "local",
      });
      deleteCart(catalogId, "wildcart", "session");

      if (catalog) {
        trackCart(cartItems, catalog, "post");
      }

      window.location.href = `/signin?callbackURL=/client/cart/${catalogId}/items?cart=wildcart`;
    }
    return;
  };

  const trackCart = (
    cartItems: CartDataType,
    catalog: CatalogType,
    timing: "pre" | "post"
  ) => {
    const itemsObject = cartItems.items
      .map((i, index) => {
        const item = items.find((item) => item.id === i.cid);
        if (item) {
          if (timing === "pre") {
            return {
              [`Product ${index + 1} ID`]: item.inventoryItem.id,
              [`Product ${index + 1} Name`]: item.inventoryItem.productName,
              [`Quantity ${index + 1}`]: i.quantity,
            };
          } else {
            return {
              [`Product ${index + 1} ID`]: item.inventoryItem.id,
              [`Product ${index + 1} Name`]: item.inventoryItem.productName,
              [`Quantity ${index + 1}`]: i.quantity,
              [`Total Product Value Amount ${index + 1}`]:
                calculatePrice(item, i.uom) * i.quantity,
            };
          }
        }
      })
      ?.reduce((acc, obj) => {
        return { ...acc, ...obj };
      }, {});

    TrackerService.track(
      timing === "pre"
        ? TRACKER_CONSTANTS.CATALOG_SUPPLIER.events.CartPageVisited
        : TRACKER_CONSTANTS.CATALOG_SUPPLIER.events.CreateOrderBtnClick,
      {
        "Catalog ID": catalog.id,
        "Catalog Name": catalog.catalogName,
        "Catalog Owner Company Name": catalog.business.companyName,
        "Product Count": cartItems.items.length,
        ...itemsObject,
      }
    );
  };

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);
  useEffect(() => {
    if (catalogId) {
      setIsLoading(true);
      InventoryService.getOneCatalog({
        catalogId: catalogId,
      })
        .then((res) => {
          setCatalog(res);
          const accountId = "wildcart";
          const tempCartItems = getCartItems(catalogId, accountId, "session");

          if (tempCartItems) {
            setCartItems(tempCartItems);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [catalogId]);
  useEffect(() => {
    if (cartItems && catalogId) {
      // setIsLoading(true);
      const ids = cartItems?.items.map((i) => i.cid) || [];

      if (cartItems.items.length > 0) {
        ItemService.getInventoryItemsByIds({ ids })
          .then((res) => {
            validateCartItems(res, cartItems, (result) => {
              setRemovedItems(result.removedItems);
              setUpdatedItems(result.updatedItems);

              if (
                result.removedItems.length === 0 &&
                result.updatedItems.length > 0
              ) {
                setShowUpdatedItems(true);
              }
            });
            setItems(res);
          })
          .catch((err) => console.error(err))
          .finally(() => {
            setIsLoading(cartItems.direct && !!session?.account.id);
          });
      } else {
        setItems([]);
        setIsLoading(false);
      }
    }
  }, [cartItems]);
  useEffect(() => {
    if (cartItems) {
      getSubtotal({
        selected,
        items,
        cartItems,
        callback: (subtotal) => setSubtotal(subtotal),
      });

      if (verifyCartItems(cartItems, items)) {
        setCanOrder(true);
      } else {
        setCanOrder(false);
      }
    }
  }, [selected, cartItems]);
  useEffect(() => {
    if (!hasTracked && catalog && cartItems && items.length > 0) {
      trackCart(cartItems, catalog, "pre");
      setHasTracked(true);
    }
  }, [catalog, items, cartItems, hasTracked]);

  const handleDeleteCart = () => {
    if (catalogId) {
      deleteCart(catalogId, "wildcart", "session");
      if (catalogId) {
        const newCartItems = getCartItems(catalogId, "wildcart", "session");
        setCartItems(newCartItems ?? undefined);
      }

      window.location.reload();
    }
  };

  const handleCheckOut = () => {
    const verifiedItems = items.filter((i) => {
      const stillInCart = cartItems?.items.find((ci) => ci.cid === i.id);
      if (stillInCart) {
        return i;
      }
    });

    if (verifiedItems.length === 0) {
      setCanOrder(false);
      return;
    }

    wildCheckout();
  };

  return (
    <div className={`lg:px-6 ${session?.account.id ? "px-4" : "px-8"}`}>
      <div className="w-full py-4 lg:flex items-center">
        <img
          src={LogoImg}
          alt="linkz-logo"
          width={109.05}
          height={52}
          className={"mr-auto"}
        />

        <span className="ml-auto flex flex-col lg:flex-row gap-2">
          {t("catalog.loginToSeePrice")}
          <Link to={"/signin"} className="ml-auto">
            <Button className="min-w-[3rem]">{t("landing.loginBtn")}</Button>
          </Link>
        </span>
      </div>

      <Box
        my={4}
        sx={{
          display: "flex",
        }}
      >
        <Stack gap={1}>
          <span className="hidden lg:block">
            <Typography fontSize={16} fontWeight={600}>
              {t("catalog.cart")}{" "}
              {cartItems
                ? cartItems.items.length > 0 && `(${cartItems?.items.length})`
                : ""}
            </Typography>
          </span>
          <Typography fontSize={16}>
            <span className="text-sm lg:text-base font-semibold lg:font-normal">
              {catalog?.business.companyName}
            </span>
          </Typography>
          <Typography
            fontSize={14}
            sx={{
              color: theme.colorSchemes.dark.palette.text.secondary,
            }}
          >
            <span className="text-xs lg:text-sm lg:hidden">
              {t("commons.lastUpdated")}:{" "}
              <span className="ml-2">
                {formatDate(new Date(catalog?.updatedAt ?? ""))}
              </span>
            </span>
          </Typography>
        </Stack>
      </Box>
      <hr
        className={`mt-4 w-full lg:hidden border-b border-solid border-[${theme.colorSchemes.light.palette.others.disabled}]`}
      />

      <List sx={{ marginTop: 3 }}>
        {cartItems &&
          items.map((i) => {
            const currency = i.currency ?? i.inventoryItem.currency;
            return (
              <ListItem key={i.id} sx={{ display: "block" }}>
                <div className={`pt-4 flex align-middle w-full`}>
                  <Checkbox
                    className="mt-[40px] mr-2 lg:mr-6"
                    onChange={(e) => {
                      const isExist = selected.find(
                        (s) => s === i.inventoryItem.id
                      );
                      window.dispatchEvent(new Event("storage"));

                      if (isExist && !e.target.checked) {
                        const newSelected = selected.filter(
                          (s) => s !== i.inventoryItem.id
                        );
                        setSelected(newSelected);
                      } else if (!isExist && e.target.checked) {
                        setSelected([...selected, i.inventoryItem.id]);
                      }
                    }}
                  />
                  <Stack gap={1}>
                    <img
                      src={`${i.inventoryItem.thumbnail ?? "/union.png"}`}
                      alt="item_image"
                      // width={100}
                      // height={100}
                      className="border border-gray-300 rounded-lg mr-4 p-2 xs:h-[100px] sm:w-[176.5px] sm:h-[176.5px] lg:w-[100px] lg:h-[100px] object-contain flex-shrink-0"
                    />
                    <Typography
                      mt={1}
                      // ml={1}
                      sx={{
                        color: theme.colorSchemes.dark.palette.text.secondary,
                        fontSize: 16,
                        width: 100,
                        textAlign: "left",
                        wordWrap: "break-word",
                        justifyContent: "center",
                        wordBreak: "break-all",
                      }}
                    >
                      <span className="text-white font-semibold">
                        {`${currency} ${
                          catalog?.hidePrice
                            ? "-"
                            : formatPrice(
                                i.price ?? i.inventoryItem.price,
                                currency
                              )
                        }`}
                      </span>
                    </Typography>
                  </Stack>
                  <div className="ml-2 lg:ml-3 w-3/5">
                    <Stack gap={1}>
                      {isMobile ? (
                        <>
                          <Typography
                            fontSize={16}
                            sx={{
                              color:
                                theme.colorSchemes.dark.palette.text.secondary,
                            }}
                          >
                            <span className="text-black font-bold lg:mt-0">
                              {i.inventoryItem.productName}
                            </span>
                          </Typography>
                          <Typography
                            fontSize={16}
                            sx={{
                              color:
                                theme.colorSchemes.dark.palette.text.secondary,
                            }}
                          >
                            <span className="text-black">
                              SKU: {i.inventoryItem.sku}
                            </span>
                          </Typography>
                          <Typography
                            className="text-black font-bold"
                            fontSize={16}
                            sx={{
                              wordBreak: "break-all",
                            }}
                          >
                            {`${currency} ${
                              catalog?.hidePrice
                                ? "-"
                                : formatPrice(
                                    calculatePrice(
                                      i,
                                      getUOMFromCart(i.id, cartItems)
                                    ),
                                    currency
                                  )
                            }`}
                            {i.inventoryItem[getUOMFromCart(i.id, cartItems)] &&
                              `/${
                                i.inventoryItem[getUOMFromCart(i.id, cartItems)]
                              }`}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            fontSize={16}
                            sx={{
                              color:
                                theme.colorSchemes.dark.palette.text.secondary,
                            }}
                          >
                            <span className="text-sm lg:text-base">
                              SKU:{" "}
                              <span className="text-black">
                                {i.inventoryItem.sku}
                              </span>
                            </span>
                          </Typography>
                          <Typography
                            fontSize={16}
                            sx={{
                              color:
                                theme.colorSchemes.dark.palette.text.secondary,
                            }}
                          >
                            <span className="text-sm lg:text-base">
                              {t("catalog.supplier.items.productName")}:{" "}
                              <span className="text-black">
                                {i.inventoryItem.productName}
                              </span>
                            </span>
                          </Typography>
                          <Chip
                            variant="outlined"
                            sx={{
                              color:
                                theme.colorSchemes.dark.palette.neutral[400],
                            }}
                          >
                            {i.inventoryItem.inventoryType}
                          </Chip>
                        </>
                      )}

                      <div className="w-full flex lg:block">
                        {catalog && (
                          <>
                            <Button
                              type="button"
                              variant="plain"
                              className="ml-auto w-full lg:w-[10rem] text-nowrap border-[#475467] text-[#475467] hover:text-white hover:bg-[#475467] hover:border-[#475467]"
                              onClick={() => {
                                updateCartQty({
                                  catalogId: catalog.id,
                                  accountId: "wildcart",
                                  override: true,
                                  quantityChange: 0,
                                  inventoryItem: i,
                                  direct: session?.account.id ? false : true,
                                  activeUOM: getUOMFromCart(i.id, cartItems),
                                  storageType: session?.account.id
                                    ? "local"
                                    : "session",
                                  callback: (newCartItems) => {
                                    setCartItems(newCartItems);
                                  },
                                });
                              }}
                            >
                              {t(`commons.deleteBtn`)}
                            </Button>
                            <SeeDetails
                              item={i}
                              catalog={catalog}
                              setCartUpdated={() => {}}
                              withAddToCart={false}
                              selectedUOM={getUOMFromCart(i.id, cartItems)}
                              updateUOMCallback={(uom) => {
                                updateCartUOM({
                                  catalogId: catalog.id,
                                  accountId: "wildcart",
                                  inventoryItem: i,
                                  uom: uom,
                                  storageType: session?.account.id
                                    ? "local"
                                    : "session",
                                  callback: (newCartItems) => {
                                    setCartItems(newCartItems);
                                    if (newCartItems.items.length === 0) {
                                      setCanOrder(false);
                                    }
                                  },
                                });
                              }}
                              isDisabled={
                                !selected.includes(i.inventoryItem.id)
                              }
                            />
                          </>
                        )}
                      </div>
                    </Stack>
                  </div>

                  {/* // TODO: use context to simplify all this */}
                  {!isMobile && catalog && cartItems && (
                    <CartQuantityManager
                      item={i}
                      hidePrice={catalog?.hidePrice}
                      catalog={catalog}
                      isDisabled={!selected.includes(i.inventoryItem.id)}
                      quantity={getQuantityFromCart(i.id, cartItems)}
                      updateUOMCallback={(uom) => {
                        updateCartUOM({
                          catalogId: catalog.id,
                          accountId: "wildcart",
                          inventoryItem: i,
                          uom: uom,
                          storageType: session?.account.id
                            ? "local"
                            : "session",
                          callback: (newCartItems) => {
                            setCartItems(newCartItems);
                          },
                        });
                      }}
                      updateQtyCallback={(cartItems: CartDataType) => {
                        setCartItems(cartItems);
                      }}
                      updateCart
                      defaultUOM={getUOMFromCart(i.id, cartItems)}
                    />
                  )}
                </div>

                <div className="w-full flex align-middle justify-center lg:hidden">
                  {catalog && cartItems && (
                    <CartQuantityManager
                      item={i}
                      hidePrice={catalog.hidePrice}
                      catalog={catalog}
                      isDisabled={!selected.includes(i.inventoryItem.id)}
                      quantity={getQuantityFromCart(i.id, cartItems)}
                      updateUOMCallback={(uom) => {
                        // TODO: make this into a handler function
                        updateCartUOM({
                          catalogId: catalog.id,
                          accountId: "wildcart",
                          inventoryItem: i,
                          uom: uom,
                          storageType: session?.account.id
                            ? "local"
                            : "session",
                          callback: (newCartItems) => {
                            setCartItems(newCartItems);
                          },
                        });
                      }}
                      updateQtyCallback={(cartItems: CartDataType) => {
                        setCartItems(cartItems);
                      }}
                      updateCart
                      defaultUOM={getUOMFromCart(i.id, cartItems)}
                    />
                  )}
                </div>
                <hr
                  className={`mt-5 w-full lg:hidden border-b border-solid border-[${theme.colorSchemes.light.palette.others.disabled}]`}
                />
              </ListItem>
            );
          })}
      </List>

      <hr
        className={`mt-36 w-full hidden lg:block border-2 border-b border-solid border-[${theme.colorSchemes.light.palette.others.disabled}]`}
      />
      <div className="w-full lg:flex align-middle py-6">
        <div className="flex align-middle lg:block">
          <Typography fontSize={18}>
            <span className="text-sm lg:text-lg">
              {t("catalog.total")}:{" "}
              <span className="text-black font-[600]">
                {`${
                  items[0]?.currency ?? items[0]?.inventoryItem.currency ?? ""
                } ${
                  catalog?.hidePrice
                    ? "-"
                    : formatPrice(
                        subtotal,
                        items[0]?.currency ?? items[0]?.inventoryItem.currency
                      )
                }`}
              </span>
            </span>
          </Typography>
          <Typography fontSize={14}>
            <span className="hidden lg:block lg:mt-3">
              {selected.length} out of {items.length} items selected
            </span>
          </Typography>
        </div>

        <div className="mt-3 lg:mt-0 flex flex-col lg:flex-row align-middle lg:ml-auto gap-3">
          <Button
            onClick={() => {
              setIsDeleteModalOpen(true);
            }}
            variant="outlined"
            className="my-auto"
          >
            {t("catalog.deleteCartBtn")}
          </Button>
          <Button
            onClick={handleCheckOut}
            className="my-auto"
            disabled={selected.length === 0 || !canOrder}
          >
            {t("catalog.createOrderBtn")}
          </Button>
        </div>
      </div>

      <ConfirmDeleteModal
        show={isDeleteModalOpen}
        onClick={handleDeleteCart}
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
      />
      <LoadingModal isLoading={isLoading} />
      <ItemsRemovedModal
        show={removedItems.length > 0}
        onClick={() => {}}
        onClose={() => {
          setRemovedItems([]);

          if (catalogId && updatedItems.length === 0) {
            if (updatedItems.length > 0) {
              setShowUpdatedItems(true);
            } else {
              const newCartItems = getCartItems(
                catalogId,
                "wildcart",
                "session"
              );
              setCartItems(newCartItems ?? undefined);
            }
          }
        }}
        removedItems={removedItems}
      />
      <ItemsUpdatedModal
        show={showUpdatedItems}
        onClick={() => {}}
        onClose={() => {
          setUpdatedItems([]);

          if (catalogId) {
            const newCartItems = getCartItems(catalogId, "wildcart", "session");
            setCartItems(newCartItems ?? undefined);
          }
        }}
        updatedItems={updatedItems}
      />
    </div>
  );
};

export default PublicCarts;
