import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Stack,
  Textarea,
} from "@mui/joy";
import React, { Dispatch, SetStateAction } from "react";
import { ItemType } from "../../../types/item";
import UploadImageBtn from "../Items/UploadImageBtn";
import { FormLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import AdditionalInfoInput from "./AdditionalInfoInput";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

type Props = {
  currentItem: ItemType;
  handleDeleteImage: () => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  setAlertMessage: Dispatch<SetStateAction<string>>;
  allSku: string[];
  allTags: string[];
};

const ProductDetailsSection = ({
  currentItem,
  handleDeleteImage,
  setIsLoading,
  setShowAlert,
  setAlertMessage,
  allSku,
  allTags,
}: Props) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isCreate = searchParams.get("mode") === "create";
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <section className="w-full lg:border-2 border-gray-200 rounded-lg flex flex-col lg:p-8 gap-6 m-0">
      {isCreate ? (
        <Stack direction={"row"} gap={"24px"} justifyContent={"left"}>
          {[1].map((_i) => (
            <UploadImageBtn
              item={currentItem}
              // size={43.2}
              size={180}
              variant="inventory"
              setIsLoading={setIsLoading}
              setShowToast={setShowAlert}
              setAlertMessage={setAlertMessage}
              label={`${t("catalog.inventory.editForm.imageLabel")}`}
            />
          ))}
        </Stack>
      ) : (
        <>
          <img
            src={`${currentItem?.thumbnail ?? "/union.png"}`}
            alt="item_image"
            // width={100}
            // height={100}
            className="border-b lg:border border-gray-300 lg:rounded-lg w-full sm:w-[176.5px] sm:h-[176.5px] lg:w-[230px] lg:h-[230px] object-contain flex-shrink-0 mx-auto"
          />
          <Stack
            direction={"row"}
            justifyContent={"center"}
            width={"100%"}
            mt={4}
            gap={2}
          >
            <Button
              type="button"
              disabled={currentItem?.thumbnail ? false : true}
              variant="plain-gray"
              className="text-nowrap"
              onClick={handleDeleteImage}
            >
              {t("catalog.inventory.editForm.deletePhotoBtn")}
            </Button>

            {currentItem && (
              <UploadImageBtn
                item={currentItem}
                setIsLoading={setIsLoading}
                setShowToast={setShowAlert}
                setAlertMessage={setAlertMessage}
                variant="button"
                callback={() => {
                  window.location.reload();
                }}
              />
            )}
          </Stack>
        </>
      )}

      {currentItem && (
        <Grid container spacing={2} mt={4} mb={2}>
          <Grid xs={6} lg={6}>
            <FormControl sx={{ flex: "1" }} error={!!errors.sku}>
              <FormLabel>{t("catalog.inventory.editForm.sku")}</FormLabel>
              <Input
                type="text"
                placeholder={t("catalog.inventory.editForm.sku")}
                defaultValue={currentItem?.sku}
                aria-label="sku"
                {...register("sku", {
                  required: t("commons.error.form.required", {
                    label: t("catalog.inventory.editForm.sku"),
                  }),
                  validate: (value) => {
                    console.log("VALIDATE", value);
                    const duplicate = allSku.filter((i) => i === value);
                    return duplicate.length > 1
                      ? t("commons.error.form.duplicateSku")
                      : true;
                  },
                })}
                error={!!errors.sku}
                className="min-h-[3.5rem]"
              />
              <FormHelperText>{(errors.sku as any)?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid xs={6} lg={6}>
            <FormControl sx={{ flex: "1" }} error={!!errors.productName}>
              <FormLabel>
                {t("catalog.inventory.editForm.productName")}
              </FormLabel>
              <Input
                type="text"
                placeholder={t("catalog.inventory.editForm.productName")}
                defaultValue={currentItem?.productName}
                aria-label="productName"
                {...register("productName", {
                  required: t("commons.error.form.required", {
                    label: t("catalog.inventory.editForm.productName"),
                  }),
                })}
                error={!!errors.productName}
                className="min-h-[3.5rem]"
              />
              <FormHelperText>
                {(errors.productName as any)?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid xs={6} lg={6}>
            <FormControl sx={{ flex: "1" }} error={!!errors.inventoryType}>
              <FormLabel>
                {t("catalog.inventory.editForm.inventoryType")}
              </FormLabel>
              <Input
                type="text"
                placeholder={t("catalog.inventory.editForm.inventoryType")}
                defaultValue={currentItem?.inventoryType}
                aria-label="inventoryType"
                {...register("inventoryType", {
                  required: t("commons.error.form.required", {
                    label: t("catalog.inventory.editForm.inventoryType"),
                  }),
                })}
                error={!!errors.inventoryType}
                className="min-h-[3.5rem]"
              />
              <FormHelperText>
                {(errors.inventoryType as any)?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid xs={6} lg={6}>
            <Controller
              name={"tags.tags"}
              control={control}
              render={({ field }) => {
                const { onChange } = field;
                return (
                  <FormControl sx={{ flex: "1" }}>
                    <FormLabel>
                      {t("catalog.inventory.editForm.subCategory")}
                    </FormLabel>
                    <Autocomplete
                      options={allTags}
                      placeholder={t("catalog.inventory.editForm.subCategory")}
                      getOptionLabel={(option) => option}
                      freeSolo
                      multiple
                      limitTags={3}
                      defaultValue={currentItem.tags?.tags ?? []}
                      onChange={(_e, data) => onChange(data)}
                    />
                  </FormControl>
                );
              }}
            />
          </Grid>
          <Grid xs={12}>
            <Controller
              name="productDescription"
              control={control}
              defaultValue={currentItem?.productDescription}
              render={({ field: { value, ref } }) => (
                <FormControl sx={{ flex: "1" }}>
                  <FormLabel>
                    {t("catalog.inventory.editForm.productDescription")}
                  </FormLabel>
                  <Textarea
                    ref={ref}
                    placeholder={t(
                      "catalog.inventory.editForm.productDescription"
                    )}
                    minRows={4}
                    onChange={(
                      event: React.ChangeEvent<HTMLTextAreaElement>
                      // newValue: string | null
                    ) => {
                      setValue("productDescription", event.target.value ?? "");
                    }}
                    defaultValue={value}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid xs={12}>
            <AdditionalInfoInput />
          </Grid>
        </Grid>
      )}
    </section>
  );
};

export default ProductDetailsSection;
