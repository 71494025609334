import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Button,
  Input,
  List,
  ListItem,
  Sheet,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from "@mui/joy";
import { debounce } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { theme } from "../../components/Theme";
import { SupplierService } from "../../services/supplier.service";
import { getActiveBusiness } from "../../store/session";
import { formatDate } from "../../utils/formatDate";
import ListUnavailable from "../../components/Catalogue/ListUnavailable";
import InfiniteScroll from "react-infinite-scroller";
import useContainer from "./useContainer";
import { OrderService } from "../../services/order.service";

function ListSuppliers() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("order");
  const unsavedOrderData = localStorage.getItem(`unsavedOrderData_${orderId}`);
  const query = searchParams.get("q");

  const [sellerId, setSellerId] = useState(
    unsavedOrderData ? JSON.parse(unsavedOrderData).sellerIds?.businessId : null
  );
  const [selectedTab, setSelectedTab] = useState("public");
  // const loaderRef = useRef(null);

  const {
    fetchCatalogs,
    catalogs,
    setCatalogs,
    pagination,
    setPagination,
    searchTerm,
    setSearchTerm,
    setIsLoading,
    hasMore,
    setHasMore,
  } = useContainer();

  const initiateAllCatalogs = useCallback(
    ({
      businessId,
      sellerId = null,
      catalogType = "PUBLIC",
    }: {
      businessId: string;
      sellerId?: string | null;
      catalogType?: string;
    }) => {
      // console.log(`fetching catalogue scope = ${searchTerm}`);
      setIsLoading(true);
      SupplierService.getAllCatalogs({
        businessId,
        sellerId,
        paging: true,
        limit: 10,
        offset: 0,
        catalogType,
      })
        .then((res) => {
          console.log("RESPONSE LOADMORE", res);
          setCatalogs(res.data);

          if (res.pagination) {
            setPagination(res.pagination);
          }

          if (res.data.length === 0) {
            setHasMore(false);
          }

          console.log("LOADMORE CATALOGS", catalogs);
        })
        .catch((error: any) => console.error(error))
        .finally(() => setIsLoading(false));
    },
    []
  );

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;
    if (!businessId) return;
    // console.log({ query, searchTerm });
    if (query && !searchTerm) {
      // console.log("cancelling");
      return;
    }

    if (searchTerm || searchTerm === "") {
      fetchCatalogs({
        businessId: getActiveBusiness()?.id ?? "",
        sellerId: sellerId,
        limit: 10,
        offset: 0,
        catalogType: selectedTab.toUpperCase(),
        searchTerm: searchTerm,
        isReset: true,
      });
    }
  }, [searchTerm]);
  
  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     const target = entries[0];
  //     if (target.isIntersecting && hasMore && catalogs.length > 0) {
  //       fetchCatalogs({
  //         businessId: getActiveBusiness()?.id ?? "",
  //         sellerId: unsavedOrderData
  //           ? JSON.parse(unsavedOrderData).sellerIds.businessId
  //           : null,
  //         limit: 10,
  //         offset: pagination.limit * pagination.current,
  //         catalogType: selectedTab.toUpperCase(),
  //       });
  //     }
  //   });

  //   if (loaderRef.current) {
  //     observer.observe(loaderRef.current);
  //   }
  
  //   return () => {
  //     if (loaderRef.current) {
  //       observer.unobserve(loaderRef.current);
  //     }
  //   };
  // }, [fetchCatalogs]);

  useEffect(() => {
    const businessId = getActiveBusiness()?.id;
    if (!businessId) return;
    if (query && !searchTerm) return;
    setIsLoading(true);

    if (orderId && !unsavedOrderData) {
      OrderService.getOrder(orderId)
        .then((res) => {
          setSellerId(res.seller.id ?? null);
          initiateAllCatalogs({
            businessId: getActiveBusiness()?.id ?? "",
            sellerId: sellerId,
            catalogType: selectedTab.toUpperCase(),
          });
        })
        .catch((error) => {
          console.error(error);
          throw error;
        })
        .finally(() => setIsLoading(false));
    } else {
      initiateAllCatalogs({
        businessId: getActiveBusiness()?.id ?? "",
        sellerId: sellerId,
        catalogType: selectedTab.toUpperCase(),
      });
    }
  }, [orderId, unsavedOrderData, selectedTab]);
  useEffect(() => {
    if (query) {
      setSearchTerm(query);
    }
  }, [query]);

  const handleChange = (
    _event: React.SyntheticEvent | null,
    value: string | number | null
  ) => {
    if (typeof value === "string") {
      setSelectedTab(value);
      setCatalogs([]);
      fetchCatalogs({
        businessId: getActiveBusiness()?.id ?? "",
        sellerId: sellerId,
        limit: 10,
        offset: 0,
        catalogType: value.toUpperCase(),
      });
    }
  };

  const handleSearchChange = useCallback(
    debounce((query) => {
      if (query) {
        searchParams.set("q", query);
      } else {
        searchParams.delete("q");
      }
      setSearchParams(searchParams);
      setSearchTerm(query);
    }, 300), // 300ms debounce delay
    []
  );

  const SuppliersView = () => {
    console.log("LOADMORE CATALOGS IN VIEW", catalogs);
    return (
      <>
        {/* <Input
          variant="linkz-input-search"
          size="sm"
          name="searchFormData.search"
          placeholder={t("catalog.searchItemPlaceholder")}
          startDecorator={<SearchOutlinedIcon />}
          sx={{ flexGrow: 1, marginTop: 6, width: {xs: "100%", lg: "50%"} }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(event.target.value);
          }}
        /> */}
        <List sx={{ marginTop: 3 }}>
          <InfiniteScroll
            pageStart={0}
            // initialLoad
            loadMore={(_page: number) => {
              // console.log("LOADMORE TRIGGERED", pagination.limit, page);
              fetchCatalogs({
                businessId: getActiveBusiness()?.id ?? "",
                sellerId: unsavedOrderData
                  ? JSON.parse(unsavedOrderData).sellerIds.businessId
                  : null,
                limit: 10,
                offset: pagination.limit * pagination.current,
                catalogType: selectedTab.toUpperCase(),
              });
            }}
            hasMore={hasMore}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            {catalogs.map((cat) => {
              return (
                <ListItem
                  key={cat.id}
                  sx={{
                    paddingX: 0,
                  }}
                >
                  <div
                    className={`py-2 px-2.5 lg:py-4 my-1 lg:my-2 lg:flex algin-middle w-full rounded-lg lg:rounded-none border lg:border-t-0 lg:border-l-0 lg:border-r-0 lg:border-b-1 border-[${theme.colorSchemes.light.palette.others.disabled}]`}
                  >
                    <div className="mb-2">
                      <Typography fontWeight={700} fontSize={16}>
                        <span className="text-sm lg:text-base ">
                          {cat.business.companyName}
                        </span>
                      </Typography>
                      <Typography fontSize={14}>
                        <span className="hidden text-xs lg:text-sm lg:block">
                          {cat.catalogName}
                        </span>
                      </Typography>
                      <Typography
                        fontSize={14}
                        sx={{
                          color: theme.colorSchemes.dark.palette.text.secondary,
                        }}
                      >
                        <span className="text-xs lg:text-sm">
                          {t("commons.lastUpdated")}:{" "}
                          <span className="ml-2">
                            {formatDate(new Date(cat?.updatedAt ?? ""))}
                          </span>
                        </span>
                      </Typography>
                    </div>
                    <Link
                      to={`/client/suppliers/${cat.id}/items${
                        orderId ? `?order=${orderId}` : ""
                      }`}
                      className="mx-auto lg:ml-auto lg:mr-0"
                    >
                      <Button
                        variant="outlined"
                        className="w-full mt-4 text-sm lg:mt-0 text-nowrap lg:text-sm"
                      >
                        {t("catalog.viewCatalogBtn")}
                      </Button>
                    </Link>
                  </div>
                </ListItem>
              );
            })}
          </InfiniteScroll>
        </List>
        {/* <div ref={loaderRef} className="flex justify-center w-full">
          {isLoading && "Loading..."}
        </div> */}
      </>
    );
  };

  return (
    <Sheet sx={{ backgroundColor: "transparent" }}>
      <Tabs
        aria-label="Basic tabs"
        value={selectedTab}
        onChange={handleChange}
        className="w-[90vw] md:w-[75vw]"
        sx={{
          backgroundColor: "transparent",
          marginTop: 4,
        }}
      >
        <TabList
          sx={{
            [`&& .${tabClasses.root}`]: {
              "&:hover": {
                bgcolor: "transparent",
              },
              [`&.${tabClasses.selected}`]: {
                "&::after": {
                  bgcolor: "primary.500",
                  borderTopLeftRadius: 3,
                  borderTopRightRadius: 3,
                  height: 2,
                },
                color: "primary.plainColor",
              },
              bgcolor: "transparent",
              flex: "initial",
              paddingY: 2,
            },
          }}
        >
          <Tab
            value={"public"}
            key={"public"}
            sx={{
              fontWeight: "600",
              whiteSpace: "nowrap",
              minWidth: 100,
              flex: "none",
              scrollSnapAlign: "start",
              flexDirection: "column",
            }}
          >
            {t("catalog.catalogType.public")}
          </Tab>
          <Tab
            value={"private"}
            key={"private"}
            sx={{
              fontWeight: "600",
              whiteSpace: "nowrap",
              minWidth: 150,
              flex: "none",
              scrollSnapAlign: "start",
              flexDirection: "column",
            }}
          >
            {t("catalog.catalogType.private")}
          </Tab>
        </TabList>

        {/* default public empty tab */}
        <TabPanel value={"public"} key={"public"} className="px-0 py-0 lg:px-6">
          <Input
            defaultValue={query ?? ""}
            variant="linkz-input-search"
            name="searchFormData.search"
            size="sm"
            placeholder={t("catalog.searchItemPlaceholder")}
            startDecorator={<SearchOutlinedIcon />}
            sx={{
              flexGrow: 1,
              marginTop: 6,
              width: { xs: "100%", lg: "41%" },
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const query = event.target.value;
              handleSearchChange(query);
            }}
          />
          {catalogs.length > 0 ? (
            <SuppliersView />
          ) : (
            <ListUnavailable
              message={t("catalog.emptySupplierCatalogPrompt")}
            />
          )}

          {/* ? (
            ) : (
              <ListUnavailable
                message={t("catalog.emptySupplierCatalogPrompt")}
              />
            )
          ) : (
            <>Loading...</>
          )} */}
        </TabPanel>

        {/* default private empty tab */}
        <TabPanel
          value={"private"}
          key={"private"}
          className="px-0 py-0 lg:px-6"
        >
          <Input
            defaultValue={query ?? ""}
            variant="linkz-input-search"
            name="searchFormData.search"
            placeholder={t("catalog.searchItemPlaceholder")}
            startDecorator={<SearchOutlinedIcon />}
            size="sm"
            sx={{
              flexGrow: 1,
              marginTop: 6,
              width: { xs: "100%", lg: "41%" },
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const query = event.target.value;
              handleSearchChange(query);
            }}
          />

          {catalogs.length > 0 ? (
            <SuppliersView />
          ) : (
            <ListUnavailable
              message={t("catalog.emptySupplierCatalogPrompt")}
            />
          )}

          {/* {!isLoading ? (
            catalogs.length > 0 ? (
              <SuppliersView />
            ) : (
              <ListUnavailable
                message={t("catalog.emptySupplierCatalogPrompt")}
              />
            )
          ) : (
            <>Loading...</>
          )} */}
        </TabPanel>
      </Tabs>
    </Sheet>
  );
}

export default ListSuppliers;
