import {
  Autocomplete,
  AutocompleteOption,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { SuccessModal } from "../SuccessModal";
import LoadingModal from "../LoadingModal/LoadingModal";
import { IBusinessResponse } from "../../types/business";
import { AccountService } from "../../services/account.service";
import { Add, CancelOutlined, InfoOutlined } from "@mui/icons-material";
import { theme } from "../Theme";
import { ICatalogInviteCreatePayload } from "../../types/catalog";
import { InventoryService } from "../../services/inventory.service";
import { useTranslation } from "react-i18next";

interface Props {
  show?: boolean;
  onClose: () => void;
  catalogId: string;
  businessId: string;
  catalogType: string;
}

export interface TempAccountType {
  id?: string;
  email: string;
  role?: {
    roleType: string;
    isOwnder: boolean | null;
    business: IBusinessResponse;
  }[];
}

interface RecipientType {
  email: string;
  hasBusiness: boolean;
  selectedBusinessId: string | null;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  width: "94%",
  maxWidth: 800,
  px: 4,
  py: 4,
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
};

const ShareModal = ({
  show = false,
  onClose,
  catalogId,
  businessId,
  catalogType,
}: Props) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [canAdd, setCanAdd] = useState(false);
  // const [currentEmail, setCurrentEmail] = useState<string>("");
  const [currentAccount, setCurrentAccount] = useState<TempAccountType | null>(
    null
  ); // current account selected in autocomplete
  const [accountOptions, setAccountOptions] = useState<TempAccountType[]>([]); // options for autocomplete
  const [selectedAccounts, setSelectedAccounts] = useState<TempAccountType[]>(
    []
  ); // will contains selected, trigger cancel button on accountList render
  const [recipients, setRecipients] = useState<RecipientType[]>([]); // will contains all recipient (pair of email and business)

  const validateEmail = (
    email: string,
    emails: string[] = selectedAccounts.map((acc) => acc.email)
  ) => {
    const valid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return valid.test(email.toLowerCase()) && !emails.includes(email);
  };

  const handleAddEmail = (account: TempAccountType) => {
    setSelectedAccounts((prev) => [...prev, account]);
    setRecipients((prev) => [
      ...prev,
      {
        email: account.email,
        hasBusiness: account.role ? true : false,
        selectedBusinessId:
          account.role?.length === 1 ? account.role[0].business.id : null,
      },
    ]);
  };

  const handleRemoveEmail = (account: TempAccountType) => {
    const removedList = selectedAccounts.filter(
      (acc) => acc.email !== account.email
    );
    console.log("REMOVED LIST", removedList);
    const removedRecipient = recipients.filter(
      (acc) => acc.email !== account.email
    );

    setSelectedAccounts(removedList);
    setRecipients(removedRecipient);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentAccount(null);
    setAccountOptions([]);
    setSelectedAccounts([]);
    onClose();
  };

  const handleSelectBusiness = (email: string, businessId: string) => {
    const index = recipients.findIndex((rec) => rec.email === email);
    if (index !== -1) {
      setRecipients([
        ...recipients.slice(0, index),
        { ...recipients[index], selectedBusinessId: businessId },
        ...recipients.slice(index + 1),
      ]);
    }
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const onSubmit = () => {
    setIsLoading(true);

    // validate
    for (let i = 0; i < recipients.length; i++) {
      if (recipients[i].hasBusiness) {
        if (recipients[i].selectedBusinessId === null) {
          setIsLoading(false);
          console.error(
            `Error on item ${i + 1}, recipient business not selected`
          );

          return false;
        }
      }
    }

    const invitesPayload: ICatalogInviteCreatePayload[] = [];
    recipients.forEach((rec) => {
      invitesPayload.push({
        catalog: catalogId,
        senderBusiness: businessId,
        catalogInvitationStatus: rec.hasBusiness ? "ACCEPTED" : "PENDING",
        recipientEmail: rec.email,
        recipientBusiness: rec.hasBusiness ? rec.selectedBusinessId : null,
      });
    });

    InventoryService.createCatalogInvite({
      catalogInvites: invitesPayload,
      catalogId: catalogId,
    })
      .then((res) => {
        if (res) {
          setShowAlert(true);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false);
        setSelectedAccounts([]);
      });

    setTimeout(() => {
      setShowAlert(false);
      handleClose();
    }, 4000);
  };

  return (
    <Modal
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} justifyContent={"center"}>
        <Typography
          mt={4}
          textAlign={"center"}
          fontWeight={600}
          fontSize={24}
          id="modal-modal-title"
          sx={{
            color: "#265641",
          }}
        >
          {t("catalog.inventory.sendCatalogLabel")}
        </Typography>
        <div className="w-full flex flex-col mt-10">
          {/* copy link  */}
          {/* <Stack direction={"row"} justifyContent={"space-between"} mt={4}>
            <Typography>
              {t("catalog.inventory.sendCatalogModalPrompt")}
            </Typography>

            <CopyLinkButton
              url={`${
                window.location.href.split("/client")[0]
              }/catalog/${catalogId}/items`}
            />
          </Stack> */}
          <div
            className={`w-full px-4 py-2 bg-[#CFE5DA] rounded-md flex items-center`}
          >
            <InfoOutlined
              style={{
                marginRight: "0.5rem",
                width: 24,
                height: 24,
              }}
            />
            <p>
              {t(`catalog.inventory.shareCatalog.${catalogType}CatalogRemark`)}
            </p>
          </div>

          <>
            {/* search input */}
            <Grid container spacing={2} mt={4}>
              <Grid xs={12} lg={10}>
                <Autocomplete
                  sx={{
                    width: "100%",
                  }}
                  placeholder={t(
                    "catalog.inventory.sendCatalogModalPlaceholder"
                  )}
                  options={[...accountOptions]}
                  getOptionLabel={(option) => option.email}
                  onInputChange={(
                    _event: React.SyntheticEvent,
                    value: string
                  ) => {
                    if (value.includes("@")) {
                      AccountService.getAccountByEmail(value)
                        .then((res) => {
                          const emails = selectedAccounts.map(
                            (acc) => acc.email
                          );
                          const newOptions = res.filter(
                            (acc) => !emails.includes(acc.email)
                          );
                          setAccountOptions(newOptions);

                          if (validateEmail(value) && res.length === 0) {
                            const newAccount = {
                              email: value,
                            };

                            setCurrentAccount(newAccount);
                            setCanAdd(true);
                          }
                        })
                        .catch((error) => console.error(error));
                    }
                  }}
                  renderOption={(props, option) => (
                    <AutocompleteOption
                      {...props}
                      sx={{
                        "&:hover": {
                          backgroundColor: "var(--joy-palette-neutral-100)",
                        },
                      }}
                    >
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        flex={1}
                      >
                        <Stack>{option.email}</Stack>
                        <Stack>
                          <Add />
                        </Stack>
                      </Stack>
                    </AutocompleteOption>
                  )}
                  onChange={(
                    _event: React.SyntheticEvent,
                    value: TempAccountType | null
                  ) => {
                    if (
                      value &&
                      validateEmail(
                        value.email,
                        selectedAccounts.map((acc) => acc.email)
                      )
                    ) {
                      setCurrentAccount(value);
                      setCanAdd(true);
                    }
                  }}
                />
              </Grid>
              <Grid xs={2}>
                <Button
                  disabled={!canAdd}
                  onClick={() => {
                    if (currentAccount) {
                      handleAddEmail(currentAccount);
                    }
                    setCanAdd(false);
                  }}
                  variant="outlined"
                  type="button"
                  className="mt-4 lg:mt-2"
                  sx={{
                    marginLeft: "auto",
                    padding: 1.5,
                  }}
                >
                  {t("commons.addBtn")}
                </Button>
              </Grid>
            </Grid>

            {/* <Typography level="body-sm" mt={0.5}>
              {selectedAccounts.length}
              <span className="ml-1">recipient added</span>
            </Typography> */}

            <List
              sx={{
                marginTop: 4,
                overflow: "auto",
                maxHeight: "40vh",
                "&::-webkit-scrollbar": { display: "none" },
              }}
            >
              {selectedAccounts.length > 0 ? (
                selectedAccounts.map((account, index) => {
                  return (
                    <ListItem key={account.id + "___" + index}>
                      <Grid
                        container
                        spacing={2}
                        mt={1}
                        mb={1}
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Grid
                          xs={2}
                          lg={1}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            variant="plain"
                            onClick={() => {
                              handleRemoveEmail(account);
                              const newList = selectedAccounts.filter(
                                (acc) => acc.email !== account.email
                              );
                              setCanAdd(
                                validateEmail(
                                  currentAccount?.email ?? "",
                                  newList.map((acc) => acc.email)
                                )
                              );
                            }}
                          >
                            <CancelOutlined />
                          </IconButton>
                        </Grid>
                        <Grid
                          xs={10}
                          lg={11}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography>{account.email}</Typography>
                        </Grid>
                        <Grid
                          xsOffset={1}
                          xs={11}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {account.role && account.role.length > 1 && (
                            <Select
                              size="sm"
                              placeholder="Select Business"
                              slotProps={{
                                button: {
                                  sx: { whiteSpace: "nowrap" },
                                },
                              }}
                              sx={{
                                width: "100%",
                              }}
                              onChange={(
                                _event: React.SyntheticEvent | null,
                                value: string | null
                              ) => {
                                if (value) {
                                  handleSelectBusiness(account.email, value);
                                }
                              }}
                            >
                              {account.role.map((r) => (
                                <Option
                                  key={r.business.id}
                                  value={r.business.id}
                                >
                                  {r.business.companyName}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Grid>
                        <Grid xs={12}>
                          <Divider
                            sx={{
                              marginY: 2,
                              backgroundColor:
                                theme.colorSchemes.light.palette.others
                                  .disabled,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })
              ) : (
                <Typography level="body-md" textAlign={"center"}>
                  {t("catalog.inventory.sendCatalogModalPrompt2")}
                </Typography>
              )}
            </List>
          </>

          {/* buttons */}
          <Stack direction={"row"} justifyContent={"right"} mt={4}>
            <Button
              type="button"
              variant="plain-gray"
              className="ml-auto"
              onClick={handleClose}
            >
              {t("commons.cancelBtn")}
            </Button>
            <Button
              disabled={selectedAccounts.length === 0}
              type="button"
              onClick={onSubmit}
              sx={{
                marginLeft: "2rem",
                padding: 1.5,
              }}
            >
              {t("catalog.sendBtn")}
            </Button>
          </Stack>
        </div>

        {/* modals */}
        <SuccessModal
          title={t("commons.successModalTitle")}
          content={t("catalog.catalogSent")}
          open={showAlert}
        />
        <LoadingModal isLoading={isLoading} />
      </Box>
    </Modal>
  );
};

export default ShareModal;
