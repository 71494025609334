import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  Input,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { SuccessModal } from "../SuccessModal";
import LoadingModal from "../LoadingModal/LoadingModal";
import { IBusinessResponse } from "../../types/business";
import { InventoryService } from "../../services/inventory.service";
import { useTranslation } from "react-i18next";
import { FormControlLabel } from "@mui/material";
import { BrowserRouterConstants } from "../../utils/constants";
import { ItemType } from "../../types/item";

interface Props {
  show?: boolean;
  onClose: () => void;
  businessId: string;
  items: string[];
  payload: ItemType[];
}

export interface TempAccountType {
  id?: string;
  email: string;
  role?: {
    roleType: string;
    isOwnder: boolean | null;
    business: IBusinessResponse;
  }[];
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  width: "94%",
  maxWidth: 525,
  px: 4,
  py: 4,
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
};

const CreateCatalogModal = ({
  show = false,
  onClose,
  businessId,
  items,
  payload,
}: Props) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isHidePrice, setIsHidePrice] = useState(false);
  const [catalogName, setCatalogName] = useState("");
  const [catalogType, setCatalogType] = useState("PRIVATE");

  const handleClose = () => {
    setShowModal(false);
    setCatalogType("PRIVATE");
    setIsHidePrice(false);
    onClose();
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCatalogType((event.target as HTMLInputElement).value);
  };

  const onSubmit = () => {
    setIsLoading(true);

    if (items.length === 0) {
      setIsLoading(false);
      window.alert("no items selected");
    }

    InventoryService.createCatalog({
      catalog: {
        catalogType,
        catalogName,
        business: businessId,
        hidePrice: catalogType === "PRIVATE" ? false : isHidePrice,
        tags: {
          tags: [],
        },
      },
    })
      .then((res) => {
        if (res.id) {
          const inventoryItems = items.map((item) => {
            const inventory = payload.find((p) => p.id === item);
            return {
              catalog: res.id,
              inventoryItem: item,
              isDeleted: false,
              currency: inventory?.currency,
              price: inventory?.price,
            };
          });

          InventoryService.createCatalogItems({
            catalogId: res.id,
            inventoryItems: inventoryItems,
          }).then((res) => {
            if (res.length > 0) {
              setShowAlert(true);
            }
          });
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        // setIsLoading(false);
        setCatalogName("");
        setCatalogType("PRIVATE");
      });

    setTimeout(() => {
      setShowAlert(false);
      handleClose();
      window.location.href = BrowserRouterConstants.CataloguesList;
    }, 2000);
  };

  return (
    <Modal
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} justifyContent={"center"}>
        <Typography
          mt={4}
          textAlign={"center"}
          fontWeight={600}
          fontSize={24}
          id="modal-modal-title"
          sx={{
            color: "#265641",
          }}
        >
          {t("catalog.inventory.createCatalog.title")}
        </Typography>

        <div className="w-full flex flex-col px-2">
          <Typography fontSize={16} mt={4}>
            {t(`catalog.inventory.catalogNameLabel`)}
          </Typography>
          <Grid container mt={1} spacing={2}>
            <Grid xs={12}>
              <Input
                placeholder={t("catalog.inventory.createCatalog.placeholder")}
                type="text"
                fullWidth
                onChange={(e) => {
                  setCatalogName(e.target.value);
                }}
              />
            </Grid>

            <Grid xs={12}>
              <div className="w-full border-y border-gray-300 py-3 px-2">
                <FormControl>
                  <RadioGroup
                    defaultValue="PRIVATE"
                    name="catalog-type"
                    className="pl-4 gap-5"
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="PUBLIC"
                      control={<Radio className="mr-3" />}
                      label={t("catalog.inventory.createCatalog.publicLabel")}
                      className="text-sm"
                    />
                    <FormControlLabel
                      value="PRIVATE"
                      control={<Radio className="mr-3" />}
                      label={t("catalog.inventory.createCatalog.privateLabel")}
                      className="text-sm"
                    />
                  </RadioGroup>
                </FormControl>

                <div className="flex items-center px-1.5 my-3">
                  <span className="flex gap-3">
                    <Checkbox
                      size="sm"
                      defaultChecked={
                        catalogType === "PRIVATE" ? true : !isHidePrice
                      }
                      checked={catalogType === "PRIVATE" ? true : !isHidePrice}
                      disabled={catalogType === "PRIVATE"}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setIsHidePrice(!event.target.checked);
                      }}
                    />
                    <Typography fontSize={14}>
                      {t("catalog.inventory.editForm.showPrice")}
                    </Typography>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>

          {/* buttons */}
          <Stack direction={"row"} justifyContent={"right"} mt={4}>
            <Button
              type="button"
              variant="plain-gray"
              className="ml-auto"
              onClick={handleClose}
            >
              {t("commons.cancelBtn")}
            </Button>
            <Button
              disabled={catalogName === ""}
              type="button"
              onClick={onSubmit}
              sx={{
                marginLeft: "2rem",
                padding: 1.5,
              }}
            >
              {t("catalog.inventory.createCatalogBtn")}
            </Button>
          </Stack>
        </div>

        {/* modals */}
        <SuccessModal
          title={t("commons.successModalTitle")}
          content={t("catalog.inventory.createCatalog.catalogCreated")}
          open={showAlert}
        />
        <LoadingModal isLoading={isLoading} />
      </Box>
    </Modal>
  );
};

export default CreateCatalogModal;
