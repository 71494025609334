import {
  KeyboardArrowDown,
  LockOpenOutlined,
  LockOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Box,
  Button,
  Chip,
  Input,
  List,
  ListItem,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { AxiosError } from "axios";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Link, useParams, useSearchParams } from "react-router-dom";
import LogoImg from "../../assets/linkz-logo-small.png";
import CartQuantityManager from "../../components/Catalogue/Item/CartQuantityManager";
import TagsCollection from "../../components/Catalogue/Item/TagsCollection";
import ListUnavailable from "../../components/Catalogue/ListUnavailable";
import { If, IfElse } from "../../components/Condition";
import { theme } from "../../components/Theme";
import { InventoryService } from "../../services/inventory.service";
import { ItemService } from "../../services/item.service";
import { SupplierService } from "../../services/supplier.service";
import { getActiveBusiness, useAuthStore } from "../../store/session";
import { getCartItems } from "../../utils/cart";
import { formatDate } from "../../utils/formatDate";
import { formatPrice } from "../../utils/formatPrice";
import SeeDetails from "../../components/Inventory/Items/SeeDetails";
import useContainer from "./useContainer";
import { TrackerService } from "../../services/tracker.service";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events";

const ListSupplierItems = () => {
  const { t } = useTranslation();

  const { catalogId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("order");
  const query = searchParams.get("q");
  // TODO: replace with /public
  const isGuestMode = window.location.pathname.startsWith("/catalog");
  const { session } = useAuthStore.getState();

  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>();
  const [unsavedOrderData, setUnsavedOrderData] = useState<string | null>(
    localStorage.getItem(`unsavedOrderData_${orderId}`)
  );
  const [searchTerm, setSearchTerm] = useState<string>();
  const [viewMode, setViewMode] = useState<string>("image");
  const [cartUpdated, setCartUpdated] = useState(false);
  const [error, setError] = useState<AxiosError | undefined>();
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );

  // const loaderRef = useRef(null);

  const {
    fetchAllItems,
    items,
    setItems,
    catalog,
    setCatalog,
    pagination,
    setPagination,
    setIsLoading,
    unauthorized,
    setUnauthorized,
    hasMore,
    setHasMore,
    cartItems,
    setCartItems,
  } = useContainer();

  const initiateInventoryItems = useCallback(
    (catalogId: string, query?: string) => {
      InventoryService.getCatalogItems({
        catalogId,
        paging: true,
        limit: 10,
        offset: 0,
        searchTerm: query,
      })
        .then((res) => {
          setItems(res.data);

          if (res.pagination) {
            setPagination(res.pagination);
          }

          if (res.data.length === 0) {
            setHasMore(false);
          }

          // getting all inventoryType for categories
          const businessId = res.data[0].inventoryItem.business;
          if (businessId) {
            ItemService.getInventoryItemField({
              fields: ["inventoryType"],
              businessId,
            }).then((res) => {
              const cats = res.map(
                (type: { inventoryType: string }) => type.inventoryType
              );
              setCategories(cats);
            });
          }
        })
        .catch((err) => console.error(err));
    },
    []
  );

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (catalogId) {
      setIsLoading(true);
      if (session?.accessToken && isGuestMode) {
        window.location.href = `/client/suppliers/${catalogId}/items`;
        return;
      }

      InventoryService.getOneCatalog({
        catalogId: catalogId,
      })
        .then((res) => {
          setCatalog(res);
          // setIsCatalogOwner(res.business.id === session?.activeBusiness.id);
          const tempCartItems = getCartItems(
            catalogId,
            session?.account.id || "wildcart",
            session?.accessToken ? "local" : "session"
          );

          if (tempCartItems) {
            setCartItems(tempCartItems);
            setCartUpdated(false);
          }

          TrackerService.track(
            TRACKER_CONSTANTS.CATALOG_SUPPLIER.events.AccessCatalogDetails,
            {
              "Catalog ID": catalogId,
              "Catalog Name": res?.catalogName,
              "Catalog Owner Company Name": res?.business.companyName,
              "Product Count": res?.itemsCount,
            }
          );

          if (res.catalogType !== "PUBLIC") {
            SupplierService.verifyAccess({
              catalog: catalogId,
              business: getActiveBusiness()?.id ?? null,
              accountId: session?.account.id ?? null,
            })
              .then((res) => {
                if (!res) {
                  setUnauthorized(true);

                  if (isGuestMode) {
                    window.location.href = `/client/suppliers/${catalogId}/items`;
                  }
                }

                initiateInventoryItems(catalogId, query ? query : undefined);
              })
              .catch((error) => {
                setUnauthorized(true);
                console.error(error);
              });
          } else {
            initiateInventoryItems(catalogId, query ? query : undefined);
          }
        })
        .catch((err) => {
          console.error(err);
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [catalogId, session]);

  // useEffect(() => {
  //   if (query && !searchTerm) return;
  //   const observer = new IntersectionObserver((entries) => {
  //     const target = entries[0];
  //     if (target.isIntersecting && hasMore && catalogId && items.length > 0) {
  //       fetchAllItems({
  //         catalogId: catalogId,
  //         paging: true,
  //         limit: 10,
  //         offset: pagination.limit * pagination.current,
  //         searchTerm: searchTerm ?? null,
  //         inventoryType: selectedCategory !== "all" ? selectedCategory : null,
  //         isReset: false,
  //       });
  //     }
  //   });

  //   if (loaderRef.current) {
  //     observer.observe(loaderRef.current);
  //   }

  //   return () => {
  //     if (loaderRef.current) {
  //       observer.unobserve(loaderRef.current);
  //     }
  //   };
  // }, [fetchAllItems]);

  useEffect(() => {
    if (query && !searchTerm) return;
    if (catalogId && (searchTerm || searchTerm === "" || selectedCategory)) {
      fetchAllItems({
        catalogId: catalogId,
        paging: true,
        limit: 10,
        offset: 0,
        searchTerm: searchTerm,
        inventoryType: selectedCategory !== "all" ? selectedCategory : null,
        isReset: true,
      });
    }
  }, [catalogId, searchTerm, selectedCategory]);

  useEffect(() => {
    if (cartUpdated && catalogId) {
      const tempCartItems = getCartItems(
        catalogId,
        session?.account.id || "wildcart",
        session?.accessToken ? "local" : "session"
      );

      if (tempCartItems) {
        setCartItems(tempCartItems);
        setCartUpdated(false);
      }
    }
  }, [cartUpdated]);

  useEffect(() => {
    if (query && !searchTerm) {
      setSearchTerm(query);
    }
  }, [query]);

  const CatalogTypeButton = () => {
    return (
      <button
        className={`my-4 lg:my-0 py-3 px-4 rounded-md ${
          catalog?.catalogType === "PUBLIC"
            ? "bg-primary-200"
            : "bg-gray-700 text-white"
        } capitalize font-semibold items-center flex cursor-default w-[106px]`}
      >
        {catalog?.catalogType === "PUBLIC" ? (
          <LockOpenOutlined
            style={{
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
        ) : (
          <LockOutlined
            style={{
              color: "white",
              width: 18,
              height: 18,
              marginRight: "0.5rem",
            }}
          />
        )}

        {catalog?.catalogType.toLowerCase()}
      </button>
    );
  };

  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      if (value === "") {
        searchParams.delete("q");
      } else {
        searchParams.set("q", value);
      }
      setSearchParams(searchParams);
      setSearchTerm(value);
    }, 300),
    []
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchTerm(event.target.value);
  };
  return (
    <div className={`lg:px-6 ${isGuestMode ? "px-4" : "w-[90vw] xs:w-full "}`}>
      <IfElse
        condition={!error}
        ifBlock={
          <IfElse
            condition={!unauthorized}
            ifBlock={
              <>
                <If condition={isGuestMode}>
                  <div className="items-center w-full py-4 lg:flex">
                    <img
                      src={LogoImg}
                      alt="linkz-logo"
                      width={109.05}
                      height={52}
                      className={"mr-auto"}
                    />

                    <span className="flex flex-col gap-2 ml-auto lg:flex-row">
                      {t("catalog.loginToSeePrice")}
                      <Link to={"/signin"} className="ml-3">
                        <Button className="min-w-[3rem]">
                          {t("landing.loginBtn")}
                        </Button>
                      </Link>
                    </span>
                  </div>
                </If>

                <div className="flex flex-col-reverse my-4 lg:flex-row">
                  <CatalogTypeButton />
                  <div
                    className={`py-4 lg:py-0 lg:ml-4 border-t lg:border-t-0 border-b border-[${theme.colorSchemes.light.palette.others.disabled}] lg:border-none`}
                  >
                    <Typography fontSize={16} fontWeight={600}>
                      {catalog?.business?.companyName}
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={600}
                      sx={{
                        color: theme.colorSchemes.light.palette.neutral[600],
                      }}
                    >
                      {catalog?.catalogName}
                    </Typography>
                    <Typography
                      fontSize={14}
                      sx={{
                        color: theme.colorSchemes.dark.palette.text.secondary,
                      }}
                    >
                      <span className="text-xs lg:text-sm lg:hidden">
                        {t("commons.lastUpdated")}:{" "}
                        <span className="ml-2">
                          {formatDate(new Date(catalog?.updatedAt ?? ""))}
                        </span>
                      </span>
                    </Typography>
                  </div>
                  <If condition={!catalog?.hidePrice}>
                    <div className="hidden mt-4 lg:block lg:mt-0 lg:ml-auto">
                      <Stack direction={"row"} gap={1} ml={"auto"}>
                        <Link
                          to={
                            isGuestMode
                              ? catalog?.catalogType === "PRIVATE"
                                ? `/client/suppliers/${catalogId}/items`
                                : `/public/cart/${catalogId}/items`
                              : orderId
                              ? `/client/orders/${orderId}`
                              : session?.accessToken
                              ? `/client/cart/${catalogId}/items`
                              : `/public/cart/${catalogId}/items`
                          }
                        >
                          <Button
                            variant="outlined"
                            startDecorator={<ShoppingCartOutlined />}
                          >
                            {t(`catalog.${orderId ? "order" : "cart"}`)}{" "}
                            {!orderId && cartItems
                              ? cartItems.items.length > 0 &&
                                `(${cartItems?.items.length})`
                              : ""}
                          </Button>
                        </Link>
                      </Stack>
                    </div>
                  </If>
                </div>
                <div className="flex flex-col my-4">
                  <Input
                    defaultValue={query ?? ""}
                    variant="linkz-input-search"
                    size="sm"
                    fullWidth={isGuestMode}
                    name="searchFormData.search"
                    placeholder={t("catalog.searchItemPlaceholder")}
                    startDecorator={<SearchOutlinedIcon />}
                    sx={{ flexGrow: 1, width: { xs: "100%", lg: "41%" } }}
                    onChange={handleChange}
                  />

                  <If condition={!catalog?.hidePrice}>
                    <Link
                      className="mt-6 lg:hidden"
                      to={
                        isGuestMode
                          ? catalog?.catalogType === "PRIVATE"
                            ? `/client/suppliers/${catalogId}/items`
                            : `/public/cart/${catalogId}/items`
                          : orderId
                          ? `/client/orders/${orderId}`
                          : session?.accessToken
                          ? `/client/cart/${catalogId}/items`
                          : `/public/cart/${catalogId}/items`
                      }
                    >
                      <Button
                        variant="outlined"
                        startDecorator={<ShoppingCartOutlined />}
                      >
                        {t(`catalog.${orderId ? "order" : "cart"}`)}{" "}
                        {!orderId && cartItems
                          ? cartItems.items.length > 0 &&
                            `(${cartItems?.items.length})`
                          : ""}
                      </Button>
                    </Link>
                  </If>

                  <Stack direction={"row"} gap={2}>
                    {/* category filter */}
                    <Select
                      defaultValue="all"
                      variant="plain"
                      size="sm"
                      indicator={<KeyboardArrowDown />}
                      className="w-[50%] lg:min-w-[10%] lg:max-w-[17%] uppercase"
                      sx={{
                        marginY: 4,
                      }}
                      onChange={(
                        _event: React.SyntheticEvent | null,
                        value: string | null
                      ) => {
                        setSelectedCategory(value);
                      }}
                    >
                      <Option
                        // key={cat}
                        color="primary"
                        value="all"
                        label={t("catalog.supplier.items.allCategoryLabel")}
                        sx={{
                          color: theme.colorSchemes.dark.palette.common.black,
                        }}
                      >
                        {t("catalog.supplier.items.allCategoryLabel")}
                      </Option>
                      {categories.map((cat) => {
                        return (
                          <Option
                            color="primary"
                            value={cat}
                            label={cat.toUpperCase()}
                            sx={{
                              color:
                                theme.colorSchemes.dark.palette.common.black,
                            }}
                          >
                            {cat}
                          </Option>
                        );
                      })}
                    </Select>

                    {/* view mode */}
                    <Select
                      defaultValue="image"
                      variant="plain"
                      size="sm"
                      indicator={<KeyboardArrowDown />}
                      className="w-[50%] lg:min-w-[10%] lg:max-w-[17%] uppercase"
                      sx={{
                        marginY: 4,
                      }}
                      onChange={(
                        _event: React.SyntheticEvent | null,
                        value: string | null
                      ) => {
                        setViewMode(value || "image");
                      }}
                    >
                      <Option
                        color="primary"
                        value={"image"}
                        label={t("catalog.supplier.items.viewMode.image")}
                        sx={{
                          color: theme.colorSchemes.dark.palette.common.black,
                        }}
                      >
                        {t("catalog.supplier.items.viewMode.image")}
                      </Option>
                      <Option
                        color="primary"
                        value={"list"}
                        label={t("catalog.supplier.items.viewMode.list")}
                        sx={{
                          color: theme.colorSchemes.dark.palette.common.black,
                        }}
                      >
                        {t("catalog.supplier.items.viewMode.list")}
                      </Option>
                    </Select>
                  </Stack>
                </div>

                <List sx={{ marginTop: 2 }}>
                  <InfiniteScroll
                    pageStart={0}
                    initialLoad
                    loadMore={(page: number) => {
                      // console.log("LOADMORE TRIGGERED", pagination.limit, page);
                      fetchAllItems({
                        catalogId: catalogId ?? "",
                        paging: true,
                        limit: 10,
                        offset: pagination.limit * (page - 1),
                        searchTerm: searchTerm ?? null,
                        inventoryType:
                          selectedCategory !== "all" ? selectedCategory : null,
                        isReset: false,
                      });
                    }}
                    hasMore={hasMore}
                    loader={
                      <div className="loader" key={0}>
                        Loading ...
                      </div>
                    }
                  >
                    <div
                      className={`${
                        viewMode === "image"
                          ? "grid grid-cols-2 align-stretch"
                          : "flex flex-col"
                      } lg:flex lg:flex-col`}
                    >
                      {items.map((i) => {
                        const currency = i.currency ?? i.inventoryItem.currency;
                        const price = i.price ?? i.inventoryItem.price;
                        // had to do switch case here
                        switch (viewMode) {
                          case "image":
                            return (
                              <ListItem
                                key={i.id}
                                sx={{
                                  display: "block",
                                  paddingX: 0.5,
                                  alignItems: "stretch",
                                }}
                              >
                                <div
                                  className={`flex flex-col lg:flex-row align-middle lg:gap-[30px] w-full h-full rounded-lg lg:rounded-none border lg:border-none border-[${theme.colorSchemes.light.palette.others.disabled}]`}
                                >
                                  <img
                                    src={`${
                                      i.inventoryItem.thumbnail ?? "/union.png"
                                    }`}
                                    alt="item_image"
                                    // width={100}
                                    // height={100}
                                    className="lg:border border-gray-300 rounded-t-lg lg:rounded-lg w-full sm:w-[176.5px] h-[176.5px] lg:w-[140px] lg:h-[140px] object-contain flex-shrink-0"
                                  />

                                  <Stack
                                    gap={"12px"}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    sx={{
                                      width: { xs: "100%", lg: "40%" },
                                      padding: { xs: 2, lg: 0 },
                                      height: "100%",
                                    }}
                                  >
                                    <Typography
                                      fontSize={{ xs: 14, lg: 20 }}
                                      sx={{
                                        color: "black",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {i.inventoryItem.productName}
                                    </Typography>
                                    <Typography
                                      fontSize={{ xs: 14, lg: 16 }}
                                      fontWeight={500}
                                      sx={{
                                        color: "black",
                                      }}
                                    >
                                      SKU: {i.inventoryItem.sku}
                                    </Typography>

                                    {i.inventoryItem.tags && (
                                      <TagsCollection
                                        tags={i.inventoryItem.tags}
                                      />
                                    )}

                                    {isMobile && (
                                      <Typography
                                        mt={1}
                                        display={{ xs: "none", lg: "block" }}
                                        sx={{
                                          fontSize: 14,
                                          width: 100,
                                          textAlign: "left",
                                          wordWrap: "break-word",
                                          justifyContent: "center",
                                          fontWeight: 600,
                                          wordBreak: "break-all",
                                        }}
                                      >
                                        {`${currency} ${
                                          isGuestMode
                                            ? catalog?.catalogType === "PRIVATE"
                                              ? "-"
                                              : catalog?.hidePrice
                                              ? "-"
                                              : formatPrice(price, currency)
                                            : catalog?.hidePrice
                                            ? "-"
                                            : formatPrice(price, currency)
                                        }`}
                                      </Typography>
                                    )}

                                    {catalog && (
                                      <SeeDetails
                                        item={i}
                                        catalog={catalog}
                                        setCartUpdated={setCartUpdated}
                                        unsavedOrderData={unsavedOrderData}
                                        orderDataCallback={() => {
                                          setUnsavedOrderData(
                                            localStorage.getItem(
                                              `unsavedOrderData_${orderId}`
                                            )
                                          );
                                        }}
                                      />
                                    )}
                                  </Stack>

                                  {!isMobile &&
                                    catalog &&
                                    !catalog.hidePrice && (
                                      <CartQuantityManager
                                        item={i}
                                        hidePrice={catalog?.hidePrice}
                                        withAddToCart
                                        catalog={catalog}
                                        setCartUpdated={setCartUpdated}
                                        unsavedOrderData={unsavedOrderData}
                                        orderDataCallback={() => {
                                          setUnsavedOrderData(
                                            localStorage.getItem(
                                              `unsavedOrderData_${orderId}`
                                            )
                                          );
                                        }}
                                      />
                                    )}
                                </div>
                                <hr
                                  className={`hidden lg:block mt-4 w-full border-b border-dashed border-[${theme.colorSchemes.light.palette.others.disabled}]`}
                                />
                              </ListItem>
                            );

                          default:
                            return (
                              <ListItem key={i.id} sx={{ display: "block" }}>
                                <div
                                  className={`pt-4 lg:flex align-middle w-full`}
                                >
                                  <Stack gap={1} sx={{ width: "60%" }}>
                                    <Typography
                                      fontSize={16}
                                      sx={{
                                        color:
                                          theme.colorSchemes.dark.palette.text
                                            .secondary,
                                      }}
                                    >
                                      SKU:{" "}
                                      <span className="text-black">
                                        {i.inventoryItem.sku}
                                      </span>
                                    </Typography>
                                    <Typography
                                      fontSize={16}
                                      sx={{
                                        color:
                                          theme.colorSchemes.dark.palette.text
                                            .secondary,
                                      }}
                                    >
                                      {t("catalog.supplier.items.productName")}:{" "}
                                      <span className="text-black">
                                        {i.inventoryItem.productName}
                                      </span>
                                    </Typography>

                                    <div className="flex gap-1 mx-2 lg:mx-0">
                                      {i.inventoryItem.tags?.tags.map((t) => (
                                        <Chip
                                          variant="outlined"
                                          sx={{
                                            color:
                                              theme.colorSchemes.dark.palette
                                                .neutral[400],
                                          }}
                                        >
                                          {t}
                                        </Chip>
                                      ))}
                                    </div>

                                    {catalog && (
                                      <SeeDetails
                                        item={i}
                                        catalog={catalog}
                                        setCartUpdated={setCartUpdated}
                                        mobileJustifyContent="justify-start"
                                        unsavedOrderData={unsavedOrderData}
                                        orderDataCallback={() => {
                                          setUnsavedOrderData(
                                            localStorage.getItem(
                                              `unsavedOrderData_${orderId}`
                                            )
                                          );
                                        }}
                                      />
                                    )}
                                  </Stack>

                                  {!isMobile &&
                                    catalog &&
                                    !catalog.hidePrice && (
                                      <CartQuantityManager
                                        item={i}
                                        hidePrice={catalog?.hidePrice}
                                        withAddToCart
                                        catalog={catalog}
                                        setCartUpdated={setCartUpdated}
                                        unsavedOrderData={unsavedOrderData}
                                        orderDataCallback={() => {
                                          setUnsavedOrderData(
                                            localStorage.getItem(
                                              `unsavedOrderData_${orderId}`
                                            )
                                          );
                                        }}
                                      />
                                    )}
                                </div>
                                <hr
                                  className={`mt-4 w-full border-b border-solid lg:border-dashed border-[${theme.colorSchemes.light.palette.others.disabled}]`}
                                />
                              </ListItem>
                            );
                        }
                      })}
                    </div>
                  </InfiniteScroll>
                </List>
              </>
            }
            elseBlock={
              <ListUnavailable message={t("catalog.noAccessCatalog")} />
            }
          />
        }
        elseBlock={
          <Box display={"flex"} width={"100%"}>
            <If condition={error?.response?.status === 404}>
              <p className="mx-auto my-8">Catalog not found</p>
            </If>
            <If condition={error?.response?.status === 500}>
              <p className="mx-auto my-8">Something went wrong</p>
            </If>
          </Box>
        }
      />
      {/* <div ref={loaderRef} className="flex justify-center w-full">
        {isLoading && "Loading..."}
      </div> */}
    </div>
  );
};

export default ListSupplierItems;
