export class ApiConstants {
  static readonly logoutURL = "/auth/logout";
  static readonly signupURL = "/auth/signup";
  static readonly signupInvitationWithRoleURL =
    "/auth/signup-invitation-with-role";
  static readonly signupInvitationWithoutRoleURL =
    "/auth/signup-invitation-without-role";
  static readonly signupInvitationBusinessConnectURL =
    "/auth/signup-invitation-business-connect";
  static readonly signinURL = "/auth/signin";

  static readonly signupSSOURL = "/auth/signup/sso";
  static readonly chooseBusinessURL = "/auth/choose-business";
  static readonly signupSSOInvitationWithRoleURL = "/auth/signup/sso-with-role";
  static readonly signupSSOInvitationWithoutRoleURL =
    "/auth/signup/sso-without-role";
  static readonly signupSSOBusinessConnectURL =
    "/auth/signup/sso-business-connect";

  static readonly signinSSOURL = "/auth/signin/sso";

  static readonly otpVerificationURL = "/auth/activation/confirm";
  static readonly resendOTPURL = "/auth/activation/resend";
  static readonly ResendPasswordResetCode = "/auth/forgot-password/resend";
  static readonly forgotPassword = "/auth/forgot-password";
  static readonly resetPassword = "/auth/reset-password";
  static readonly changePassword = "/auth/change-password";
  static readonly verifyPasswordResetCode = "/auth/password-reset/validate";
  static readonly getSelectableBusinesses = "/auth/available-businesses";

  static readonly orderURL = "/orders";

  static readonly DashboardAnalyticURL = "/orders/analytic";

  static readonly pdfURL = "/pdf";

  static readonly profileURL = "/profile";
  static readonly profileFullNameURL = "/profile/fullname";
  static readonly profilePhoneNumberURL = "/profile/phone";
  static readonly profilePhoneNumberVerificationURL =
    "/profile/phone/verification";

  static readonly manageEmployeesURL = "/employees";

  static readonly businessURL = "/businesses";
  static readonly businessCreditURL = "/businesses/current/credits";

  static readonly businessConnectionURL = "/business-connections";

  static readonly accountURL = "/account";
  static readonly invitationTokenCheck = "/account/invite/check";
  static readonly switchBusinessProfileURL = "/account/switch-business";

  static readonly catalogURL = "/catalogs";
  static readonly itemsURL = "/items";

  static readonly availableCreditsPackageURL = "/credits/packages";
  static readonly discountedCreditsPackageURL = "/credits/packages/discounted";
  static readonly creditsTransactionsURL = "/credits/transactions";

  static readonly paymentUrl = "/payments";

  //#region tax routes
  static readonly taxURL = "/admin-tax";
  static readonly businessTaxURL = "/businesses-tax";
  //#endregion

  //#region kyc

  static readonly kycUrl = `${this.accountURL}/kyc`;
  //#endregion

  static readonly BusinessContacts = "business-contact";

  static readonly KycPaymentGateway = "kyc-payment-gateway";
}

export class BrowserRouterConstants {
  static readonly Signin = "/signin";
  static readonly Signup = "/signup";
  static readonly SignupSSO = "/signup/sso";
  static readonly Verify = "/verify";
  static readonly Verified = "/verified";
  static readonly PhoneVerified = "/phone-verified";
  static readonly Recover = "/forget-password";
  static readonly PasswordResetVerify = "/forget-password/verify";
  static readonly SetPassword = "/set-password";

  /** Public Main Route */
  static readonly PublicPrefix = "/public/";

  /** Panel Main Route */
  static readonly ClientPrefix = "/client/";

  /** Panel Child Routes Under "/client" */
  static readonly ItemDetails = "items/:itemId";

  static readonly Credit = "credit";
  static readonly CreditTransactionTab = "credit?activeTab=history";

  static readonly Orders = "orders";
  static readonly OrdersEdit = "orders/:orderId";
  // static readonly OrdersEdit = "orders/edit";

  static readonly Finance = "finance";

  static readonly Manage = "manage";

  static readonly Profile = "profile";
  // Child Routes Under "/profile"
  static readonly ProfileFullName = "profile/fullname";
  static readonly ProfileMobileNumber = "profile/mobile";
  static readonly ProfileMobileNumberVerification =
    "profile/mobile/verification";
  static readonly ProfileChangePassword = "profile/password";
  static readonly ProfileBusiness = "profile/business";
  static readonly ProfileBusinessUpdate = "profile/business/:businessId";
  static readonly ProfileKYC = "profile/kyc";
  // End of Panel Routes profile

  // Employess
  static readonly Employees = "employees";
  static readonly AddEmployee = "employees/add";
  static readonly UpdateEmployee = "employees/update/:employeeId";
  // End of Employee
  // Business Contact
  static readonly BusinessContact = "business-contact";
  static readonly AddBusinessContact = "business-contact/add";
  static readonly UpdateBusinessContact = "business-contact/update/:contactId";
  // End of Business Contact

  static readonly Notis = "notis";
  static readonly Catalogues = "catalogues";
  static readonly CataloguesItems = "catalogues/:catalogId/items";
  static readonly CataloguesList = "catalogues/list";
  static readonly CatalogInventoryItems =
    "catalogues/inventory/:catalogId/items";
  static readonly ManageCatalogInventoryItem =
    "catalogues/inventory/:catalogId/manage";
  static readonly Inventory = "inventory";
  static readonly InventoryItems = "inventory/:catalogId/items";
  static readonly InventoryEdit = "inventory/:inventoryItemId/edit";
  static readonly Supplier = "suppliers";
  static readonly SupplierItems = "suppliers/:catalogId/items";
  static readonly CartItems = "cart/:catalogId/items";
}

export enum AuthStrategy {
  GOAUTH = "GOOGLE_OAUTH",
  Normal = "normal",
}
export const WhiteListedCountries = ["MY", "SG", "TH", "ID"];
export const PhNoWhiteListedCountries = ["my", "sg", "th", "id"];
export const PhNoFormat = {
  my: "+.. ..-....-....",
  sg: "+.. ....-....",
  th: "+.. ... ... ...",
  id: "+.. ... ... ... ..",
};

export type CountryCode = keyof typeof PhNoFormat;

export const termsTitle = "auth.termsAndCondition.title";
export const termsContent = "auth.termsAndCondition.content";

export const privacyPolicyTitle = "auth.privacyPolicy.title";
export const privacyPolicyContent = "auth.privacyPolicy.content";

export const DefaultCurrencies = [
  { label: "USD", id: "USD" },
  { label: "SGD", id: "SGD" },
  { label: "EUR", id: "EUR" },
  { label: "HKD", id: "HKD" },
  { label: "JPY", id: "JPY" },
  { label: "IDR", id: "IDR" },
  { label: "MYR", id: "MYR" },
  { label: "THB", id: "THB" },
  { label: "VND", id: "VND" },
  { label: "MMK", id: "MMK" },
  { label: "PHP", id: "PHP" },
];

export const countryMap: { [key: string]: any } = {
  SG: { code: "65", dial: "", label: "Singapore" },
  MY: { code: "60", dial: "", label: "Malaysia" },
  TH: { code: "66", dial: "", label: "Thailand" },
  ID: { code: "62", dial: "", label: "Indonesia" },
};

export const getCountryCodeFromCountryName: { [key: string]: any } = {
  "65": "SG",
  "60": "MY",
  "66": "TH",
  "62": "ID",
};

export const DISCOUNT_TYPE = {
  percentage: { value: "PERCENTAGE", label: "%" },
  amount: { value: "AMOUNT", label: "123" },
};

export const KYC_INDUSTRIES = [
  "Agriculture",
  "Automotive",
  "Banking & Financial Services",
  "Construction",
  "Consumer goods",
  "Education",
  "Energy & utilities",
  "F&B Industry",
  "Healthcare & pharmaceuticals",
  "Hospitality & tourism",
  "Information technology & software",
  "Insurance",
  "Legal services",
  "Logistics & transportation",
  "Manufacturing",
  "Media & entertainment",
  "Mining & metals",
  "Real estate",
  "Retail",
  "Telecommunications",
  "Textiles & apparel",
  "Other",
];

export const KYC_ROLE = [
  "Founder, Owner, C-level, Executive",
  "Sales department",
  "Finance department",
  "Purchasing department",
  "Warehouse department",
];

export const KYC_SIZE = ["0 - 5", "6- 25", "26 - 100", "More than 100"];
export const KYC_WHERE_DID_YOU_LEARN = [
  "Referral / word of mouth",
  "Social media (e.g., Linkedin, Facebook, Twitter)",
  "Online search  / Google",
  "Industry event / conference",
  "Advertisement (online / print)",
  "Email campaign / newsletter",
  "Website / blog",
  "Direct contact from sales representative",
  "Press release / news article",
  "Other",
];

export const AVAILABLE_UOMS = [
  "Piece",
  "Liter",
  "Milliliter",
  "Meter",
  "Centimeter",
  "Inch",
  "Pack",
  "Dozen",
  "Buah",
  "Kilogram",
  "Gram",
  "Bungkus",
  "Lusin",
];
