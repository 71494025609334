import { ViewSidebarOutlined } from "@mui/icons-material";
import { Box, Breadcrumbs, Link, Stack, Typography } from "@mui/joy";
import "flag-icons/css/flag-icons.css";
import { useTranslation } from "react-i18next";
import HamburgerIcon from "./Icons/Hamburger";
import { openSidebar } from "./utils";
import LanguageSelector from "./LanguageSelector";
import { BusinessSessionHelper } from "../helpers/business-session.helper";
import KycSnackBar from "./kyc/KycSnackBar";

interface Props {
  paths: {
    name: string;
    path: string;
  }[];
}

const BreadcrumbHeader = ({ paths }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Stack
        direction={"row"}
        // justifyContent={"center"}
        alignItems={"center"}
        sx={{
          width: "100%",
        }}
      >
        <span className="min-[900px]:block hidden">
          <ViewSidebarOutlined />
        </span>
        <Box className="max-[899px]:block hidden" onClick={() => openSidebar()}>
          <HamburgerIcon />
        </Box>
        <div className="min-[900px]:block hidden">
          <Breadcrumbs
            aria-label="breadcrumbs"
            sx={{
              marginLeft: "1rem",
            }}
          >
            {paths.map((p, index) => {
              if (index === paths.length - 1) {
                return (
                  <Typography
                    key={p.name}
                    sx={{
                      marginLeft: "1rem",
                    }}
                  >
                    {t(`breadcrumb.${p.name}`)}
                  </Typography>
                );
              }

              return (
                <Link
                  key={p.name}
                  color="neutral"
                  href={p.path}
                  sx={{
                    marginX: "1rem",
                  }}
                >
                  {t(`breadcrumb.${p.name}`)}
                </Link>
              );
            })}
          </Breadcrumbs>
        </div>
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          <LanguageSelector />
          {/* <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              right: "15px",
              top: "5px",
              borderRadius: "10px",
              position: "absolute",
              backgroundColor: "#ff8360",
            }}
          />
          <NotificationsOutlined
            style={{
              fontSize: "2rem",
              margin: "0 0.6rem",
            }}
          />
        </div>
        <AccountCircleOutlined
          style={{
            fontSize: "2.3rem",
          }}
        /> */}
        </div>
      </Stack>
      {!(window.location.href.endsWith("/profile") || window.location.href.endsWith("/profile/kyc")) && !BusinessSessionHelper.IsValidatedKYCPaymentGateway() && <KycSnackBar />}
    </Stack>
  );
};

export default BreadcrumbHeader;
