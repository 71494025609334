import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CatalogInventoryItemType } from "../../../types/item";
import UOMSelector from "./UOMSelector";
import { Button, Input, Stack, Typography } from "@mui/joy";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import { theme } from "../../Theme";
import { useTranslation } from "react-i18next";
import AddToCartBtn from "../../Inventory/Items/AddToCartBtn";
import {
  calculatePrice,
  getConversionRate,
  getMinimumQuantity,
  updateCartQty,
  validateOnKeyDown,
} from "../../../utils/cart";
import { formatPrice } from "../../../utils/formatPrice";
import { useAuthStore } from "../../../store/session";
import { CartDataType } from "../../../types/cart";
import { CatalogType } from "../../../types/catalog";

interface Props {
  item: CatalogInventoryItemType;
  hidePrice?: boolean;
  withAddToCart?: boolean;
  isDisabled?: boolean;
  catalog: CatalogType;
  setCartUpdated?: Dispatch<SetStateAction<boolean>>;
  updateUOMCallback?: (uom: "uom1" | "uom2" | "uom3") => void;
  updateQtyCallback?: (cartItems: CartDataType) => void;
  quantity?: number;
  updateCart?: boolean;
  defaultUOM?: "uom1" | "uom2" | "uom3";
  unsavedOrderData?: string | null;
  orderDataCallback?: () => void;
}

const CartQuantityManager = ({
  item,
  hidePrice = false,
  withAddToCart = false,
  isDisabled = false,
  catalog,
  setCartUpdated,
  updateUOMCallback,
  updateQtyCallback,
  quantity = 0,
  updateCart = false,
  defaultUOM = "uom1",
  unsavedOrderData,
  orderDataCallback,
}: Props) => {
  const currency = item.currency ?? item.inventoryItem.currency;
  const { t } = useTranslation();
  // TODO: replace with /public
  const isGuestMode = window.location.pathname.startsWith("/catalog");

  const [count, setCount] = useState(quantity);
  const [inputValue, setInputValue] = useState(quantity.toString());
  const [selectedUOM, setSelectedUOM] = useState<"uom1" | "uom2" | "uom3">(
    defaultUOM
  );
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);
  useEffect(() => {
    setSelectedUOM(defaultUOM);
  }, [defaultUOM]);

  const { session } = useAuthStore.getState();

  const realCount = () => {
    return count * getConversionRate(selectedUOM, item.inventoryItem);
  };

  const handleIncrease = () => {
    setInputValue((prev) => String(Number(prev) + 1));
    setCount((prev) => prev + 1);

    if (updateCart) {
      updateCartQty({
        catalogId: catalog.id,
        accountId: session?.account.id || "wildcart",
        override: false,
        quantityChange: 1,
        inventoryItem: item,
        direct: session?.account.id ? false : true,
        activeUOM: selectedUOM,
        storageType: session?.account.id ? "local" : "session",
        callback: updateQtyCallback && updateQtyCallback,
      });
    }
  };

  const handleDecrease = () => {
    if (count > 0) {
      setInputValue((prev) => String(Number(prev) - 1));
      setCount((prev) => prev - 1);

      if (updateCart) {
        updateCartQty({
          catalogId: catalog.id,
          accountId: session?.account.id || "wildcart",
          override: false,
          quantityChange: -1,
          inventoryItem: item,
          direct: session?.account.id ? false : true,
          activeUOM: selectedUOM,
          storageType: session?.account.id ? "local" : "session",
          callback: updateQtyCallback && updateQtyCallback,
        });
      }
    }
  };

  return (
    <div className="flex w-full justify-start items-start gap-[30px]">
      <UOMSelector
        item={item}
        hidePrice={hidePrice}
        catalogType={catalog.catalogType}
        activeUOM={selectedUOM}
        isDisabled={isDisabled}
        setActiveUOM={(uom) => {
          setSelectedUOM(uom);

          if (updateUOMCallback) {
            updateUOMCallback(uom);
          }
        }}
      />

      {isMobile ? (
        <div className="flex flex-col justify-center mx-auto">
          <Stack gap={2} direction="row" alignItems={"center"}>
            <Button
              className="p-1 min-w-fit min-h-fit"
              onClick={handleDecrease}
              disabled={isDisabled}
            >
              <RemoveOutlined />
            </Button>
            <Input
              disabled={isDisabled}
              // type="number"
              className="w-full lg:w-24 text-center"
              placeholder={"0"}
              value={inputValue}
              slotProps={{
                input: { min: 0, onWheel: (e) => e.preventDefault() },
              }}
              onFocus={(_e) => {
                if (inputValue === "0") {
                  setInputValue("");
                }
              }}
              sx={{
                [`&& .MuiInput-input`]: {
                  textAlign: "center",
                },
              }}
              onBlur={(_e) => {
                if (!withAddToCart && count === 0) {
                  updateCartQty({
                    catalogId: catalog.id,
                    accountId: session?.account.id || "wildcart",
                    override: true,
                    quantityChange: 0,
                    inventoryItem: item,
                    direct: session?.account.id ? false : true,
                    activeUOM: selectedUOM,
                    storageType: session?.account.id ? "local" : "session",
                    callback: updateQtyCallback && updateQtyCallback,
                  });
                }
              }}
              onChange={(e) => {
                let newValue = e.target.value;
                newValue = newValue.replace(",", ".");
                if (
                  newValue.startsWith("0") &&
                  !newValue.startsWith("0.") &&
                  newValue.length > 1
                ) {
                  newValue = newValue.replace(/^0+/, "");
                }

                if (!isNaN(Number(newValue)) || newValue === "") {
                  if (newValue.includes(".")) {
                    const [integerPart, decimalPart] = newValue.split(".");
                    if (decimalPart.length > 2) {
                      newValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
                    }
                  }

                  setInputValue(newValue);
                  setCount(Number(newValue));
                }

                if (!withAddToCart) {
                  updateCartQty({
                    catalogId: catalog.id,
                    accountId: session?.account.id || "wildcart",
                    override: true,
                    quantityChange: Number(newValue),
                    inventoryItem: item,
                    direct: session?.account.id ? false : true,
                    activeUOM: selectedUOM,
                    storageType: session?.account.id ? "local" : "session",
                    deleteOnNull: false,
                    callback: updateQtyCallback && updateQtyCallback,
                  });
                }
              }}
              onKeyDown={(e) => {
                if (validateOnKeyDown(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            <Button
              className="p-1 min-w-fit min-h-fit"
              onClick={handleIncrease}
              disabled={isDisabled}
            >
              <AddOutlined />
            </Button>
          </Stack>
          {realCount() < (item.inventoryItem.minimumQuantity ?? 0) &&
            count > 0 && (
              <Typography
                fontSize={12}
                mt={1}
                textAlign={"left"}
                sx={{
                  color: theme.palette.danger[500],
                }}
              >
                {t("catalog.minimumQuantity")}
              </Typography>
            )}

          {!isDisabled && (
            <Typography
              fontSize={16}
              fontWeight={600}
              my={2}
              textAlign={"left"}
              sx={{
                color: "black",
                wordBreak: "break-all",
              }}
            >
              <span className="text-sm font-normal mr-4">
                {t("catalog.totalPrice")}
              </span>{" "}
              {`${currency} ${
                isGuestMode
                  ? catalog.catalogType === "PRIVATE"
                    ? "-"
                    : hidePrice
                    ? "-"
                    : formatPrice(
                        count * calculatePrice(item, selectedUOM),
                        currency
                      )
                  : hidePrice
                  ? "-"
                  : formatPrice(
                      count * calculatePrice(item, selectedUOM),
                      currency
                    )
              }`}
            </Typography>
          )}

          {/* // TODO: Simplify this: */}
          {withAddToCart &&
            realCount() >= getMinimumQuantity(item) &&
            setCartUpdated &&
            !hidePrice && (
              <div className="flex mt-3 justify-end pr-4">
                <AddToCartBtn
                  catalog={catalog}
                  inventoryItem={item}
                  setCartUpdated={setCartUpdated}
                  quantity={count}
                  selectedUOM={selectedUOM as "uom1" | "uom2" | "uom3"}
                  unsavedOrderData={unsavedOrderData}
                  orderDataCallback={orderDataCallback}
                />
              </div>
            )}
        </div>
      ) : (
        <div className="flex flex-col justify-end  ml-auto">
          <Stack gap={2} ml={"auto"} direction="row" alignItems={"center"}>
            <Button
              className="p-1 min-w-fit min-h-fit"
              onClick={handleDecrease}
              disabled={isDisabled}
            >
              <RemoveOutlined />
            </Button>
            <Input
              disabled={isDisabled}
              // type="number"
              className="w-full lg:w-24 text-center"
              placeholder={"0"}
              value={inputValue}
              onFocus={(_e) => {
                if (inputValue === "0") {
                  setInputValue("");
                }
              }}
              sx={{
                [`&& .MuiInput-input`]: {
                  textAlign: "center",
                },
              }}
              onBlur={(_e) => {
                if (!withAddToCart && count < 0.1) {
                  updateCartQty({
                    catalogId: catalog.id,
                    accountId: session?.account.id || "wildcart",
                    override: true,
                    quantityChange: 0,
                    inventoryItem: item,
                    direct: session?.account.id ? false : true,
                    activeUOM: selectedUOM,
                    storageType: session?.account.id ? "local" : "session",
                    callback: updateQtyCallback && updateQtyCallback,
                  });
                }
              }}
              onChange={(e) => {
                let newValue = e.target.value;
                newValue = newValue.replace(",", ".");
                if (
                  newValue.startsWith("0") &&
                  !newValue.startsWith("0.") &&
                  newValue.length > 1
                ) {
                  newValue = newValue.replace(/^0+/, "");
                }

                if (!isNaN(Number(newValue)) || newValue === "") {
                  if (newValue.includes(".")) {
                    const [integerPart, decimalPart] = newValue.split(".");
                    if (decimalPart.length > 2) {
                      newValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
                    }
                  }

                  setInputValue(newValue);
                  setCount(Number(newValue));
                }

                if (!withAddToCart) {
                  updateCartQty({
                    catalogId: catalog.id,
                    accountId: session?.account.id || "wildcart",
                    override: true,
                    quantityChange: Number(newValue),
                    inventoryItem: item,
                    direct: session?.account.id ? false : true,
                    activeUOM: selectedUOM,
                    storageType: session?.account.id ? "local" : "session",
                    deleteOnNull: false,
                    callback: updateQtyCallback && updateQtyCallback,
                  });
                }
              }}
              onKeyDown={(e) => {
                if (validateOnKeyDown(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            <Button
              className="p-1 min-w-fit min-h-fit"
              onClick={handleIncrease}
              disabled={isDisabled}
            >
              <AddOutlined />
            </Button>
          </Stack>
          {realCount() < (item.inventoryItem.minimumQuantity ?? 0) &&
            count > 0 && (
              <Typography
                fontSize={12}
                mt={1}
                textAlign={"right"}
                sx={{
                  color: theme.palette.danger[500],
                }}
              >
                {t("catalog.minimumQuantity")}
              </Typography>
            )}

          {!isDisabled && (
            <div className="flex gap-4 align-middle my-4">
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {t("catalog.totalPrice")}{" "}
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={600}
                textAlign={"left"}
                sx={{
                  color: "black",
                  wordBreak: "break-all",
                }}
              >
                {`${currency} ${
                  isGuestMode
                    ? catalog.catalogType === "PRIVATE"
                      ? "-"
                      : hidePrice
                      ? "-"
                      : formatPrice(
                          count * calculatePrice(item, selectedUOM),
                          currency
                        )
                    : hidePrice
                    ? "-"
                    : formatPrice(
                        count * calculatePrice(item, selectedUOM),
                        currency
                      )
                }`}
              </Typography>
            </div>
          )}

          {/* // TODO: Simplify this: */}
          {withAddToCart &&
            realCount() >= getMinimumQuantity(item) &&
            setCartUpdated &&
            !hidePrice && (
              <div className="flex mt-3 justify-end w-full">
                <AddToCartBtn
                  catalog={catalog}
                  inventoryItem={item}
                  setCartUpdated={setCartUpdated}
                  quantity={count}
                  selectedUOM={selectedUOM as "uom1" | "uom2" | "uom3"}
                  unsavedOrderData={unsavedOrderData}
                  orderDataCallback={orderDataCallback}
                />
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default CartQuantityManager;
