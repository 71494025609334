import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import showErrorToast, { ErrorToastConfig } from "../../../components/Error";
import { BusinessService } from "../../../services/business.service";
import { TaxService } from "../../../services/tax.service";
import { ITax } from "../../../types";
import {
  IBusinessResponse,
  ICreateBusiness,
  IUpdateBusiness,
} from "../../../types/business";

const businessScheme = z.object({
  companyName: z.string().min(3),
  // companyEmail: z.string().email(),
  companyNumber: z.string().optional(),
  companyNumberCountryCode: z
    .string()
    .optional()
    .transform((arg, ctx) => {
      if (arg) {
        if (!new RegExp(/^[0-9]+$/).test(arg)) {
          ctx.addIssue({
            code: "custom",
            message: "Should be numbers",
          });
        }
      }
      return arg;
    }),
  companyWebsite: z.string().optional(),
  companyCountry: z.string(),
  companyProvince: z.string().optional(),
  companyAddress: z.string().optional(),
  companyPostalCode: z
    .string()
    .optional()
    .transform((arg, ctx) => {
      if (arg) {
        if (!new RegExp(/^[0-9]+$/).test(arg)) {
          ctx.addIssue({
            code: "custom",
            message: "Should be numbers",
          });
        }
      }
      return arg;
    }),
  companyCurrency: z.string().optional(),
  companyBankName: z.string().optional().nullable(),
  companyBankAccountNo: z
    .string()
    .optional()
    .transform((arg, ctx) => {
      if (arg) {
        if (!new RegExp(/^[0-9]+$/).test(arg)) {
          ctx.addIssue({
            code: "custom",
            message: "Should be number",
          });
        }
      }
      return arg;
    }),
  companyBankAccountHolderName: z.string().optional(),
  companyPaymentTerms: z.array(z.string()).optional(),
  applicableAdminFee: z.string().optional(),
});

export default function useBusinessContainer({ isEditMode = false }) {
  const { state } = useLocation();
  const [selectedTaxes, setSelectedTaxes] = useState<string[]>([]);
  const [editProfileOnly, setEditProfileOnly] = useState(false);
  const [backUrl, setBackUrl] = useState(null);
  const navigate = useNavigate();
  const [defaultTaxId, setDefaultTaxId] = useState<string>();
  const [companyIcon, setCompanyIcon] = useState<File | null>(null);
  console.log({ selectedTaxes });
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm<z.infer<typeof businessScheme>>({
    resolver: zodResolver(businessScheme),
  });

  const { companyCountry } = watch();

  const mutation = useMutation({
    mutationFn: BusinessService.createBusiness,
    onSuccess: (data) => {
      taxAssociateMutation.mutate({
        businessId: data.id,
        taxIds: selectedTaxes,
      });
      if (defaultTaxId)
        defaultTaxMutation.mutate({ businessId: data.id, taxId: defaultTaxId });
    },
    onError: (error: any) => {
      setLoading(false);
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const upadateMutation = useMutation({
    mutationFn: BusinessService.updateBusiness,
    onSuccess: (data) => {
      if (!editProfileOnly)
        taxAssociateMutation.mutate({
          businessId: data.id,
          taxIds: selectedTaxes,
        });
      else {
        setLoading(false);
        setIsSuccess(true);
        setTimeout(() => {
          if (backUrl) window.location.href = backUrl;
          else navigate("/client/profile", { replace: true });
        }, 2000);
      }
    },
    onError: (error: any) => {
      setLoading(false);
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });
  const defaultTaxMutation = useMutation({
    mutationFn: BusinessService.setBusinessDefaultTax,
    onSuccess: () => { },
  });
  const taxAssociateMutation = useMutation({
    mutationFn: TaxService.associateTaxesOption,
    onSuccess: () => {
      setLoading(false);
      setIsSuccess(true);
      setTimeout(() => {
        navigate("/client/profile", { replace: true });
      }, 2000);
    },
    onError: (error: any) => {
      setLoading(false);
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const taxesOptionsQuery = useQuery({
    queryKey: ["taxes", companyCountry],
    queryFn: () => TaxService.getTaxesOption(companyCountry ?? ""),
    enabled: !!companyCountry,
    retry: false,
    staleTime: 0,
  });

  const handleSetCompanyIcon = (file: File | null) => {
    if (file) {
      setCompanyIcon(file);
    }
  };

  const onSubmit: SubmitHandler<z.infer<typeof businessScheme>> = (data) => {
    setLoading(true);
    console.log("SEND", data.applicableAdminFee)
    const payload: ICreateBusiness = {
      companyName: data.companyName,
      // companyEmail: data.companyEmail,
      companyNumber: data.companyNumber,
      companyNumberCountryCode: data.companyNumberCountryCode,
      companyWebsite: data.companyWebsite,
      companyCountry: data.companyCountry,
      companyProvince: data.companyProvince,
      companyAddress: data.companyAddress,
      companyPostalCode: data.companyPostalCode,
      companyCurrency: data.companyCurrency,
      companyBankName: data.companyBankName,
      companyBankAccountNo: data.companyBankAccountNo,
      companyBankAccountHolderName: data.companyBankAccountHolderName,
      companyPaymentTerms: data.companyPaymentTerms,
      applicableAdminFee: data.applicableAdminFee
    };
    if (isEditMode) {
      upadateMutation.mutate({
        businessId: (state as IBusinessResponse).id,
        payload: payload as IUpdateBusiness,
      });
    } else {
      mutation.mutate(payload);
    }
  };

  const setDataFromCurrentState = () => {
    if (state) {
      setDefaultTaxId(state?.defaultTax?.id ?? "");
      setEditProfileOnly(state.profileOnly ?? false);
      setBackUrl(state.backUrl ?? "");
      const currentBussiness = state as IBusinessResponse;
      setValue("companyName", currentBussiness.companyName);
      // setValue("companyEmail", currentBussiness.companyEmail);
      setValue("companyNumber", currentBussiness.companyNumber);
      setValue(
        "companyNumberCountryCode",
        currentBussiness.companyNumberCountryCode
      );
      setValue("companyWebsite", currentBussiness.companyWebsite);
      setValue("companyCountry", currentBussiness.companyCountry);
      setValue("companyProvince", currentBussiness.companyProvince);
      setValue("companyAddress", currentBussiness.companyAddress);
      setValue("companyPostalCode", currentBussiness.companyPostalCode);
      var bankInfo =
        (currentBussiness.bankInfo?.length ?? 0) > 0
          ? currentBussiness.bankInfo[0]
          : null;
      if (bankInfo) {
        setValue("companyCurrency", bankInfo.currency);
        setValue("companyBankName", bankInfo.bankName);
        setValue("companyBankAccountNo", bankInfo.accountNo);
        setValue("companyBankAccountHolderName", bankInfo.accountHolderName);
      }
      setValue("companyPaymentTerms", currentBussiness.companyPaymentTerms);
      console.log("ADMINFEE", currentBussiness.applicableAdminFee)
      setValue("applicableAdminFee", currentBussiness.applicableAdminFee)
    }
  };

  useEffect(() => {
    if (isEditMode) {
      setDataFromCurrentState();
    }
  }, [isEditMode, state]);

  async function fetchAndSetBusinessTax() {
    try {
      const res = await TaxService.getTaxesIdsByBusiness(state?.id);
      setSelectedTaxes(res);
    } catch (error) { }
  }

  useEffect(() => {
    if (isEditMode) {
      fetchAndSetBusinessTax();
    }
  }, [isEditMode]);

  useEffect(() => {
    if (!isEditMode) setDefaultTaxId(undefined);
    setSelectedTaxes([]);
  }, [companyCountry]);
  return {
    errors,
    companyIcon,
    loading,
    isSuccess,
    editProfileOnly,
    register,
    handleSetCompanyIcon,
    watch,
    handleSubmit,
    onSubmit,
    setValue,
    setError,
    taxOptions: (taxesOptionsQuery.data || []) as ITax[],
    selectedTaxes,
    setSelectedTaxes,
    companyCountry,
    setDefaultTaxId,
    defaultTaxId,
  };
}
