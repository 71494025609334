import { Squares2X2Icon } from "@heroicons/react/24/outline";
import { Add, Remove } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/joy";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useAuthStore } from "../../store/session";
import { BrowserRouterConstants } from "../../utils/constants";
import { Toggler, transformUomList } from "../utils";
import OrderItem from "./OrderItem";
import { IOrderRevision } from "../../types/order";

export default ({
  defaultTax,
  nationalTaxes,
  productSearchList,
  isBusinessAuthorized,
  isReadOnly,
  orderType,
  sellerCountry,
  isAdjustmentOrder,
  lowStockItems,
  updateItemCallback,
  revisions,
}: any) => {
  const mainOrder = revisions
    ? revisions.find((i: IOrderRevision) => i.isMain)
    : null;

  const { t } = useTranslation();
  const { control, getValues } = useFormContext();
  const { session } = useAuthStore();
  const { orderId } = useParams();
  const [isAppending, setIsAppending] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  // console.log ("Product Search List:::", productSearchList);
  const { clearErrors } = useFormContext();
  const {
    fields: orderItemFields,
    append: appendOrderItem,
    remove: removeOrderItem,
    update: updateOrderItem,
  } = useFieldArray({
    control,
    name: "orderItems",
  });

  console.log("Order Item Default Tax ::", getValues("defaultTaxItem"));

  const newProductItem = {
    orderItemId: "",
    productId: "",
    // businessId: getValues("sellerIds.businessId") || "",
    businessId: "",
    currency: getValues("currency") || "",
    catalogId: "",
    sku: "",
    productName: "",
    productDescription: "",
    inventoryType: "unknown",
    uomList: "",
    quantity: 1,
    price: 0,
    discount: 0,
    tax: 0,
    totalPrice: 0,
    createdById: session?.account.id.toString() || "",
    additionalTax: defaultTax,
    taxItem: {
      taxLabel: getValues("defaultTaxItem.taxLabel") || "",
      taxAmount: getValues("defaultTaxItem.taxAmount") || 0,
      taxType: getValues("defaultTaxItem.taxType") || null,
    },
    taxLabel: getValues("defaultTaxItem.taxLabel") || "",
    taxAmount: getValues("defaultTaxItem.taxAmount") || 0,
    taxType: getValues("defaultTaxItem.taxType") || null,
  };

  const handleRemoveOrderItem = (index: number) => {
    if (isRemoving) return;
    setIsRemoving(true);
    try {
      removeOrderItem(index);
    } catch (error) {
      // Handle error
    } finally {
      /** for quantity rework, validate the qty whenever removing item if lowStockItems.length > 0 */
      if (lowStockItems.length > 0 && updateItemCallback) {
        updateItemCallback(getValues("orderItems"));
      }
      setIsRemoving(false);
    }
  };

  const handleUpdateOrderItem = (index: number, product: any) => {
    if (isAppending) return;
    setIsAppending(true);
    console.log("handled Update Product ::: ", product);

    try {
      updateOrderItem(index, {
        selectedItem: product, // selected item object from AutoComplete search
        productId: product.id,
        businessId: product.business,
        catalogId: product.catalog || "",
        createdById: product.createdBy,
        price: product.price,
        uomItem: getValues(`orderItems.${index}.uomItem`),
        uomList: transformUomList(product),
        // quantity: product.quantity || 1,
        discount: product.discount || 0,
        discountType: getValues(`orderItems.${index}.discountType`),
        tax: product.tax || 0,
        sku: product.sku,
        productName: product.productName,
        productDescription: product.productDescription,
        inventoryType: product.inventoryType,
        orderItemId: product.orderItemId,
      });
    } catch (error) {
    } finally {
      /** for quantity rework, validate the qty whenever removing item if lowStockItems.length > 0 */
      clearErrors(`orderItems.${index}.quantity`);
      if (lowStockItems.length > 0 && updateItemCallback) {
        updateItemCallback(getValues("orderItems"));
      }

      setIsAppending(false);
    }
  };

  return (
    <Toggler
      defaultExpanded={true}
      renderToggle={({ open, setOpen, sxClasses }) => (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          onClick={() => setOpen(!open)}
          width={{ xs: "90vw", md: "100%" }}
          sx={sxClasses}
        >
          <Typography
            startDecorator={<Squares2X2Icon width={24} height={24} />}
            fontWeight={600}
          >
            {t("order.createOrder.productForm.addProduct")}
            <span className={`text-danger-500`}>*</span>
          </Typography>
          {open ? <Remove color="primary" /> : <Add color="primary" />}
        </Stack>
      )}
    >
      <Stack spacing={4} width={{ xs: "90vw", md: "100%" }}>
        {!isReadOnly && isBusinessAuthorized && !isAdjustmentOrder && (
          <Link
            to={`/client/${BrowserRouterConstants.Supplier}?order=${orderId}`}
          >
            <Button
              variant="outlined"
              sx={{ alignSelf: "start" }}
              onClick={() => appendOrderItem(newProductItem)}
            >
              {t("order.createOrder.productForm.addFromCatalogBtn")}
            </Button>
          </Link>
        )}

        {orderItemFields?.map((field, index: number) => (
          <OrderItem
            key={field.id + index}
            index={index}
            orderItemField={field}
            handleRemoveOrderItem={handleRemoveOrderItem}
            handleUpdateOrderItem={handleUpdateOrderItem}
            isReadOnly={isReadOnly}
            nationalTaxes={nationalTaxes}
            productSearchList={productSearchList}
            sellerCountry={sellerCountry}
            orderType={orderType}
            isAdjustmentOrder={isAdjustmentOrder}
            mainOrderContent={mainOrder?.content.orderItems}
          />
        )) || ""}

        {!isReadOnly && !isAdjustmentOrder && (
          <Button
            variant="outlined"
            sx={{ alignSelf: "start" }}
            onClick={() => {
              appendOrderItem(newProductItem);
            }}
          >
            {t("order.createOrder.productForm.addNewBtn")}
          </Button>
        )}
      </Stack>
    </Toggler>
  );
};
