import { CheckCircle, KeyboardArrowDown } from "@mui/icons-material";
import { Option, Select, Stack, Typography } from "@mui/joy";
import { useQueryClient } from "@tanstack/react-query";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import dayjs from "dayjs";
import "dayjs/locale/id";
import "flag-icons/css/flag-icons.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FlagById from "../../components/FlagById";
import LoanIcon from "../../components/Icons/LoanIcon";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { BusinessSessionHelper } from "../../helpers/business-session.helper";
import { BusinessService } from "../../services/business.service";
import { useAuthStore } from "../../store/session";
import { animateSelectStyle } from "../../styles/select";
import { IBusinessResponse } from "../../types/business";
import { BrowserRouterConstants } from "../../utils/constants";
import DahsboardChart from "./components/DahsboardChart";
import useContainer from "./useContainer";
import i18next from "../../i18n/config";
import { TrackerService } from "../../services/tracker.service";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events.ts";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Dashboard() {
  const { session } = useAuthStore();
  const { t } = useTranslation();
  const [availableBusinesses, setAvailableBusinesses] = useState<
    IBusinessResponse[]
  >([]);
  const [activeBusiness, setActiveBusiness] = useState<IBusinessResponse>();
  const { data, isPending } = useContainer();
  const client = useQueryClient();
  const getAvailableBusinesses = () => {
    BusinessService.getBusiness({ paging: false }).then((data) => {
      setAvailableBusinesses(data);
      const activeBusiness = data.find(
        (b) => b.id === BusinessSessionHelper.GetBusinessId()
      );
      if (activeBusiness) setActiveBusiness(activeBusiness);
    });
  };

  useEffect(() => {
    TrackerService.track(TRACKER_CONSTANTS.LOGIN_SUCCESS.events.HomeDashboardVisited, {
      // ...session,
      // ...session?.activeBusiness
    })
    getAvailableBusinesses();
  }, []);
  if (isPending) {
    return <LoadingModal isLoading={isPending} />;
  }
  return (
    <Stack spacing={3} mt={4}>
      <hr className="h-1  border-[#636B744D] border-0 border-b" />
      <Typography>{t("dashboard.hello")}</Typography>
      <Typography level="h2">{session?.profile.fullName}</Typography>
      <Select
        key={activeBusiness?.id}
        value={activeBusiness}
        onChange={async (_, value) => {
          if (!value) return;
          await BusinessSessionHelper.SetActiveBusiness(value as IBusinessResponse);
          setActiveBusiness(value as IBusinessResponse);
          client.invalidateQueries({ queryKey: ["business-credits", "kyc-payment-gateway-status"] });
        }}
        renderValue={(option) => (
          <Typography
            fontWeight={600}
            mr={"auto"}
            startDecorator={
              <span
                className={`fi fi-${activeBusiness?.companyCountry.toLocaleLowerCase()} shadow-md`}
              ></span>
            }
          >
            {option?.label}
          </Typography>
        )}
        variant="plain"
        size="md"
        indicator={<KeyboardArrowDown />}
        sx={{ ...animateSelectStyle, backgroundColor: "white" }}
      >
        {availableBusinesses
          ?.sort(
            (a, b) =>
              (a.id === activeBusiness?.id ? 0 : 1) -
              (b.id === activeBusiness?.id ? 0 : 1)
          )
          .map((business) => (
            <Option
              key={business.id}
              value={business}
              label={business.companyName}
            >
              <Stack
                sx={{ width: "100%" }}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={5}
              >
                <div>
                  <Typography
                    sx={{ flex: 1 }}
                    startDecorator={<FlagById id={business.companyCountry} />}
                  >
                    {business.companyName}
                  </Typography>
                  {business.id === activeBusiness?.id && (
                    <Typography fontSize={12} textColor="neutral.500">
                      {t("profile.business.currentLoggedInCompanyLabel")}
                    </Typography>
                  )}
                </div>
                {business.id === activeBusiness?.id && (
                  <CheckCircle color="primary" />
                )}
              </Stack>
            </Option>
          ))}
      </Select>
      <Stack spacing={8}>
        {/* Credit section */}
        <Stack
          spacing={4}
          className={`p-4 border rounded-2xl ${+data?.credits < 1 ? "border-[#C41C1C]" : "border-[#1f7a1f]"
            }`}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} spacing={2}>
              <LoanIcon />
              <Typography textColor={"text.primary"} fontWeight={400}>
                {t("dashboard.yourCredits")}
              </Typography>
            </Stack>
            <Typography
              fontWeight={600}
              fontSize={20}
              textColor={+data?.credits < 1 ? "danger.500" : "text.primary"}
            >
              {data?.credits}
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography>
                {t("commons.dateUpdated")}{" "}
                {dayjs(data?.updatedAt)
                  .locale(i18next.resolvedLanguage ?? "en")
                  .format("ddd D MMM hh:mm A")}
              </Typography>
              <Link to={BrowserRouterConstants.Credit}>
                <Typography
                  paddingX={4}
                  paddingY={1}
                  fontWeight={600}
                  textColor={"primary.plainColor"}
                >
                  {t("dashboard.topUpBtn")}
                </Typography>
              </Link>
            </Stack>
            <hr className="border-0 border-dotted border-[#636B744D] border-b" />
          </Stack>
          <Link to={BrowserRouterConstants.CreditTransactionTab}>
            <Typography
              textAlign={"center"}
              paddingY={1}
              textColor={"primary.plainColor"}
              fontWeight={600}
              fontSize={14}
            >
              {t("dashboard.transactionHistoryBtn")}
            </Typography>
          </Link>
        </Stack>
        {/* Chart Section */}
        <Stack spacing={8}>
          {/* OVerview */}
          <DahsboardChart />
        </Stack>
      </Stack>
      {/* <ComingSoon /> */}
    </Stack>
  );
}
