import { Id, My, Sg, Th } from "react-flags-select";

const FlagsMap: Map<string, JSX.Element> = new Map([
    ["ID", <Id />],
    ["MY", <My />],
    ["SG", <Sg />],
    ["TH", <Th />],
]);

export default function FlagById({ id }: { id: string }) {
    return FlagsMap.get(id);
}

