import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Drawer,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Textarea,
  Typography,
  useTheme,
} from "@mui/joy";
import {
  ChevronLeftOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { CatalogInventoryItemType } from "../../../types/item";
import { formatPrice } from "../../../utils/formatPrice";
import { calculatePrice } from "../../../utils/cart";
import { CatalogType } from "../../../types/catalog";
import CartQuantityManager from "../../Catalogue/Item/CartQuantityManager";
import { Toggler } from "../../utils";
import { IfElse } from "../../Condition";

interface Props {
  item: CatalogInventoryItemType;
  catalog: CatalogType;
  setCartUpdated: Dispatch<SetStateAction<boolean>>;
  mobileJustifyContent?: string;
  withAddToCart?: boolean;
  selectedUOM?: "uom1" | "uom2" | "uom3";
  isDisabled?: boolean;
  updateUOMCallback?: (uom: "uom1" | "uom2" | "uom3") => void;
  unsavedOrderData?: string | null;
  orderDataCallback?: () => void;
}

const SeeDetails = ({
  item,
  catalog,
  setCartUpdated,
  mobileJustifyContent = "justify-center",
  withAddToCart = true,
  selectedUOM = "uom1",
  isDisabled = false,
  updateUOMCallback,
  unsavedOrderData,
  orderDataCallback,
}: Props) => {
  const currency = item.currency ?? item.inventoryItem.currency;
  const { t } = useTranslation();
  // TODO: replace with /public
  const isGuestMode = window.location.pathname.startsWith("/catalog");
  const theme = useTheme();

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.sm
  );
  const [isOpen, setisOpen] = useState(false);
  const [activeUOM, setActiveUOM] = useState<"uom1" | "uom2" | "uom3">(
    selectedUOM
  );

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  return (
    <IfElse
      condition={isMobile}
      ifBlock={
        <>
          <Button
            variant="plain"
            className={`focus:bg-transparent hover:bg-transparent w-full lg:min-w-fit lg:ml-0 ${mobileJustifyContent} lg:justify-start lg:text-left whitespace-nowrap mt-auto`}
            onClick={() => {
              setisOpen(!isOpen);
            }}
          >
            {t(
              `catalog.supplier.items.${
                isOpen ? "lessDetailsBtn" : "seeDetailsBtn"
              }`
            )}
            <span className="ml-2 hidden lg:block">
              {isOpen ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
            </span>
          </Button>
          <Drawer
            open={isOpen}
            onClose={() => setisOpen(false)}
            anchor="bottom"
            size="lg"
          >
            <div className="h-screen">
              <div
                className={`flex align-middle p-3 border-b border-[${theme.colorSchemes.light.palette.others.disabled}]`}
              >
                <IconButton
                  variant="plain"
                  onClick={() => setisOpen(false)}
                  className="absolute top-2"
                >
                  <ChevronLeftOutlined />
                </IconButton>
                <span className="mx-auto my-auto">
                  <Typography fontSize={16} fontWeight={600}>
                    {t("catalog.supplier.items.detailsLabel")}
                  </Typography>
                </span>
              </div>

              <div className="w-full flex justify-center mt-4">
                <img
                  src={`${item.inventoryItem.thumbnail ?? "/union.png"}`}
                  alt="item_image"
                  // width={100}
                  // height={100}
                  className="border-b lg:border border-gray-300 lg:mr-4 p-2 w-full sm:w-screen sm:h-[50vw] md:w-[393px] md:h-[393px] lg:w-[100px] lg:h-[100px] object-contain"
                />
              </div>

              <div className="flex w-screen overflow-auto gap-2 my-4 px-4">
                {item.inventoryItem.uom1 && (
                  <Button
                    variant="outlined"
                    className={`rounded-lg min-w-20 ${
                      activeUOM === "uom1" ? "bg-primary-500 text-white" : ""
                    }`}
                    disabled={isDisabled}
                    onClick={() => {
                      setActiveUOM("uom1");

                      if (updateUOMCallback) {
                        updateUOMCallback("uom1");
                      }
                    }}
                  >
                    {item.inventoryItem.uom1}
                  </Button>
                )}
                {item.inventoryItem.uom2 && (
                  <Button
                    variant="outlined"
                    className={`rounded-lg min-w-20 ${
                      activeUOM === "uom2" ? "bg-primary-500 text-white" : ""
                    }`}
                    disabled={isDisabled}
                    onClick={() => {
                      setActiveUOM("uom2");

                      if (updateUOMCallback) {
                        updateUOMCallback("uom1");
                      }
                    }}
                  >
                    {item.inventoryItem.uom2}
                  </Button>
                )}
                {item.inventoryItem.uom3 && (
                  <Button
                    variant="outlined"
                    className={`rounded-lg min-w-20 ${
                      activeUOM === "uom3" ? "bg-primary-500 text-white" : ""
                    }`}
                    disabled={isDisabled}
                    onClick={() => {
                      setActiveUOM("uom3");

                      if (updateUOMCallback) {
                        updateUOMCallback("uom1");
                      }
                    }}
                  >
                    {item.inventoryItem.uom3}
                  </Button>
                )}
              </div>

              <Grid xs={12} container sx={{ width: "100%", padding: 2 }}>
                <Grid xs={6}>
                  <Typography
                    fontSize={14}
                    sx={{
                      color: theme.colorSchemes.dark.palette.text.secondary,
                    }}
                  >
                    {t("catalog.supplier.items.productName")}:{" "}
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <div className="w-full text-right">
                    <span className="text-black text-sm">
                      {item.inventoryItem.productName}
                    </span>
                  </div>
                </Grid>

                <Grid xs={6}>
                  <Typography
                    fontSize={14}
                    sx={{
                      color: theme.colorSchemes.dark.palette.text.secondary,
                    }}
                  >
                    SKU:
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <div className="w-full text-right">
                    <span className="text-black text-sm">
                      {item.inventoryItem.sku}
                    </span>
                  </div>
                </Grid>

                <Grid xs={6}>
                  <Typography
                    fontSize={14}
                    sx={{
                      color: theme.colorSchemes.dark.palette.text.secondary,
                    }}
                  >
                    {t("catalog.items.category")}:{" "}
                  </Typography>
                </Grid>
                <Grid xs={6}>
                  <div className="w-full text-right">
                    <span className="text-black text-sm">
                      {item.inventoryItem.inventoryType}
                    </span>
                  </div>
                </Grid>

                {item.inventoryItem.flexiColumns?.data.map((col) => (
                  <>
                    <Grid xs={6}>
                      <Typography
                        fontSize={14}
                        sx={{
                          color: theme.colorSchemes.dark.palette.text.secondary,
                        }}
                      >
                        {col.columnName}:{" "}
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      <div className="w-full text-right">
                        <span className="text-black text-sm">
                          {col.cellValue}
                        </span>
                      </div>
                    </Grid>
                  </>
                ))}

                <Grid xs={6}>
                  <Typography
                    fontSize={14}
                    sx={{
                      color: theme.colorSchemes.dark.palette.text.secondary,
                    }}
                  >
                    {t("catalog.supplier.items.price")}:{" "}
                  </Typography>
                </Grid>

                {catalog && (
                  <Grid xs={6}>
                    <div className="w-full text-right">
                      {item.price && item.inventoryItem.price > item.price && (
                        <p className="text-gray-600 line-through">
                          {`${currency} ${
                            isGuestMode
                              ? catalog.catalogType === "PRIVATE"
                                ? "-"
                                : catalog.hidePrice
                                ? "-"
                                : formatPrice(
                                    calculatePrice(item, activeUOM),
                                    currency
                                  )
                              : catalog.hidePrice
                              ? "-"
                              : formatPrice(
                                  calculatePrice(item, activeUOM),
                                  currency
                                )
                          }`}
                        </p>
                      )}

                      <span className="text-black text-sm font-semibold">
                        <Typography
                          className="text-black font-bold"
                          fontSize={16}
                          sx={{
                            wordBreak: "break-all",
                          }}
                        >
                          {`${currency} ${
                            isGuestMode
                              ? catalog.catalogType === "PRIVATE"
                                ? "-"
                                : catalog.hidePrice
                                ? "-"
                                : formatPrice(
                                    calculatePrice(item, activeUOM),
                                    currency
                                  )
                              : catalog.hidePrice
                              ? "-"
                              : formatPrice(
                                  calculatePrice(item, activeUOM),
                                  currency
                                )
                          }`}
                          {item.inventoryItem[activeUOM] &&
                            `/${item.inventoryItem[activeUOM]}`}
                        </Typography>
                      </span>
                    </div>
                  </Grid>
                )}

                <Grid xs={12}>
                  <FormControl sx={{ flex: "1", marginTop: "1rem" }}>
                    <FormLabel>
                      {t("catalog.inventory.editForm.productDescription")}
                    </FormLabel>
                    <Textarea
                      disabled
                      size="sm"
                      placeholder={t(
                        "catalog.inventory.editForm.productDescription"
                      )}
                      minRows={4}
                      defaultValue={item.inventoryItem.productDescription}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              {withAddToCart && !catalog.hidePrice && (
                <div
                  className={`flex align-middle p-3 border-t border-[${theme.colorSchemes.light.palette.others.disabled}] sticky bottom-0 w-full z-10 bg-white`}
                >
                  <CartQuantityManager
                    item={item}
                    hidePrice={catalog?.hidePrice}
                    withAddToCart
                    catalog={catalog}
                    setCartUpdated={setCartUpdated}
                    defaultUOM={activeUOM}
                    unsavedOrderData={unsavedOrderData}
                    orderDataCallback={orderDataCallback}
                  />
                </div>
              )}
            </div>
          </Drawer>
        </>
      }
      elseBlock={
        <Toggler
          renderToggle={({ open, setOpen }) => (
            <Button
              variant="plain"
              className={`focus:bg-transparent hover:bg-transparent w-full lg:min-w-fit lg:ml-0 ${mobileJustifyContent} lg:justify-start lg:text-left whitespace-nowrap`}
              onClick={() => {
                setOpen(!open);
              }}
            >
              {t(
                `catalog.supplier.items.${
                  open ? "lessDetailsBtn" : "seeDetailsBtn"
                }`
              )}
              <span className="ml-2 hidden lg:block">
                {open ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
              </span>
            </Button>
          )}
          buttonPosition="bottom"
        >
          <Stack gap={1} my={0}>
            {item.inventoryItem.flexiColumns?.data?.map((col) => (
              <Typography
                fontSize={14}
                sx={{
                  color: "black",
                }}
              >
                {col.columnName}:{" "}
                <span className="text-black">{col.cellValue}</span>
              </Typography>
            ))}
            <FormControl sx={{ flex: "1", marginTop: "12px", width: "100%" }}>
              <FormLabel sx={{ fontSize: 14 }}>
                {t("catalog.inventory.editForm.productDescription")}
              </FormLabel>
              <Textarea
                disabled
                size="sm"
                placeholder={t("catalog.inventory.editForm.productDescription")}
                minRows={4}
                defaultValue={item.inventoryItem.productDescription}
              />
            </FormControl>
          </Stack>
        </Toggler>
      }
    />
  );
};

export default SeeDetails;
