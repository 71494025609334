import React from "react";
import { useTranslation } from "react-i18next";

export const sampleOrder = {
  isPurchaseOrder: false,
  orderType: "Purchase Order",
  invoiceNumber: "PO123456",
  orderStatus: "Shipped",
  issuedDate: "2024-08-12",
  companyName: "Tech Solutions Inc.",
  companyMobile: "+1234567890",
  companyEmail: "contact@techsolutions.com",
  companyAddress: "1234 Innovation Drive, Tech City, TC 56789",
  customerName: "Jane Doe",
  customerMobile: "+0987654321",
  customerEmail: "jane.doe@example.com",
  bankName: "Global Bank",
  accountNo: "GB123456789",
  paymentTerm: "Net 30",
  customerAddress: "5678 Customer Lane, Consumer Town, CT 12345",
  items: [
    {
      currency: "IDR",
      sku: "A001",
      name: "Widget Pro",
      quantity: 10,
      price: 25.0,
      discount: 5.0,
      tax: 2.5,
      total: 225.0,
    },
    {
      currency: "IDR",
      sku: "B002",
      name: "Gadget X",
      quantity: 5,
      price: 50.0,
      discount: 0.0,
      tax: 7.5,
      total: 275.0,
    },
  ],
  currency: "IDR",
  subTotal: 500.0,
  additionalDiscount: 10.0,
  deliveryFee: 15.0,
  additionalTax: 5.0,
  totalDiscount: 10.0,
  totalTax: 10.0,
  grandTotalAmount: 510.0,
  downPayment: 510.0,
  driverName: "John Smith",
  deliveryDate: "2024-08-15",
  shippingMethod: "Standard Shipping",
  vehicleNumber: "XYZ-1234",
  trackingNumber: "TRACK123456",
  remark: "Handle with care",
  issuedBy: "Alice Johnson",
  approvedBy: "Bob Brown",
  accountHolderName: "",
  paymentType: "",
};

export type OrderDetailsProps = typeof sampleOrder;

const OrderDetails = ({
  paymentType,
  accountHolderName,
  isPurchaseOrder,
  orderType,
  invoiceNumber,
  issuedDate,
  companyName,
  companyMobile,
  companyEmail,
  companyAddress,
  customerName,
  customerMobile,
  customerEmail,
  bankName,
  accountNo,
  paymentTerm,
  customerAddress,
  items = [],
  currency,
  subTotal,
  additionalDiscount,
  deliveryFee,
  additionalTax,
  totalDiscount,
  totalTax,
  grandTotalAmount,
  downPayment,
  driverName,
  deliveryDate,
  shippingMethod,
  vehicleNumber,
  trackingNumber,
  remark,
  issuedBy,
  approvedBy,
}: OrderDetailsProps) => {
  console.log({ paymentType });
  const { t } = useTranslation();
  React.useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
   html,
body {
  padding: 0;
  margin: 0 auto;
}

body {
  font-family: "Plus Jakarta Sans", Arial, sans-serif;
}

p {
  padding: 0;
  margin: 0 0 10px;
}

.txt-lg {
  font-size: 24px;
}

.mt-4 {
  margin-top: 32px;
}

.mt-8 {
  margin-top: 64px;
}

.mt-4 {
  margin-top: 26px;
}

.semi-bold {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.header {
  font-size: 24px;
  font-weight: bold;
}

.subheader {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.subheader-1 {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: start;
}

.subheader-1 p {
  margin-bottom: 0;
  line-height: 16px;
}

.subheader-1-bold {
  font-size: 12px;
  font-weight: bold;
}

th {
  font-size: 10px;
}

td {
  font-size: 10px;
}

th,
td {
  padding: 10px;
  border: 1px solid #636b744d;
}

.capitalize {
  text-transform: capitalize;
}

.buyer-seller-table tr td {
  width: 50%;
  vertical-align: top;
  border: none;
}

.bottom-part-table tr td {
  border: none;
  vertical-align: bottom;
}

.bottom-part-table tr td:first-child {
  width: 50%;
}

.bottom-part-table .bottom-part-amount-table tr td {
  width: 50%;
  border: none;
}

.bottom-part-table .bottom-part-amount-table tr td:last-child {
  text-align: right;
}

.bottom-part-table .bottom-part-amount-table tfoot {
  border-top: 1px solid #000;
  border-top-style: dashed;
}

.container-pdf {
  background-color: #fff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.underline {
  width: 279px;
  height: 0.5px;
  background-color: #101828;
  border: none;
  margin: 8px 0 8px 0;
}

.td_summary {
  border-left: none;
  border-right: none;
  padding: 8px 0 8px 8px;
}

.td_summary_title {
  font-weight: 700;
}

.grey {
  background-color: #f2f4f7;
  border-bottom: none;
  border-top: none;
}

.border-none {
  border: none;
}

.issued-approved {
  font-size: 12px !important;
}

.date-label {
  font-weight: 400;
  padding-left: 0;
}

.product-row th {
  border-color: #667085;
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.product-row td,
th {
  border-left: none;
  border-right: none;
  text-align: center;
}
    `;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <title>Order Details</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap"
          rel="stylesheet"
        />
      </head>

      <body>
        <div
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            minHeight: "1128px",
          }}
        >
          <div style={{ flex: 1 }} className="container-pdf">
            <div>
              <div className="header">{t(`order.pdf.type.${orderType}`)}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <p
                  className="subheader-1"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <span className="subheader-1-bold">
                    {t("order.createOrder.summary.orderNumber")}:
                  </span>
                  {invoiceNumber}
                </p>
                <p className="subheader-1">
                  <span className="subheader-1-bold">
                    {t("order.pdf.issuedDate")}:
                  </span>
                  {issuedDate}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "12px",
              }}
            >
              <CompanyDetails
                isPurchaseOrder={isPurchaseOrder}
                companyName={companyName}
                companyMobile={companyMobile}
                companyEmail={companyEmail}
                bankName={bankName}
                accountNo={accountNo}
                accountHolderName={accountHolderName}
                companyAddress={companyAddress}
              />
              <CustomerDetails
                isPurchaseOrder={isPurchaseOrder}
                customerName={customerName}
                customerMobile={customerMobile}
                customerEmail={customerEmail}
                bankName={bankName}
                accountNo={accountNo}
                accountHolderName={accountHolderName}
                paymentTerm={paymentTerm}
                customerAddress={customerAddress}
              />
            </div>
            {/* purchase details */}
            <div
              style={{
                borderTop: "5px solid #101828",
              }}
            >
              <h2 className="subheader">{t("order.pdf.purchaseDetails")}</h2>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  marginTop: "16px",
                  borderRadius: "24px",
                }}
                className="table"
              >
                <thead>
                  <tr className="product-row">
                    <th>{t("order.pdf.table.no")}</th>
                    <th>{t("order.pdf.table.sku")}</th>
                    <th>{t("order.pdf.table.product")}</th>
                    <th>{t("order.pdf.table.quantity")}</th>
                    <th>{t("order.pdf.table.discount")}</th>
                    <th>{t("order.pdf.table.tax")}</th>
                    <th>
                      {t("order.pdf.table.price")}&nbsp;({currency})
                    </th>
                    <th>
                      {t("order.pdf.table.total")}&nbsp;({currency})
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, i) => (
                    <React.Fragment key={i}>
                      {" "}
                      <tr className="product-row">
                        <td>{i + 1}</td>
                        <td>{item.sku}</td>
                        <td>{item.name}</td>
                        <td className="text-right">{item.quantity}</td>

                        <td className="text-right">{item.discount}</td>
                        <td className="text-right">{item.tax}</td>
                        <td className="text-right">{item.price}</td>
                        <td
                          className="text-right"
                          style={{ textAlign: "right", paddingRight: 0 }}
                        >
                          {item.total}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}{" "}
                  <tr className="border-none">
                    <td className="border-none" colSpan={5}></td>
                    <td className="td_summary td_summary_title">
                      {t("order.pdf.table.subtotal")}
                    </td>
                    <td colSpan={2} className="text-right td_summary">
                      {currency}&nbsp;{subTotal ?? 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-none" colSpan={5}></td>
                    <td className="td_summary td_summary_title">
                      {t("order.pdf.table.additionalDiscount")}
                    </td>
                    <td colSpan={2} className="text-right td_summary">
                      {currency}&nbsp;{additionalDiscount}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-none" colSpan={5}></td>
                    <td className="td_summary td_summary_title">
                      {t("order.pdf.table.deliveryFee")}
                    </td>
                    <td colSpan={2} className="text-right td_summary">
                      {currency}&nbsp;{deliveryFee}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-none" colSpan={5}></td>
                    <td className="td_summary td_summary_title">
                      {t("order.pdf.table.additionalTax")}
                    </td>
                    <td colSpan={2} className="text-right td_summary">
                      {currency}&nbsp;{additionalTax}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-none" colSpan={5}></td>
                    <td className="td_summary td_summary_title">
                      {t("order.pdf.table.totalDiscount")}
                    </td>
                    <td colSpan={2} className="text-right td_summary">
                      {currency}&nbsp;{totalDiscount}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-none" colSpan={5}></td>
                    <td className="td_summary td_summary_title">
                      {t("order.pdf.table.totalTax")}
                    </td>
                    <td colSpan={2} className="text-right td_summary">
                      {currency}&nbsp;{totalTax}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-none" colSpan={5}></td>
                    <td className="td_summary td_summary_title grey">
                      {t("order.pdf.table.grandTotal")}
                    </td>
                    <td
                      colSpan={2}
                      className="text-right td_summary td_summary_title grey"
                    >
                      {currency}&nbsp;{grandTotalAmount}
                    </td>
                  </tr>
                  {paymentType === "SPLIT_PAYMENT" && (
                    <tr>
                      <td className="border-none" colSpan={5}></td>
                      <td className="td_summary td_summary_title grey">
                        {t("order.pdf.table.downPayment")}
                      </td>
                      <td
                        colSpan={2}
                        className="text-right td_summary td_summary_title grey"
                      >
                        {currency}&nbsp;{downPayment}
                      </td>
                    </tr>
                  )}
                  <tr className="issued-approved">
                    <td
                      colSpan={3}
                      style={{
                        fontSize: "14px",
                        borderBottom: "0.5px solid #101828",
                      }}
                      className="border-none td_summary td_summary_title"
                    >
                      {t("order.pdf.deliveryInfo.title")}
                    </td>
                    <td className="border-none" />
                    <td
                      colSpan={4}
                      style={{
                        fontSize: "14px",
                        borderBottom: "0.5px solid #101828",
                      }}
                      className="text-left border-none td_summary td_summary_title"
                    >
                      {t("order.pdf.remarks")}
                    </td>
                  </tr>
                  <tr className="issued-approved">
                    <td colSpan={3} className="border-none td_summary">
                      <span className="td_summary_title">
                        {t("order.pdf.deliveryInfo.driverName")}:
                      </span>
                      {driverName}
                      <br />
                      <span className="td_summary_title">
                        {t("order.pdf.deliveryInfo.deliveryDate")}:
                      </span>
                      {deliveryDate}
                      <br />
                      <span className="td_summary_title">
                        {t("order.pdf.deliveryInfo.shippingMethod")}:
                      </span>
                      {shippingMethod}
                      <br />
                      <span className="td_summary_title">
                        {t("order.pdf.deliveryInfo.vehicleNumber")}:
                      </span>
                      {vehicleNumber}
                      <br />
                      <span className="td_summary_title">
                        {t("order.pdf.deliveryInfo.trackingNumber")}:
                      </span>
                      {trackingNumber}
                      <br />
                    </td>
                    <td className="border-none" />
                    <td
                      colSpan={3}
                      className="text-left border-none td_summary"
                    >
                      <p
                        style={{
                          maxWidth: "275.5px",
                        }}
                      >
                        {remark}
                      </p>
                    </td>
                  </tr>
                  <tr className="issued-approved">
                    <td className="border-none td_summary td_summary_title">
                      {t("order.pdf.issuedBy")}
                    </td>
                    <td className="border-none" colSpan={4}></td>
                    <td className="text-left border-none td_summary td_summary_title">
                      {t("order.pdf.approvedBy")}
                    </td>
                    <td className="border-none"></td>
                  </tr>
                  <tr className="issued-approved">
                    <td
                      className="border-none td_summary td_summary_title"
                      style={{
                        fontWeight: "400",
                        paddingBottom: "72px",
                      }}
                    >
                      {issuedBy}
                    </td>
                    <td className="border-none" colSpan={4}></td>
                    <td
                      className="text-left border-none td_summary td_summary_title"
                      style={{
                        fontWeight: "400",
                        paddingBottom: "72px",
                      }}
                    >
                      {approvedBy}
                    </td>
                    <td className="border-none"></td>
                  </tr>
                  <tr className="issued-approved">
                    <td
                      style={{
                        border: "none",
                        borderBottom: "1px solid #D0D5DD",
                        width: "146px",
                      }}
                    ></td>
                    <td className="border-none" colSpan={4}></td>
                    <td
                      style={{
                        border: "none",
                        borderBottom: "1px solid #D0D5DD",
                        width: "146px",
                      }}
                    ></td>
                  </tr>
                  <tr className="issued-approved">
                    <td className="border-none td_summary td_summary_title date-label">
                      {t("order.pdf.date")}:
                    </td>
                    <td className="border-none" colSpan={4}></td>
                    <td className="text-left border-none td_summary td_summary_title date-label">
                      {t("order.pdf.date")}:
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              backgroundColor: "#499873",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <p
              style={{
                fontWeight: "500",
                fontSize: "10px",
                margin: "0",
                color: "#fff",
                textAlign: "center",
              }}
            >
              Powered by Linkz
            </p>
          </div>
        </div>
      </body>
    </html>
  );
};

export default OrderDetails;

function BankInfo({
  bankName,
  accountNo,
  accountHolderName,
}: Readonly<{
  bankName: string;
  accountHolderName: string;
  accountNo: string;
}>) {
  const { t } = useTranslation();
  return (
    <>
      <div className="subheader-1">
        <p
          className="subheader-1-bold"
          style={{
            width: "140px",
          }}
        >
          {t("order.pdf.details.bank")}
        </p>
        <p>{bankName}</p>
      </div>
      <div className="subheader-1">
        <p
          className="subheader-1-bold"
          style={{
            width: "140px",
          }}
        >
          {t("order.pdf.details.accNo")}
        </p>
        <p>{accountNo}</p>
      </div>
      <div className="subheader-1">
        <p
          className="subheader-1-bold"
          style={{
            width: "140px",
          }}
        >
          {t("order.pdf.details.accHolder")}
        </p>
        <p>{accountHolderName}</p>
      </div>
    </>
  );
}

function CustomerDetails({
  accountHolderName,
  isPurchaseOrder,
  customerName,
  customerMobile,
  customerEmail,
  bankName,
  accountNo,
  paymentTerm,
  customerAddress,
}: any) {
  const { t } = useTranslation();
  return (
    <div
      className=""
      style={{
        width: "50%",
      }}
    >
      <h2 className="subheader">
        {t(`order.pdf.${!isPurchaseOrder ? "buyerDetails" : "sellerDetails"}`)}
        {/* {!isPurchaseOrder ? "Buyer Details" : "Seller  Details"} */}
      </h2>
      <hr
        className="underline"
        style={{
          width: "232px",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "3px",
        }}
      >
        <div className="subheader-1">
          <p
            className="subheader-1-bold"
            style={{
              width: "140px",
            }}
          >
            {t("order.pdf.details.name")}
          </p>
          <p>{customerName}</p>
        </div>
        <div className="subheader-1">
          <p
            className="subheader-1-bold"
            style={{
              width: "140px",
            }}
          >
            {t("order.pdf.details.mobile")}
          </p>
          <p>{customerMobile}</p>
        </div>
        <div className="subheader-1">
          <p
            className="subheader-1-bold"
            style={{
              width: "140px",
            }}
          >
            {t("order.pdf.details.email")}
          </p>
          <p>{customerEmail}</p>
        </div>
        {isPurchaseOrder && (
          <BankInfo
            accountHolderName={accountHolderName}
            bankName={bankName}
            accountNo={accountNo}
          />
        )}
        <div className="subheader-1">
          <p
            className="subheader-1-bold"
            style={{
              width: "140px",
            }}
          >
            {t("order.pdf.details.paymentTerm")}
          </p>
          <p>{paymentTerm}</p>
        </div>
        <div className="subheader-1">
          <p
            className="subheader-1-bold"
            style={{
              width: "140px",
              flexShrink: 0,
            }}
          >
            {t("order.pdf.details.address")}
          </p>
          <p>{customerAddress}</p>
        </div>
      </div>
    </div>
  );
}

function CompanyDetails({
  isPurchaseOrder,
  companyName,
  companyMobile,
  companyEmail,
  bankName,
  accountNo,
  companyAddress,
  accountHolderName,
}: any) {
  const { t } = useTranslation();

  return (
    <div
      className=""
      style={{
        width: "50%",
      }}
    >
      <h2 className="subheader">
        {t(`order.pdf.${isPurchaseOrder ? "buyerDetails" : "sellerDetails"}`)}
        {/* {isPurchaseOrder ? "Buyer Details" : "Seller Details"} */}
      </h2>
      <hr
        className="underline"
        style={{
          width: "232px",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "3px",
        }}
      >
        <div className="subheader-1">
          <p
            className="subheader-1-bold"
            style={{
              width: "140px",
            }}
          >
            {t("order.pdf.details.name")}
          </p>
          <p>{companyName}</p>
        </div>
        <div className="subheader-1">
          <p
            className="subheader-1-bold"
            style={{
              width: "140px",
            }}
          >
            {t("order.pdf.details.mobile")}
          </p>
          <p>{companyMobile}</p>
        </div>
        <div className="subheader-1">
          <p
            className="subheader-1-bold"
            style={{
              width: "140px",
            }}
          >
            {t("order.pdf.details.email")}
          </p>
          <p>{companyEmail}</p>
        </div>
        {!isPurchaseOrder && (
          <BankInfo
            accountHolderName={accountHolderName}
            bankName={bankName}
            accountNo={accountNo}
          />
        )}
        <div className="subheader-1">
          <p
            className="subheader-1-bold"
            style={{
              width: "140px",
              flexShrink: 0,
            }}
          >
            {t("order.pdf.details.address")}
          </p>
          <p>{companyAddress}</p>
        </div>
      </div>
    </div>
  );
}
