import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import { InvitationType } from "../../services/account.service";
import { AuthService } from "../../services/auth.service";
import {
  countryMap,
  getCountryCodeFromCountryName,
} from "../../utils/constants";

const generalSchema = z.object({
  agreed: z.boolean().default(false),
  companyName: z.string(),
  country: z
    .object({
      code: z.string(),
      dial: z.string(),
      label: z.string(),
    })
    .optional(),
  fullName: z.string().min(1).max(100),
  mobile: z
    .string()
    .min(1, "Invalid mobile number")
    .max(100, "Invalid mobile number"),
  companyCountryCode: z.string(),
});
const invitationSchema = generalSchema.merge(
  z.object({
    companyName: generalSchema.shape.companyName.optional(),
    companyCountryCode: generalSchema.shape.companyCountryCode.optional(),
  })
);
const useContainer = () => {
  //#region ----- local states
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { pathname, state } = useLocation();
  const accessToken = state?.accessToken;
  const isSSORoute = pathname.includes("sso");
  const [urlSearchParams] = useSearchParams();
  const token = urlSearchParams.get("token");
  const schema = token ? invitationSchema : generalSchema;
  const [invitationType, setInvitationType] = useState<InvitationType | null>(
    null
  );
  const [hasBusinessProfile, setHasBusinessProfile] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    setError,
    formState: { errors, isValid },
    watch,
    handleSubmit,
    register,
    setValue,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const { mobile, country, fullName, companyName, companyCountryCode, agreed } =
    watch();
  const callbackUrl = urlSearchParams.get("callbackURL");
  const invitationTokenMutation = useMutation({
    mutationFn: AuthService.invitationTokenCheck,
    onSuccess: ({ data }) => {
      if (data.redirectURL) {
        navigate(data.redirectURL);
      }
      if (data.invitationType) {
        setInvitationType(data.invitationType);
        setHasBusinessProfile(data.hasBusinessProfile);
      }
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
      navigate("/signup");
    },
  });
  const mutation = useMutation({
    mutationFn: !token
      ? AuthService.signupSSO
      : invitationTokenMutation.data?.data.hasBusinessProfile
      ? AuthService.signupSSOWithRoleInvitation
      : invitationTokenMutation.data?.data.hasAccount
      ? AuthService.signupSSOWithoutRoleInvitation
      : invitationType === InvitationType.BUSINESS_CONNECTION_INVITATION
      ? AuthService.signupSSOBusinessConnectInvitation
      : AuthService.signupSSO,
    onSuccess: ({ data: { activation, created, token } }) => {
      if (!activation) {
        if (callbackUrl) {
          navigate(`/signin?callbackURL=${encodeURIComponent(callbackUrl)}`);
        } else {
          navigate("/signin");
        }
        return;
      }
      localStorage.setItem("phoneVerificationToken", token);
      localStorage.setItem("countryCode", country?.code.toString() || "");
      localStorage.setItem("mobile", mobile);
      if (created === true) {
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          if (callbackUrl) {
            navigate(`/verify?callbackURL=${encodeURIComponent(callbackUrl)}`);
          } else {
            navigate("/verify");
          }
        }, 4000);
      } else {
        if (callbackUrl) {
          navigate(`/verify?callbackURL=${encodeURIComponent(callbackUrl)}`);
        } else {
          navigate("/verify");
        }
      }
    },
    onError: (error: any) => {
      const errorMessage =
        error.response.data.message || "Something went wrong.";
      toast(
        (t) => (
          <div
            style={{
              width: "100%",
              opacity: t.visible ? 1 : 0,
              transition: "opacity 100ms ease-in-out",
            }}
          >
            <Typography
              textColor={"danger.softColor"}
              fontSize={"14px"}
              fontWeight={500}
              textAlign={"center"}
              py={"12px"}
            >
              {errorMessage}
            </Typography>
          </div>
        ),
        {
          style: {
            padding: 0,
            margin: 0,
          },
          position: "bottom-center",
          duration: 4000,
        }
      );
    },
  });

  //#region life cycle method
  useEffect(() => {
    if (isSSORoute && !accessToken) {
      navigate("/signup");
    }
  }, [isSSORoute, accessToken]);

  useEffect(() => {
    if (companyCountryCode) {
      const countryCode: any = countryMap[companyCountryCode];
      if (countryCode) {
        setValue("country", countryCode);
      }
    }
  }, [companyCountryCode]);

  useEffect(() => {
    if (country?.code) {
      const countryName: any = getCountryCodeFromCountryName[country.code];
      if (countryName) {
        setValue("companyCountryCode", countryName);
      }
    }
  }, [country?.code]);
  //#endregion

  //#region ----- handler functions
  const onSubmit: SubmitHandler<z.infer<typeof schema>> = (data) => {
    let payload: any = {};
    if (invitationTokenMutation.data?.data.hasBusinessProfile) {
      payload = {
        fullName: data.fullName,
        companyName: data.companyName,
        localPhoneNumber: data.mobile,
        countryCode: data.country!.code,
        accessToken,
        companyCountryCode: data.companyCountryCode,
        invitationToken: token,
      };
    } else {
      payload = {
        fullName: data.fullName,
        companyName: data.companyName,
        localPhoneNumber: data.mobile,
        countryCode: data.country!.code,
        accessToken,
        companyCountryCode: data.companyCountryCode,
      };
      if (token) {
        payload["invitationToken"] = token;
      }
      if (invitationType === InvitationType.BUSINESS_CONNECTION_INVITATION) {
        payload.token = token;
      }
    }

    if (payload.localPhoneNumber.startsWith("0")) {
      payload.localPhoneNumber = payload.localPhoneNumber.replace(/^0+/, "");
    }
    mutation.mutate(payload);
  };

  //#region life cycle method
  useEffect(() => {
    if (token) {
      invitationTokenMutation.mutate({ token });
    }
  }, [token]);

  //#endregion

  //#endregion
  return {
    setError,
    handleSubmit,
    onSubmit,
    register,
    errors,
    control,
    setValue,
    isLoading: mutation.isPending,
    fullName,
    showModal,
    companyName,
    mobile,
    isSSORoute,
    companyCountryCode,
    hasBusinessProfile,
    setShowTermsModal,
    showPrivacyPolicy,
    setShowPrivacyPolicy,
    showTermsModal,
    agreed,
    isValid,
    phoneNo: `${country?.code ?? ""}${country?.dial ?? ""}`,
  };
};

export default useContainer;
