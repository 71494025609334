import { privateAxios, publicAxios } from "../lib/api";
import { IOrderInitiatePayload, IOrderSavePayload } from "../types/order";
import { ApiConstants } from "../utils/constants";

export class OrderService {
  static async save(payload: IOrderSavePayload) {
    return await privateAxios.post(ApiConstants.orderURL, payload);
  }

  static async update(orderId: string | undefined, payload: IOrderSavePayload) {
    const response = await privateAxios.put(
      `${ApiConstants.orderURL}/${orderId}`,
      payload,
      {
        params: payload.paymentMethod && {
          paymentMethod: payload.paymentMethod,
        },
      }
    );
    return response.data;
  }

  static async updateOrderStatus(
    orderId: string | undefined,
    orderStatus: string
  ) {
    const response = await privateAxios.put(
      `${ApiConstants.orderURL}/${orderId}/status`,
      { orderStatus: orderStatus }
    );
    return response.data;
  }

  static async updateAdjustment(
    revisionId: string,
    payload: any,
    isMain: boolean
  ) {
    return await privateAxios.patch(
      `${ApiConstants.orderURL}/adjustment/${revisionId}`,
      { updateOrderDto: payload, isMain }
    );
  }

  static async initiateDraft(payload: IOrderInitiatePayload) {
    return await privateAxios.post(ApiConstants.orderURL, payload);
  }

  static async createAdjustment(
    orderId: string,
    payload: any,
    isMain: boolean
  ) {
    return await privateAxios.post(
      `${ApiConstants.orderURL}/adjustment/${orderId}`,
      { updateOrderDto: payload, isMain }
    );
  }

  static async getOrderList() {
    return await privateAxios.get(ApiConstants.orderURL);
  }

  static async getOrderListByBusinessId({
    businessId,
    searchTerm,
    hasPagination = false,
    limit = 10,
    offset = 0,
  }: {
    businessId: string | undefined;
    searchTerm?: string;
    hasPagination?: boolean;
    limit?: number;
    offset?: number;
  }) {
    const response = await privateAxios.get(
      `${ApiConstants.orderURL}/${businessId}/list`,
      {
        params: {
          hasPagination,
          searchTerm,
          limit,
          offset,
        },
      }
    );
    return response.data;
  }

  static async getOutstandingOrderListPublic({
    sellerBusinessId,
    buyerBusinessId,
    searchTerm,
    hasPagination,
    limit,
    offset,
  }: any) {
    const response = await publicAxios.get(
      `${ApiConstants.orderURL}/public/outstanding`,
      {
        params: {
          sellerBusinessId,
          buyerBusinessId,
          searchTerm,
          hasPagination,
          limit,
          offset,
        },
      }
    );
    return response.data;
  }

  static async getOrder(orderId: string | undefined): Promise<any> {
    const response = await privateAxios.get(
      `${ApiConstants.orderURL}/${orderId}`
    );
    return response.data;
  }

  static async getOrderPublic(orderId: string | undefined): Promise<any> {
    const response = await publicAxios.get(
      `${ApiConstants.orderURL}/public/detail/${orderId}`
    );
    return response.data;
  }

  static async getOrderPDFContent(orderId: string | undefined): Promise<any> {
    const response = await privateAxios.get(
      `${ApiConstants.orderURL}/${orderId}/pdf-content`
    );
    return response.data;
  }

  static async getOrderPdf(orderId: string | undefined): Promise<any> {
    const response = await privateAxios.get(
      `${ApiConstants.pdfURL}/order/${orderId}`,
      { responseType: "blob" }
    );
    return response.data;
  }

  static async duplicateOrder(orderId: string | undefined): Promise<any> {
    const response = await privateAxios.post(
      `${ApiConstants.orderURL}/${orderId}/duplicate`
    );
    return response.data;
  }

  static async deleteOrder(orderId: string | undefined): Promise<any> {
    const isDeleted = await privateAxios.delete(
      `${ApiConstants.orderURL}/${orderId}`
    );
    return isDeleted;
  }

  static async cancelOrder(orderId: string | undefined): Promise<any> {
    const response = await privateAxios.patch(
      `${ApiConstants.orderURL}/${orderId}/cancel`
    );
    return response.data;
  }

  static async analyticData(startDate: string, endDate: string, type: string) {
    const response = await privateAxios.get(
      `${ApiConstants.DashboardAnalyticURL}?startDate=${startDate}&endDate=${endDate}&filterType=${type}`
    );
    return {
      saleData: response.data.sales.map((sale: any) => sale.total_sales),
      purchaseData: response.data.purchases.map(
        (sale: any) => sale.total_sales
      ),
    };
  }

  static async sendOutStandingOrderReminder(
    buyerAccountId: string,
    buyerBusinessId: string
  ) {
    const response = await privateAxios.post(
      `${ApiConstants.orderURL}/outstanding/reminder`,
      {
        buyerAccountId: buyerAccountId,
        buyerBusinessId: buyerBusinessId,
      }
    );
    return response.data;
  }
}
