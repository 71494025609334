import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { OrderService } from "../../../services/order.service";

const useContainer = () => {
  //#region local states
  const [xLabels, setXLabels] = useState([
    "1 jan - 7 jan",
    "8 jan -14 jan",
    "15 jan - 21 jan",
    "22 jan - 28 jan",
    "29 jan - 4 feb",
    "5 feb - 11 feb",
    "12 feb - 18 feb",
  ]);

  const [state, setState] = useState({
    sale: true,
    purchase: true,
    profit: true,
  });
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
    type: "",
  });
  //#endregion

  //#region ----- Http calls
  const analyticDataQuery = useQuery({
    queryKey: ["analytic-data", dateRange.startDate, dateRange.endDate],
    queryFn: () =>
      OrderService.analyticData(
        dateRange.startDate,
        dateRange.endDate,
        dateRange.type
      ),
    enabled: !!dateRange.startDate && !!dateRange.endDate,
  });

  //#region ----- handler functions
  const calculateDateRange = (value: string) => {
    const endDate = new Date();
    let startDate;

    switch (value) {
      case "last-7-days":
        startDate = new Date();
        startDate.setDate(endDate.getDate() - 7);
        break;
      case "last-7-weeks":
        startDate = new Date();
        startDate.setDate(endDate.getDate() - 7 * 7);
        break;
      case "last-7-months":
        startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 6);
        break;
      default:
        return;
    }

    setDateRange({
      type: value,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
  };
  const handleSelectChange = (_event: any, value: any) => {
    calculateDateRange(value);
    generateXLabels(value);
  };

  const handleChange = (key: string, value: boolean) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  //#region lifecycle methods
  const analyticData = analyticDataQuery?.data
    ? {
        ...analyticDataQuery?.data,
        profitData: analyticDataQuery.data.saleData.map(
          (sale: number, index: number) =>
            sale - analyticDataQuery.data.purchaseData[index]
        ),
      }
    : {
        saleData: [],
        purchaseData: [],
        profitData: [],
      };
  const calculateTotal = (data: Array<number>) =>
    data.reduce((acc, item) => acc + item, 0);

  const generateXLabels = (option: string) => {
    const currentDate = new Date();
    const xLabels = [];

    switch (option) {
      case "last-7-days":
        for (let i = 6; i >= 0; i--) {
          const date = new Date(currentDate);
          date.setDate(date.getDate() - i);
          const label = `${date.getDate()} ${date.toLocaleString("default", {
            month: "long",
          })}`;
          xLabels.push(label);
        }
        break;
      case "last-7-weeks":
        for (let i = 0; i < 7; i++) {
          const startDate = new Date(currentDate);
          startDate.setDate(currentDate.getDate() - i * 7 - 6);
          const endDate = new Date(currentDate);
          endDate.setDate(currentDate.getDate() - i * 7);
          const label = `${startDate.getDate()} ${startDate.toLocaleString(
            "default",
            { month: "short" }
          )} - ${endDate.getDate()} ${endDate.toLocaleString("default", {
            month: "short",
          })}`;
          xLabels.unshift(label); // prepend to keep order from past to present
        }
        break;
      case "last-7-months":
        for (let i = 5; i >= 0; i--) {
          const startDate = new Date(currentDate);
          startDate.setMonth(startDate.getMonth() - i);
          startDate.setDate(1); // Set to first day of the month
          const endDate = new Date(startDate);
          endDate.setMonth(endDate.getMonth() + 1);
          endDate.setDate(0); // Set to last day of the month
          const label = `${startDate.toLocaleString("default", {
            month: "long",
          })}`;
          xLabels.push(label); // push to keep order from past to present
        }
        break;
      default:
        break;
    }
    setXLabels(xLabels);
    // return xLabels;
  };

  // Use useMemo to memoize the calculation of totals
  const totals = useMemo(() => {
    return {
      saleTotal: calculateTotal(analyticData.saleData),
      purchaseTotal: calculateTotal(analyticData.purchaseData),
      profitTotal: calculateTotal(analyticData.profitData),
    };
  }, [analyticData]);

  useEffect(() => {
    if (dateRange.startDate === "" && dateRange.endDate === "") {
      calculateDateRange("last-7-days");
      generateXLabels("last-7-days");
    }
  }, []);
  //#endregion

  return {
    isLoading: false, // analyticDataQuery.isPending,
    data: analyticData,
    handleSelectChange,
    handleChange,
    calculateDateRange,
    dateRange,
    totals,
    state,
    xLabels,
  };
};

export default useContainer;
