import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { Divider } from "@mui/material";
import startsWith from "lodash.startswith";
import React from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { If } from "../../../../components/Condition";
import { countries } from "../../../../components/Countries";
import FlagById from "../../../../components/FlagById";
import LoadingModal from "../../../../components/LoadingModal/LoadingModal";
import { SuccessModal } from "../../../../components/SuccessModal";
import { BusinessSessionHelper } from "../../../../helpers/business-session.helper";
import { animateSelectStyle } from "../../../../styles/select";
import {
  PhNoWhiteListedCountries,
  WhiteListedCountries,
} from "../../../../utils/constants";
import { matchesFormat } from "../../../../utils/misc";
import { currencyList } from "../../../Order/EditOrders";
import { useContactFormContainer } from "./contactFormContainer";

export default function ContactForm(props: {
  editMode?: boolean;
  onSuccess?: (data: any) => void;
  onCancel?: () => void;
}) {
  const { t } = useTranslation();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    errors,
    onSubmit,
    onSearching,
    searchContactByEmail,
    businessOptions,
    handleSetBusinessData,
    setError,
    success,
    loading,
    navigate,
    getValues,
  } = useContactFormContainer({
    editMode: props.editMode,
    onSuccess: props.onSuccess,
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2} sx={{ padding: 2 }}>
          <FormControl error={!!errors.contactBusinessAccountId}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              placeholder={"Email"}
              sx={{ backgroundColor: "white" }}
              value={watch("contactEmail")}
              defaultValue={getValues("contactEmail")}
              disabled={props.editMode}
              onChange={(e) => {
                if (!props.editMode) {
                  searchContactByEmail(e.target.value);
                }
              }}
            />
            {errors.contactBusinessAccountId?.message && (
              <FormHelperText>
                {errors.contactBusinessAccountId?.message}
              </FormHelperText>
            )}
            {onSearching && (
              <Typography>Searching contact to add...</Typography>
            )}
          </FormControl>
          <FormControl error={!!errors.companyName}>
            <FormLabel>{t("profile.business.companyName")}</FormLabel>
            {businessOptions?.length > 1 ? (
              <Select
                placeholder={t("profile.business.companyName")}
                indicator={<KeyboardArrowDown />}
                onChange={(_, value) => {
                  if (value)
                    handleSetBusinessData(
                      businessOptions.find((b: any) => b.id === value)
                    );
                }}
                renderValue={(option: any) => (
                  <Typography>{option?.label}</Typography>
                )}
                sx={{ ...animateSelectStyle, backgroundColor: "white" }}
              >
                {businessOptions.map((business: any) => (
                  <Option
                    key={business.id}
                    value={business.id}
                    label={business.companyName}
                  >
                    <Typography>{business.companyName}</Typography>
                  </Option>
                ))}
              </Select>
            ) : (
              <Input
                placeholder={t("profile.business.companyName")}
                sx={{ backgroundColor: "white" }}
                {...register("companyName")}
              />
            )}
            {errors.companyName?.message && (
              <FormHelperText>{errors.companyName?.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={!!errors.nameOfContact}>
            <FormLabel>{t("profile.business.contactName")}</FormLabel>
            <Input
              placeholder={t("profile.business.contactName")}
              sx={{ backgroundColor: "white" }}
              {...register("nameOfContact")}
            />
            {errors.nameOfContact?.message && (
              <FormHelperText>{errors.nameOfContact?.message}</FormHelperText>
            )}
          </FormControl>
          <Box
            flexDirection={{
              xs: "column",
              md: "row",
            }}
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <FormControl error={!!errors.companyCountry}>
              <FormLabel>{t("profile.business.country")}</FormLabel>

              <Select
                placeholder={t("profile.business.country")}
                indicator={<KeyboardArrowDown />}
                onChange={(_, value) => {
                  if (value) setValue("companyCountry", value);
                }}
                renderValue={(option) => (
                  <Typography
                    startDecorator={<FlagById id={`${option?.value ?? ""}`} />}
                  >
                    {option?.label}
                  </Typography>
                )}
                value={watch("companyCountry")}
                sx={{ ...animateSelectStyle, backgroundColor: "white" }}
              >
                {countries
                  .filter((country) =>
                    WhiteListedCountries.includes(country.code)
                  )
                  .map((country) => (
                    <Option
                      key={country.code}
                      value={country.code}
                      label={country.label}
                    >
                      <Typography
                        startDecorator={<FlagById id={country.code} />}
                      >
                        {country.label}
                      </Typography>
                    </Option>
                  ))}
              </Select>
              {errors.companyCountry?.message && (
                <FormHelperText>
                  {errors.companyCountry?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ flex: "1" }} error={!!errors.companyNumber}>
              <FormLabel>{t("profile.business.companyNumber")}</FormLabel>
              <PhoneInput
                value={
                  (watch("companyNumber") &&
                    `+${watch("companyNumberCountryCode")}${watch(
                      "companyNumber"
                    )}`) ||
                  ""
                }
                onlyCountries={PhNoWhiteListedCountries}
                enableTerritories={false}
                masks={{
                  my: "..-....-....",
                  sg: "....-....",
                  th: "... ... ...",
                  id: "... ... ... ...",
                }}
                isValid={(inputNumber, _: any, countries: any) => {
                  if (inputNumber === "") {
                    return true;
                  }
                  const result = (countries || []).some((country: any) => {
                    return (
                      (startsWith(inputNumber, country.dialCode) ||
                        startsWith(country.dialCode, inputNumber)) &&
                      matchesFormat(
                        inputNumber,
                        country.format,
                        country.countryCode
                      )
                    );
                  });
                  if (!result && errors.companyNumber?.message === null) {
                    setError("companyNumber", {
                      message: "invalid mobile number.",
                    });
                  }
                  return result;
                }}
                inputStyle={{
                  height: "50px",
                  width: "100%",
                  fontSize: "16px",
                }}
                onChange={(phone, meta: any) => {
                  setValue("companyNumberCountryCode", meta.dialCode);
                  setValue(
                    "companyNumber",
                    phone.replace(new RegExp(meta.dialCode), "")
                  );
                }}
              />
              {errors.companyName?.message && (
                <FormHelperText>{errors.companyName?.message}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <FormControl sx={{ flex: 3 }} error={!!errors.companyAddress}>
            <FormLabel>{t("profile.business.address")}</FormLabel>
            <Input
              placeholder={t("profile.business.address")}
              sx={{ backgroundColor: "white" }}
              {...register("companyAddress")}
            />
            {errors.companyAddress?.message && (
              <FormHelperText>{errors.companyAddress?.message}</FormHelperText>
            )}
          </FormControl>
          <Box
            flexDirection={{
              xs: "column",
              md: "row",
            }}
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <FormControl sx={{ flex: 1 }} error={!!errors.companyProvince}>
              <FormLabel>{t("profile.business.province")}</FormLabel>
              <Input
                placeholder={t("profile.business.province")}
                sx={{ backgroundColor: "white" }}
                {...register("companyProvince")}
              />
              {errors.companyProvince?.message && (
                <FormHelperText>
                  {errors.companyProvince?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ flex: 1 }} error={!!errors.companyPostalCode}>
              <FormLabel>{t("profile.business.postalCode")}</FormLabel>
              <Input
                placeholder={t("profile.business.postalCode")}
                sx={{ backgroundColor: "white" }}
                {...register("companyPostalCode")}
              />
              {errors.companyPostalCode?.message && (
                <FormHelperText>
                  {errors.companyPostalCode?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <FormControl sx={{ flex: 1 }} error={!!errors.companyPaymentTerms}>
            <FormLabel>{t("profile.business.paymentTerms")}</FormLabel>
            <Input
              placeholder={t("profile.business.paymentTerms")}
              sx={{ backgroundColor: "white" }}
              {...register("companyPaymentTerms")}
            />
            {errors.companyPaymentTerms?.message && (
              <FormHelperText>
                {errors.companyPaymentTerms?.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            sx={{ flex: 1 }}
            error={
              !!errors.companyCreditLimit || !!errors.companyCreditLimitCurrency
            }
          >
            <FormLabel>{t("profile.business.companyCreditLimit")}</FormLabel>
            <Input
              type={"number"}
              placeholder={t("profile.business.companyCreditLimit")}
              sx={{ backgroundColor: "white" }}
              defaultValue={0}
              value={watch("companyCreditLimit") ?? 0}
              onChange={(event) => {
                setValue(
                  "companyCreditLimit",
                  parseInt(event.target.value) ?? 0
                );
              }}
              startDecorator={
                <React.Fragment>
                  <Select
                    variant="plain"
                    defaultValue={""}
                    value={watch("companyCreditLimitCurrency") ?? ""}
                    indicator={<KeyboardArrowDown />}
                    onChange={(_, value) =>
                      setValue("companyCreditLimitCurrency", value ?? "IDR")
                    }
                    sx={{ ...animateSelectStyle, paddingLeft: 0 }}
                  >
                    {currencyList.map((c) => {
                      return (
                        <Option id={c} value={c}>
                          {c}
                        </Option>
                      );
                    })}
                  </Select>
                  <Divider orientation="vertical" />
                </React.Fragment>
              }
            />
            {(errors.companyCreditLimit?.message ||
              errors.companyCreditLimitCurrency?.message) && (
              <FormHelperText>
                {errors.companyCreditLimit?.message ||
                  errors.companyCreditLimitCurrency?.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ flex: 1 }} error={!!errors.companyBankName}>
            <FormLabel>{t("profile.business.companyBankName")}</FormLabel>
            <Input
              placeholder={t("profile.business.companyBankName")}
              sx={{ backgroundColor: "white" }}
              {...register("companyBankName")}
            />
            {errors.companyBankName?.message && (
              <FormHelperText>{errors.companyBankName?.message}</FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ flex: 1 }} error={!!errors.companyBankAccountNo}>
            <FormLabel>{t("profile.business.companyBankAccountNo")}</FormLabel>
            <Input
              placeholder={t("profile.business.companyBankAccountNo")}
              sx={{ backgroundColor: "white" }}
              {...register("companyBankAccountNo")}
            />
            {errors.companyBankAccountNo?.message && (
              <FormHelperText>
                {errors.companyBankAccountNo?.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            sx={{ flex: 1 }}
            error={!!errors.companyBankAccountHolderName}
          >
            <FormLabel>
              {t("profile.business.companyBankAccountHolderName")}
            </FormLabel>
            <Input
              placeholder={t("profile.business.companyBankAccountHolderName")}
              sx={{ backgroundColor: "white" }}
              {...register("companyBankAccountHolderName")}
            />
            {errors.companyBankAccountHolderName?.message && (
              <FormHelperText>
                {errors.companyBankAccountHolderName?.message}
              </FormHelperText>
            )}
          </FormControl>
          <br />
          <If condition={!props.editMode || BusinessSessionHelper.IsOwner()}>
            <Box
              flexDirection={{
                xs: "column",
                md: "row",
              }}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="plain"
                sx={{
                  fontSize: "16px",
                }}
                onClick={() =>
                  props.onCancel
                    ? props.onCancel()
                    : navigate("/client/business-contact")
                }
              >
                {t("commons.cancelBtn")}
              </Button>
              <Button
                type="submit"
                sx={{
                  fontSize: "16px",
                }}
              >
                {t("manage.businessContact.saveContactBtn")}
              </Button>
            </Box>
          </If>
        </Stack>
      </form>
      <LoadingModal isLoading={loading} />
      <SuccessModal
        open={success}
        title="Success"
        content="Your contact has been saved successfully."
      />
    </>
  );
}
