import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { FormControl, FormLabel, Grid, Stack } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import Divider from "@mui/joy/Divider";
import FormHelperText from "@mui/joy/FormHelperText";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import startsWith from "lodash.startswith";
import ReactFlagsSelect from "react-flags-select";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link as RouterLink } from "react-router-dom";
import { If } from "../../components/Condition";
import Gauth from "../../components/Gauth";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import Password from "../../components/PasswordPolicy/PasswordPolicy";
import { SuccessModal } from "../../components/SuccessModal";
import Shared from "../../layouts/Shared";
import {
  CountryCode,
  PhNoFormat,
  PhNoWhiteListedCountries,
  WhiteListedCountries,
  privacyPolicyTitle,
  termsTitle,
} from "../../utils/constants";
import { matchesFormat } from "../../utils/misc";
import TermsAndPoliciesModal from "./components/TermsAndPoliciesModal";
import useContainer from "./useContainer";

export default function Signup() {
  const { t } = useTranslation();

  const {
    agreed,
    isValid,
    phoneNo,
    privacyPolicy,
    setPrivacyPolicy,
    setError,
    googleSignUpHandler,
    showModal,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    showPassword,
    toggleShowPassword,
    password,
    isLoading,
    fullName,
    companyName,
    email,
    mobile,
    countryName,
    alrdyHasBusiness,
    token,
    showTermsModal,
    setShowTermsModal,
  } = useContainer();
  return (
    <>
      <Shared
        sxProps={{
          backgroundColor: "transparent",
        }}
        minWidth={"329px"}
        width={{
          md: "640px",
        }}
      >
        <Helmet
          link={[
            { href: "https://linkzasia.com/auth/register", rel: "canonical" },
          ]}
          meta={[{ content: "Register", name: "description" }]}
          title="Register - Linkz"
        />
        <Stack flex={1}>
          <Typography
            textColor={"primary.solidActiveBg"}
            textAlign={"center"}
            fontSize={"18"}
            fontWeight={600}
            py={2}
            mt={3}
            mb={2}
          >
            {t("landing.createAccountLabel")}
          </Typography>
          <Box
            component="section"
            data-testid="register"
            id="register"
            padding={2}
            paddingX={4}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                spacing={2}
                alignItems={"start"}
                justifyContent={"center"}
              >
                <Grid xs={12}>
                  <FormControl error={!!errors.fullName}>
                    <FormLabel>
                      <Typography
                        className={`transition-opacity duration-300 ${fullName ? "opacity-100 h-auto" : "opacity-0 h-0"
                          }`}
                        fontSize={"sm"}
                        fontWeight={500}
                        textColor={"text.secondary"}
                      >
                        {t("landing.signup.fullName")}
                      </Typography>
                    </FormLabel>
                    <Input
                      placeholder={t("landing.signup.fullName")}
                      size="lg"
                      {...register("fullName")}
                    />
                    {errors.fullName?.message ? (
                      <FormHelperText>
                        {errors.fullName?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <If
                  condition={(!!token && !alrdyHasBusiness) || !!!token}
                  children={
                    <Grid xs={12}>
                      <FormControl error={!!errors.companyName}>
                        <FormLabel>
                          <Typography
                            className={`transition-opacity duration-300 ${companyName
                              ? "opacity-100 h-auto"
                              : "opacity-0 h-0"
                              }`}
                            fontSize={"sm"}
                            fontWeight={500}
                            textColor={"text.secondary"}
                          >
                            {t("landing.signup.companyName")}
                          </Typography>
                        </FormLabel>
                        <Input
                          placeholder={t("landing.signup.companyName")}
                          size="lg"
                          {...register("companyName")}
                        />
                        {errors.companyName?.message ? (
                          <FormHelperText>
                            {errors.companyName?.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  }
                />
                <If
                  condition={(!!token && !alrdyHasBusiness) || !!!token}
                  children={
                    <Grid xs={12}>
                      <FormControl error={!!errors.countryName}>
                        <FormLabel>
                          <Typography
                            className={`transition-opacity duration-300 ${countryName
                              ? "opacity-100 h-auto"
                              : "opacity-0 h-0"
                              }`}
                            fontSize={"sm"}
                            fontWeight={500}
                            textColor={"text.secondary"}
                          >
                            {t("landing.signup.country")}
                          </Typography>
                        </FormLabel>
                        <ReactFlagsSelect
                          id={"select-flag"}
                          placeholder={t("landing.signup.country")}
                          selected={countryName ?? ""}
                          onSelect={(code) => setValue("countryName", code)}
                          countries={WhiteListedCountries}
                        />

                        {errors.countryName?.message ? (
                          <FormHelperText>
                            {errors.countryName?.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                  }
                />
                <Grid xs={12}>
                  <FormControl error={errors.mobile ? true : false}>
                    <FormLabel>
                      <Typography
                        fontSize={"sm"}
                        fontWeight={500}
                        className={`transition-opacity duration-300 ${mobile ? "opacity-100 h-auto" : "opacity-0 h-0"
                          }`}
                        textColor={"text.secondary"}
                      >
                        {t("landing.signup.phoneNumber")}
                      </Typography>
                    </FormLabel>
                    <PhoneInput
                      inputProps={{
                        name: "input-phone",
                      }}
                      value={phoneNo}
                      onlyCountries={PhNoWhiteListedCountries}
                      masks={{
                        my: "..-....-....",
                        sg: "....-....",
                        th: "... ... ...",
                        id: "... ... ... ...",
                      }}
                      enableTerritories={false}
                      isValid={(inputNumber, meta: any, countries) => {
                        if (inputNumber === "") {
                          return true;
                        }
                        const result = (countries || []).some(
                          (country: any) => {
                            if (
                              startsWith(inputNumber, country.dialCode) ||
                              startsWith(country.dialCode, inputNumber)
                            ) {
                              const iso2: CountryCode =
                                meta.iso2 as CountryCode;
                              const format = PhNoFormat[iso2];
                              if (!format) return false;
                              const isMatch = matchesFormat(
                                inputNumber,
                                format,
                                country.countryCode
                              );
                              return isMatch;
                            }
                            return false;
                          }
                        );

                        if (!result && !!!errors.mobile?.message) {
                          setError("mobile", {
                            message: "invalid mobile number.",
                          });
                        } else if (result && !!errors.mobile?.message) {
                          setError("mobile", {
                            message: undefined,
                          });
                        }
                        return result;
                      }}
                      inputStyle={{
                        height: "42px",
                        width: "100%",
                        fontSize: "16px",
                      }}
                      onChange={(phone, meta: any) => {
                        console.log({ phone, meta });
                        setValue("country.code", meta.dialCode);
                        setValue("country.label", meta.name);
                        setValue(
                          "country.dial",
                          phone.replace(new RegExp(meta.dialCode), "")
                        );
                        setValue(
                          "mobile",
                          phone.replace(new RegExp(meta.dialCode), "")
                        );
                      }}
                    />

                    {errors.mobile?.message ? (
                      <FormHelperText>{errors.mobile?.message}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid xs={12}>
                  <FormControl error={errors.email ? true : false}>
                    <FormLabel>
                      <Typography
                        className={`transition-opacity duration-300 ${email ? "opacity-100 h-auto" : "opacity-0 h-0"
                          }`}
                        fontSize={"sm"}
                        fontWeight={500}
                        textColor={"text.secondary"}
                      >
                        {t("landing.signup.email")}
                      </Typography>
                    </FormLabel>
                    <Input
                      disabled={!!token}
                      placeholder={t("landing.signup.email")}
                      required
                      size="lg"
                      type="email"
                      {...register("email")}
                    />
                    {errors.email?.message ? (
                      <p>{errors.email?.message}</p>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid xs={12}>
                  <FormControl error={!!errors.password}>
                    <FormLabel>
                      <Typography
                        className={`transition-opacity duration-300 ${password ? "opacity-100 h-auto" : "opacity-0 h-0"
                          }`}
                        fontSize={"sm"}
                        fontWeight={500}
                        textColor={"text.secondary"}
                      >
                        {t("landing.signup.password")}
                      </Typography>
                    </FormLabel>
                    <Input
                      endDecorator={
                        <IconButton onClick={toggleShowPassword}>
                          {showPassword ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <VisibilityOutlinedIcon />
                          )}
                        </IconButton>
                      }
                      placeholder={t("landing.signup.password")}
                      required
                      size="lg"
                      type={showPassword ? "text" : "password"}
                      {...register("password")}
                    />
                    {errors.password?.message ? (
                      <FormHelperText>
                        {errors.password?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid xs={12}>
                  <Password value={password} />
                </Grid>
                <Grid xs={12} width={"fit-content"}>
                  <FormControl size="md">
                    <div className="flex items-center w-full gap-3">
                      <Checkbox {...register("agreed")} required />
                      <p
                        style={{ margin: 0 }}
                      // className="flex items-center ml-3"
                      >
                        {t("landing.signup.acceptTerms")}
                        <span
                          // onClick={() => setShowTermsModal(true)}
                          style={{
                            margin: "0 3px",
                            textDecoration: "none",
                          }}
                        >
                          <a href={`${import.meta.env.VITE_PP_URL ?? '#'}`} target="_blank">
                            <span className={"text-[#499873] cursor-pointer"}>
                              {t("landing.signup.terms")}
                            </span>
                          </a>
                        </span>
                        &
                        <span
                          // onClick={() => setPrivacyPolicy(true)}
                          style={{
                            marginLeft: "3px",
                            textDecoration: "none",
                          }}
                        >
                          <a href={`${import.meta.env.VITE_PP_URL ?? '#'}`} target="_blank">
                            <span className={"text-[#499873] cursor-pointer"}>
                              {t("landing.signup.privacy")}
                            </span>
                          </a>
                        </span>
                        .
                      </p>
                    </div>
                  </FormControl>
                </Grid>
                <Grid xs={12} textAlign={"center"}>
                  <Button
                    id="btn-create"
                    className=""
                    sx={{ maxWidth: "352px" }}
                    fullWidth
                    size="lg"
                    type="submit"
                    disabled={isLoading || !isValid || !agreed}
                  >
                    {t("commons.createBtn")}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Divider sx={{ my: 2 }}>{t("landing.or")}</Divider>
            <Box
              onClick={googleSignUpHandler}
              sx={{ maxWidth: "352px", margin: "auto" }}
            >
              <Gauth />
            </Box>
          </Box>
          <Stack justifyContent={"center"} paddingBottom={5} flex={1}>
            <Typography fontSize="sm" mt={3} textAlign={"center"}>
              {t("landing.signup.alreadyHaveAccount")}
              <Link
                component={RouterLink}
                fontSize="sm"
                marginLeft={1}
                to={"/signin"}
              >
                {t("landing.loginBtn")}
              </Link>
            </Typography>
          </Stack>
        </Stack>
        <SuccessModal
          title={t("commons.successModalTitle")}
          content={t("landing.signup.accountCreated")}
          open={showModal}
        />
      </Shared>
      <LoadingModal isLoading={isLoading} />
      <TermsAndPoliciesModal
        title={t(termsTitle)}
        content={"t&c"}
        isOpen={showTermsModal}
        close={() => setShowTermsModal(false)}
      />
      <TermsAndPoliciesModal
        title={t(privacyPolicyTitle)}
        content={"privacyPolicy"}
        isOpen={privacyPolicy}
        close={() => setPrivacyPolicy(false)}
      />
    </>
  );
}
