import { FormControl, FormLabel, Stack } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import startsWith from "lodash.startswith";
import ReactFlagsSelect from "react-flags-select";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { SuccessModal } from "../../components/SuccessModal";
import Shared from "../../layouts/Shared";
import {
  CountryCode,
  PhNoFormat,
  PhNoWhiteListedCountries,
  WhiteListedCountries,
  privacyPolicyTitle,
  termsTitle,
} from "../../utils/constants";
import { matchesFormat } from "../../utils/misc";
import TermsAndPoliciesModal from "../Signup/components/TermsAndPoliciesModal";
import useContainer from "./useContainer";

export default function SignupSSO() {
  const { t } = useTranslation();
  const {
    agreed,
    isValid,
    // googleSignUpHandler,
    setError,
    showModal,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    isLoading,
    fullName,
    companyName,
    mobile,
    companyCountryCode,
    hasBusinessProfile,
    setShowTermsModal,
    showPrivacyPolicy,
    setShowPrivacyPolicy,
    showTermsModal,
    phoneNo,
  } = useContainer();

  return (
    <>
      <Shared
        sxProps={{
          backgroundColor: "transparent",
        }}
        minWidth={"329px"}
        width={{
          md: "640px",
        }}
      >
        <Helmet
          link={[
            { href: "https://linkzasia.com/auth/register", rel: "canonical" },
          ]}
          meta={[{ content: "Register", name: "description" }]}
          title="Register - Linkz"
        />
        <Stack flex={1}>
          <Typography
            textColor={"primary.solidActiveBg"}
            textAlign={"center"}
            fontSize={"18"}
            fontWeight={600}
            py={2}
            mt={3}
            mb={2}
          >
            {t("landing.createAccountLabel")}
          </Typography>
          <Box
            component="section"
            data-testid="register"
            id="register"
            padding={2}
            paddingX={4}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-2">
                <FormControl error={!!errors.fullName}>
                  <FormLabel>
                    <Typography
                      className={`transition-opacity duration-300 ${
                        fullName ? "opacity-100 h-auto" : "opacity-0 h-0"
                      }`}
                      fontSize={"sm"}
                      fontWeight={500}
                      textColor={"text.secondary"}
                    >
                      {t("landing.signup.fullName")}
                    </Typography>
                  </FormLabel>
                  <Input
                    placeholder={t("landing.signup.fullName")}
                    size="lg"
                    {...register("fullName")}
                  />
                  {errors.fullName?.message ? (
                    <FormHelperText>{errors.fullName?.message}</FormHelperText>
                  ) : null}
                </FormControl>

                {!hasBusinessProfile && (
                  <FormControl error={!!errors.companyName}>
                    <FormLabel>
                      <Typography
                        className={`transition-opacity duration-300 ${
                          companyName ? "opacity-100 h-auto" : "opacity-0 h-0"
                        }`}
                        fontSize={"sm"}
                        fontWeight={500}
                        textColor={"text.secondary"}
                      >
                        {t("landing.signup.companyName")}
                      </Typography>
                    </FormLabel>
                    <Input
                      placeholder={t("landing.signup.companyName")}
                      size="lg"
                      {...register("companyName")}
                    />
                    {errors.companyName?.message ? (
                      <FormHelperText>
                        {errors.companyName?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
                <FormControl error={!!errors.companyCountryCode}>
                  <FormLabel>
                    <Typography
                      className={`transition-opacity duration-300 ${
                        companyCountryCode
                          ? "opacity-100 h-auto"
                          : "opacity-0 h-0"
                      }`}
                      fontSize={"sm"}
                      fontWeight={500}
                      textColor={"text.secondary"}
                    >
                      {t("landing.signup.country")}
                    </Typography>
                  </FormLabel>
                  <ReactFlagsSelect
                    placeholder={t("landing.signup.country")}
                    selected={companyCountryCode ?? ""}
                    onSelect={(code) => {
                      setValue("companyCountryCode", code);
                    }}
                    countries={WhiteListedCountries}
                  />

                  {errors.companyCountryCode?.message ? (
                    <FormHelperText>
                      {errors.companyCountryCode?.message}
                    </FormHelperText>
                  ) : null}
                </FormControl>
                <FormControl error={errors.mobile ? true : false}>
                  <FormLabel>
                    <Typography
                      fontSize={"sm"}
                      fontWeight={500}
                      className={`transition-opacity duration-300 ${
                        mobile ? "opacity-100 h-auto" : "opacity-0 h-0"
                      }`}
                      textColor={"text.secondary"}
                    >
                      {t("landing.signup.phoneNumber")}
                    </Typography>
                  </FormLabel>
                  <PhoneInput
                    inputStyle={{
                      height: "42px",
                      width: "100%",
                      fontSize: "16px",
                    }}
                    masks={{
                      my: "..-....-....",
                      sg: "....-....",
                      th: "... ... ...",
                      id: "... ... ... ...",
                    }}
                    value={phoneNo}
                    onlyCountries={PhNoWhiteListedCountries}
                    isValid={(inputNumber, meta: any, countries) => {
                      if (inputNumber === "") {
                        return true;
                      }
                      const result = (countries || []).some((country: any) => {
                        if (
                          startsWith(inputNumber, country.dialCode) ||
                          startsWith(country.dialCode, inputNumber)
                        ) {
                          const iso2: CountryCode = meta.iso2 as CountryCode;
                          const format = PhNoFormat[iso2];
                          const isMatch = matchesFormat(
                            inputNumber,
                            format,
                            country.countryCode
                          );
                          return isMatch;
                        }
                        return false;
                      });
                      if (!result && !!!errors.mobile?.message) {
                        setError("mobile", {
                          message: "invalid mobile number.",
                        });
                      } else if (result && !!errors.mobile?.message) {
                        setError("mobile", {
                          message: undefined,
                        });
                      }
                      return result;
                    }}
                    onChange={(phone, meta: any) => {
                      setValue("country.code", meta.dialCode);
                      setValue("country.label", meta.name);
                      setValue(
                        "country.dial",
                        phone.replace(new RegExp(meta.dialCode), "")
                      );
                      setValue(
                        "mobile",
                        phone.replace(new RegExp(meta.dialCode), "")
                      );
                    }}
                  />

                  {errors.mobile?.message ? (
                    <FormHelperText>{errors.mobile?.message}</FormHelperText>
                  ) : null}
                </FormControl>
                <div className="mx-auto my-8 w-fit">
                  <FormControl size="md">
                    <div className="flex items-center w-full gap-3">
                      <Checkbox {...register("agreed")} required />
                      <p
                        style={{ margin: 0 }}
                        // className="flex items-center ml-3"
                      >
                        {t("landing.signup.acceptTerms")}
                        <span
                          onClick={() => setShowTermsModal(true)}
                          style={{
                            margin: "0 3px",
                            textDecoration: "none",
                          }}
                        >
                          <span className={"text-[#499873] cursor-pointer"}>
                            {t("landing.signup.terms")}
                          </span>
                        </span>
                        &
                        <span
                          onClick={() => setShowPrivacyPolicy(true)}
                          style={{
                            marginLeft: "3px",
                            textDecoration: "none",
                          }}
                        >
                          <span className={"text-[#499873] cursor-pointer"}>
                            {t("landing.signup.privacy")}
                          </span>
                        </span>
                        .
                      </p>
                    </div>
                  </FormControl>
                </div>
                <div className="text-center">
                  <Button
                    sx={{ maxWidth: "352px" }}
                    fullWidth
                    size="lg"
                    type="submit"
                    disabled={isLoading || !isValid || !agreed}
                  >
                    {t("commons.createBtn")}
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </Stack>
        <SuccessModal
          title={t("commons.successModalTitle")}
          content={t("landing.signup.accountCreated")}
          open={showModal}
        />
      </Shared>
      <LoadingModal isLoading={isLoading} />
      <TermsAndPoliciesModal
        title={t(termsTitle)}
        content={"t&c"}
        isOpen={showTermsModal}
        close={() => setShowTermsModal(false)}
      />
      <TermsAndPoliciesModal
        title={t(privacyPolicyTitle)}
        content={"privacyPolicy"}
        isOpen={showPrivacyPolicy}
        close={() => setShowPrivacyPolicy(false)}
      />
    </>
  );
}
