import { DangerousOutlined } from "@mui/icons-material";
import {
  Button,
  List,
  ListItem,
  Sheet,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Link, useSearchParams } from "react-router-dom";
import { SuccessToastConfig } from "../../components/Error";
import ConfirmDeleteModal from "../../components/Inventory/ConfirmDeleteModal";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { theme } from "../../components/Theme";
import { InventoryService } from "../../services/inventory.service";
import { getActiveBusiness } from "../../store/session";
import { formatDate } from "../../utils/formatDate";
import { getInitials } from "../../utils/misc";
import InviteModal, { IAccountBusiness } from "./InviteModal";
import useContainer from "./useContainer";

const ListCatalogue = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(
    searchParams.get("type") ?? "public"
  );
  const [selectedCatalog, setSelectedCatalog] = useState<string>("");
  // const loaderRef = useRef(null);
  const {
    fetchAllCatalogs,
    pagination,
    isLoading,
    setIsLoading,
    hasMore,
    catalogs,
    setCatalogs,
    confirmDelete,
    setConfirmDelete,
    setShowAlert,
    setAlertMessage,
  } = useContainer();

  const fetchCatalogue = () => {
    setIsLoading(true);
    fetchAllCatalogs({
      businessId: getActiveBusiness()?.id ?? "",
      limit: 10,
      offset: 0,
      catalogType: selectedTab.toUpperCase() as "PUBLIC" | "PRIVATE", // had to cast, will fix later
      isReset: true,
    });
  };

  useEffect(() => {
    fetchCatalogue();
  }, []);

  const handleChange = (
    _event: React.SyntheticEvent | null,
    value: string | number | null
  ) => {
    if (typeof value === "string") {
      setSelectedTab(value);
      setIsLoading(true);
      setCatalogs([]);
      fetchAllCatalogs({
        businessId: getActiveBusiness()?.id ?? "",
        limit: 10,
        offset: 0,
        catalogType: value.toUpperCase() as "PUBLIC" | "PRIVATE", // had to cast, will fix later
        isReset: true,
      });
    }
  };

  const handleRemove = () => {
    setIsLoading(true);
    InventoryService.removeCatalog({
      catalogId: selectedCatalog,
    })
      .then((res) => {
        setIsLoading(false);
        if (res) {
          setAlertMessage(t("catalog.catalogRemoved"));
          setShowAlert(true);
          fetchAllCatalogs({
            businessId: getActiveBusiness()?.id ?? "",
            limit: 10,
            offset: 0,
            catalogType: selectedTab.toUpperCase() as "PUBLIC" | "PRIVATE", // had to cast, will fix later
            isReset: true,
          });
        }
      })
      .catch((err) => console.error(err));
  };

  const CatalogueView = ({ tabValue }: { tabValue: string }) => {
    // useEffect(() => {
    //   const observer = new IntersectionObserver((entries) => {
    //     const target = entries[0];
    //     if (target.isIntersecting && hasMore && catalogs.length > 0) {
    //       fetchAllCatalogs({
    //         businessId: getActiveBusiness()?.id ?? "",
    //         limit: 10,
    //         offset: pagination.limit * pagination.current,
    //         catalogType: tabValue.toUpperCase() as "PUBLIC" | "PRIVATE",
    //         isReset: false,
    //       });
    //     }
    //   });

    //   if (loaderRef.current) {
    //     observer.observe(loaderRef.current);
    //   }

    //   return () => {
    //     if (loaderRef.current) {
    //       observer.unobserve(loaderRef.current);
    //     }
    //   };
    // }, [fetchAllCatalogs]);

    return (
      <>
        <List sx={{ marginTop: 3 }}>
          <InfiniteScroll
            pageStart={0}
            // initialLoad
            loadMore={(_page: number) => {
              // console.log("LOADMORE TRIGGERED", pagination.limit, page);
              fetchAllCatalogs({
                businessId: getActiveBusiness()?.id ?? "",
                limit: 10,
                offset: pagination.limit * pagination.current,
                catalogType: tabValue.toUpperCase() as "PUBLIC" | "PRIVATE",
                isReset: false,
              });
            }}
            hasMore={hasMore}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            {catalogs.map((cat) => {
              const accounts = cat.accounts?.map((account) => ({
                ...account,
                newlySelectedBusinessId: account.recipientBusiness,
              }));
              return (
                <ListItem
                  key={cat.id}
                  sx={{
                    paddingX: 0,
                  }}
                >
                  <div
                    className={`py-2 px-2.5 lg:py-4 my-1 lg:my-2 lg:flex algin-middle w-full rounded-lg lg:rounded-none border lg:border-t-0 lg:border-l-0 lg:border-r-0 lg:border-b-1 border-[${theme.colorSchemes.light.palette.others.disabled}]`}
                  >
                    <div className="mb-2">
                      {/* <Typography fontWeight={700} fontSize={16}>
                      <span className="text-sm lg:text-base ">
                        {cat.business.companyName}
                      </span>
                    </Typography> */}
                      <Typography fontSize={14}>
                        <span className="text-xs font-bold lg:text-sm">
                          {cat.catalogName}
                        </span>
                      </Typography>
                      <Typography
                        fontSize={14}
                        sx={{
                          color: theme.colorSchemes.dark.palette.text.secondary,
                        }}
                      >
                        <span className="text-xs lg:text-sm">
                          {t("commons.lastUpdated")}:{" "}
                          <span className="ml-2">
                            {formatDate(new Date(cat?.updatedAt ?? ""))}
                          </span>
                        </span>
                      </Typography>
                    </div>

                    <div className="flex items-center justify-center gap-3 ml-auto">
                      <UserListButton
                        selectedTab={selectedTab}
                        fetchCatalogue={fetchCatalogue}
                        accounts={accounts ?? []}
                        catalogId={cat.id}
                      />
                      <Button
                        variant="plain"
                        className="w-full mt-4 text-sm lg:mt-0 text-nowrap lg:text-base"
                        onClick={() => {
                          setSelectedCatalog(cat.id);
                          setConfirmDelete(true);
                        }}
                      >
                        {t("catalog.removeBtn")}
                      </Button>
                      <Link
                        to={`/client/catalogues/inventory/${cat.id}/items`}
                        className="mx-auto lg:ml-auto lg:mr-0"
                      >
                        <Button className="w-full mt-4 text-sm lg:mt-0 text-nowrap lg:text-base">
                          {t("catalog.viewCatalogBtn")}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </ListItem>
              );
            })}
          </InfiniteScroll>
        </List>
        {/* <div ref={loaderRef} className="flex justify-center w-full">
          {isLoading && "Loading..."}
        </div> */}
      </>
    );
  };

  return (
    <Sheet sx={{ backgroundColor: "transparent" }}>
      {!isLoading && (
        <Tabs
          aria-label="Basic tabs"
          value={selectedTab}
          onChange={handleChange}
          className="w-[90vw] md:w-[75vw]"
          sx={{
            backgroundColor: "transparent",
            marginTop: 4,
          }}
        >
          <TabList
            sx={{
              [`&& .${tabClasses.root}`]: {
                "&:hover": {
                  bgcolor: "transparent",
                },
                [`&.${tabClasses.selected}`]: {
                  "&::after": {
                    bgcolor: "primary.500",
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    height: 2,
                  },
                  color: "primary.plainColor",
                },
                bgcolor: "transparent",
                flex: "initial",
                paddingY: 2,
              },
            }}
          >
            <Tab
              value={"public"}
              key={"public"}
              sx={{
                fontWeight: "600",
                whiteSpace: "nowrap",
                minWidth: 100,
                flex: "none",
                scrollSnapAlign: "start",
                flexDirection: "column",
                paddingX: "3rem",
              }}
            >
              {t("catalog.catalogType.publicLabel")}
            </Tab>
            <Tab
              value={"private"}
              key={"private"}
              sx={{
                fontWeight: "600",
                whiteSpace: "nowrap",
                minWidth: 150,
                flex: "none",
                scrollSnapAlign: "start",
                flexDirection: "column",
                paddingX: "3rem",
              }}
            >
              {t("catalog.catalogType.privateLabel")}
            </Tab>
          </TabList>

          {/* default public empty tab */}
          <TabPanel
            value={"public"}
            key={"public"}
            className="px-0 py-0 lg:px-6"
          >
            {catalogs.length > 0 ? (
              <CatalogueView tabValue="public" />
            ) : (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                mt={"32px"}
              >
                <DangerousOutlined
                  color="primary"
                  sx={{ width: "100px", height: "100px" }}
                />
                <br />
                <Typography fontWeight={"lg"} fontSize={"sm"}>
                  {t("catalog.emptyCatalogPrompt")}
                </Typography>
              </Stack>
            )}
          </TabPanel>

          {/* default private empty tab */}
          <TabPanel
            value={"private"}
            key={"private"}
            className="px-0 py-0 lg:px-6"
          >
            {catalogs.length > 0 ? (
              <CatalogueView tabValue="private" />
            ) : (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                mt={"32px"}
              >
                <DangerousOutlined
                  color="primary"
                  sx={{ width: "100px", height: "100px" }}
                />
                <br />
                <Typography fontWeight={"lg"} fontSize={"sm"}>
                  {t("catalog.emptyCatalogPrompt")}
                </Typography>
              </Stack>
            )}
          </TabPanel>
        </Tabs>
      )}

      <ConfirmDeleteModal
        show={confirmDelete}
        onClick={handleRemove}
        onClose={() => {
          setConfirmDelete(false);
        }}
      />
      <LoadingModal isLoading={isLoading} />
    </Sheet>
  );
};

const UserListButton = ({
  catalogId,
  accounts,
  selectedTab,
  fetchCatalogue,
}: {
  fetchCatalogue: () => void;
  catalogId: string;
  selectedTab: string;
  accounts: IAccountBusiness[];
}) => {
  const accountCount = accounts.length;
  const business = getActiveBusiness();
  const [showModal, setShowModal] = useState(false);
  const showToast = () => {
    toast("Successfully sent invitation.", SuccessToastConfig);
  };

  return (
    <>
      <div
        onClick={() => setShowModal(true)}
        className="flex items-center justify-center cursor-pointer"
      >
        {accountCount >= 1 && (
          <div className="flex border font-bold  border-[#636B744D] items-center justify-center w-8 h-8 text-sm text-[#499873] uppercase bg-[#F1F8F4] rounded-full">
            {getInitials(accounts[0]?.profile?.fullName ?? accounts[0].email)}
          </div>
        )}
        {accountCount >= 2 && (
          <div className="flex border font-bold  border-[#636B744D] items-center justify-center w-8 h-8 -ml-3 text-sm text-[#499873] uppercase bg-[#F1F8F4] rounded-full">
            {getInitials(accounts[1]?.profile?.fullName ?? accounts[1].email)}
          </div>
        )}
        {accountCount > 2 && (
          <div className="flex items-center justify-center w-8 h-8 -ml-3 text-sm text-white bg-[#499873] rounded-full">
            +{accountCount - 2}
          </div>
        )}
      </div>
      {showModal && (
        <InviteModal
          selectedTab={selectedTab}
          catalogId={catalogId}
          businessId={business?.id ?? ""}
          accounts={accounts}
          show={showModal}
          onClose={() => {
            setShowModal(false);
            fetchCatalogue();
          }}
          showToast={showToast}
        />
      )}
    </>
  );
};

export default ListCatalogue;
