import { Box, Button, Input, Modal, Option, Select } from "@mui/joy";
import { Checkbox, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import CopyLinkButton from "../../components/CopyLinkButton";
import showErrorToast, { ErrorToastConfig } from "../../components/Error";
import DeleteIcon from "../../components/Icons/DeleteIcon";
import EmailIcon from "../../components/Icons/EmailIcon";
import InfoIcon from "../../components/Icons/InfoIcon";
import { TRACKER_CONSTANTS } from "../../constants/tracker.events";
import { AccountService } from "../../services/account.service";
import { CatalogService } from "../../services/catalog.service";
import { InventoryService } from "../../services/inventory.service";
import { TrackerService } from "../../services/tracker.service";
import { IBusinessResponse } from "../../types/business";
import { ICatalogInviteCreatePayload } from "../../types/catalog";
import { getInitials } from "../../utils/misc";

export type IAccountBusiness = IAccount & {
  businesses: IBusinessResponse[];
  newlySelectedBusinessId: string;
  recipientBusiness: string | null;
};

interface Props {
  show?: boolean;
  showToast: () => void;
  onClose: () => void;
  catalogId: string;
  selectedTab: string;
  businessId: string;
  accounts: IAccountBusiness[];
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  // px: 3,
  // py: 3,
  borderRadius: "32px",
  borderColor: "transparent",
  outline: "none",
  height: "90vh",
};

const InviteModal = ({
  selectedTab,
  show = false,
  onClose,
  catalogId,
  businessId,
  accounts,
  showToast,
}: Props) => {
  const [accountBusinessAcesses, setAccountBusinessAcesses] =
    useState<IAccountBusiness[]>(accounts);
  const { t } = useTranslation();

  const [businesses, setBusinesses] = useState<IBusinessResponse[]>([]);
  const [account, setAccount] = useState<IAccount | undefined>(undefined);
  const [business, setBusiness] = useState<string | undefined>(undefined);
  const [newlyAddedAccountBusinesses, setNewlyAddedAccountBusinesses] =
    useState<(IAccountBusiness & { invitationSent: boolean })[]>([]);

  const [businessAcessesToRemove, setBusinessAcessesToRemove] = useState<
    {
      businessId: string;
      catalogId: string;
      accountId: string;
      email: string;
    }[]
  >([]);

  const [error, setError] = useState("");
  const [isSubmitting, setisSubmitting] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedAccountBusinesses, setSelectedAccountBusinesses] = useState<
    { businessId: string; accountId: string; email: string }[]
  >([]);

  const removeMutation = useMutation({
    mutationFn: CatalogService.removeCatalogAccess,
    onSuccess: () => {
      setBusinessAcessesToRemove([]);
      setisSubmitting(false);
      handleClose();
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const invitationMutation = useMutation({
    mutationFn: CatalogService.sendEmailInvitation,
    onSuccess: () => {
      TrackerService.track(
        TRACKER_CONSTANTS.REGISTRATION_VIA_CATALOG.events
          .SellerSendInvitationToBuyer
      );
      setisSubmitting(false);
      setBusinessAcessesToRemove([]);
      showToast();
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const catalogInvitationMutation = useMutation({
    mutationFn: InventoryService.createCatalogInvite,
    onSuccess: () => {
      TrackerService.track(
        TRACKER_CONSTANTS.REGISTRATION_VIA_CATALOG.events
          .SellerSendInvitationToBuyer
      );
      if (businessAcessesToRemove.length > 0) {
        removeMutation.mutate(businessAcessesToRemove);
      } else {
        handleClose();
        setisSubmitting(false);
        setSelectedAccountBusinesses([]);
      }

      showToast();
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  const newInvitationMutation = useMutation({
    mutationFn: CatalogService.sendEmailInvitationToUnregisterUser,
    onSuccess: ({ data }) => {
      console.log(data);
      showToast();
    },
    onError: (error: any) => {
      toast(showErrorToast(error), ErrorToastConfig);
    },
  });

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const isPending =
    isSubmitting ||
    removeMutation.isPending ||
    catalogInvitationMutation.isPending;

  const handleClose = () => {
    setSelectedAccountBusinesses([]);
    setBusinessAcessesToRemove([]);
    setShowModal(false);
    onClose();
  };

  const handleRemove = () => {
    selectedAccountBusinesses.forEach((sab) => {
      if (sab.accountId) {
        setBusinessAcessesToRemove((prev) => [
          ...prev,
          {
            businessId: sab.businessId,
            catalogId,
            accountId: sab.accountId,
            email: sab.email,
          },
        ]);
      }
    });
    setAccountBusinessAcesses((prev) =>
      prev.filter(
        (p) =>
          !selectedAccountBusinesses.some(
            (sab) =>
              sab.accountId === p.id &&
              sab.businessId === p.newlySelectedBusinessId
          )
      )
    );
    setNewlyAddedAccountBusinesses((prev) =>
      prev.filter(
        (p) =>
          !selectedAccountBusinesses.some(
            (sab) =>
              (p.id ? sab.accountId === p.id : p.email === sab.email) &&
              sab.businessId === p.newlySelectedBusinessId
          )
      )
    );
    setSelectedAccountBusinesses([]);
  };

  const handleSendEmailUnregisterUser = (emails: string[]) => {
    const emailList = newlyAddedAccountBusinesses.filter(
      (naab) => emails.includes(naab.email) && !naab.invitationSent
    );
    if (emailList.length > 0) {
      const payload: ICatalogInviteCreatePayload[] = [];
      emailList.forEach((email) =>
        payload.push({
          catalog: catalogId,
          senderBusiness: businessId,
          catalogInvitationStatus: "PENDING",
          recipientEmail: email.email,
          recipientBusiness: null,
        })
      );
      newInvitationMutation.mutate({ catalogId, payload });
      setNewlyAddedAccountBusinesses((prev) =>
        prev.map((naab) =>
          emails.includes(naab.email) ? { ...naab, invitationSent: true } : naab
        )
      );
    }
  };

  const handleSendEmail = () => {
    invitationMutation.mutate({
      catalogId,
      accountIds: [...selectedAccountBusinesses.map((sab) => sab.accountId)],
    });
  };

  const sendIndividualEmail = (id: string) => {
    invitationMutation.mutate({
      catalogId,
      accountIds: [id],
    });
  };

  const handleApply = () => {
    const invitesPayload: ICatalogInviteCreatePayload[] = [];
    if (newlyAddedAccountBusinesses.length > 0) {
      newlyAddedAccountBusinesses
        .filter((naab) => !naab.invitationSent)
        .forEach((naab) => {
          invitesPayload.push({
            catalog: catalogId,
            senderBusiness: businessId,
            catalogInvitationStatus: "PENDING",
            recipientEmail: naab.email,
            recipientBusiness: naab.newlySelectedBusinessId
              ? naab.newlySelectedBusinessId
              : null,
          });
        });
      // invite new account to catalog.
    }
    accountBusinessAcesses
      .filter((aba) => aba.newlySelectedBusinessId !== aba.recipientBusiness)
      .forEach((aba) =>
        invitesPayload.push({
          catalog: catalogId,
          senderBusiness: businessId,
          catalogInvitationStatus: "PENDING",
          recipientEmail: aba.email,
          recipientBusiness: aba.newlySelectedBusinessId
            ? aba.newlySelectedBusinessId
            : null,
        })
      );
    if (invitesPayload.length > 0) {
      catalogInvitationMutation.mutate({
        catalogInvites: invitesPayload,
        catalogId: catalogId,
      });
    } else if (businessAcessesToRemove.length > 0) {
      removeMutation.mutate(businessAcessesToRemove);
    } else {
      handleClose();
      setisSubmitting(false);
    }
  };

  const debouncedSetSearchTerm = useCallback(
    debounce(async (value: string) => {
      const { account, businesses } =
        await AccountService.getBusinessByAccountEmail(value);
      if (account) {
        setAccount(account);
        if (businesses.length > 0) {
          setBusinesses(businesses);
        }
      }
    }, 300),
    []
  );

  const onSubmit = async () => {
    const isAlrdyExisted = [
      ...newlyAddedAccountBusinesses,
      ...accountBusinessAcesses,
    ].some(
      (aba) =>
        aba.email === emailInput && aba.newlySelectedBusinessId === business
    );
    if (isAlrdyExisted) {
      setError("This user already added to catalog");
      return;
    } else {
      if (businesses.length > 0 && account && businesses) {
        setNewlyAddedAccountBusinesses((prev) => [
          {
            ...account,
            businesses,
            newlySelectedBusinessId: business,
            invitationSent: false,
          } as any,
          ...prev,
        ]);
      } else {
        setNewlyAddedAccountBusinesses((prev) => [
          {
            email: emailInput,
            businesses: [],
            ...(account ? { ...account } : {}),
            invitationSent: false,
          } as any,
          ...prev,
        ]);
      }
      setEmailInput("");
      setBusiness("");
      setBusinesses([]);
    }
  };
  console.log({ newlyAddedAccountBusinesses });
  return (
    <Modal
      onClose={handleClose}
      open={showModal}
      style={{
        backgroundColor: "#32383E99",
        backdropFilter: "unset",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        justifyContent={"center"}
        className="md:w-[848px] w-[76.3dvw]"
      >
        <div className="flex flex-col h-full gap-6 px-4 py-2 lg:p-6">
          <Typography textAlign={"center"} fontWeight={700} fontSize={20}>
            {t("catalog.shareCatalog.title")}
          </Typography>
          <div className="lg:border lg:border-[#EAECF0] shadow-sm lg:p-6 p-0 flex flex-col gap-4 flex-1 overflow-hidden">
            {selectedTab === "public" && (
              <div className="bg-[#CFE5DA] md:flex flex-row py-2 px-4 items-center gap-4 rounded-lg hidden">
                <InfoIcon />
                <p className="text-sm font-normal text-[#101828]">
                  {t("catalog.shareCatalog.prompt")}
                </p>
              </div>
            )}
            {/* Email Input */}
            <div className="flex flex-col gap-4">
              <div className="flex flex-col items-start gap-4 md:flex-row">
                <div className="flex flex-col flex-1 w-full gap-4 lg:flex-row">
                  <div className="flex flex-col flex-1 gap-1">
                    <Typography className="hidden text-sm font-normal lg:block">
                      {t("catalog.shareCatalog.typeEmail")}
                    </Typography>
                    <Input
                      size="sm"
                      value={emailInput}
                      onChange={(e) => {
                        if (businesses.length > 0) {
                          setBusinesses([]);
                        }
                        setEmailInput(e.target.value);
                        if (validateEmail(emailInput)) {
                          debouncedSetSearchTerm(e.target.value);
                        }
                        setError("");
                      }}
                      type="text"
                      className="border border-[#475467] px-4 py-1 rounded-lg flex-1"
                    />
                    {emailInput && !validateEmail(emailInput) && (
                      <Typography className="text-sm text-danger-500">
                        {t("commons.error.form.invalid", { label: "Email" })}
                      </Typography>
                    )}
                    {error && error.length > 0 && (
                      <Typography className="text-sm text-danger-500">
                        {error}
                      </Typography>
                    )}
                  </div>
                  <div className="flex flex-col flex-1 gap-1">
                    <Typography className="hidden text-sm font-normal lg:block">
                      {t("landing.signup.companyName")}
                    </Typography>
                    <Select
                      size="sm"
                      placeholder="Choose a Company.."
                      disabled={businesses.length === 0}
                      className="w-full p-2 border border-[#475467] disabled:bg-[#EAECF0] rounded-lg"
                      value={business}
                      onChange={(
                        _e: React.SyntheticEvent | null,
                        value: string | null
                      ) => {
                        if (!value) {
                          return;
                        }

                        setBusiness(value);
                        setError("");
                      }}
                    >
                      {businesses.map((business) => (
                        <Option key={business.id} value={business.id}>
                          {business.companyName ?? "-"}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <Button
                  disabled={
                    isSubmitting ||
                    !validateEmail(emailInput) ||
                    (businesses.length > 0 && !business)
                  }
                  onClick={onSubmit}
                  variant="outlined"
                  className="px-4 py-1 mt-0 lg:mt-6 border border-[#499873] text-[#499873] text-sm disabled:border-[#98A2B3] disabled:text-[#98A2B3]"
                >
                  {t("commons.addBtn")}
                </Button>
              </div>
            </div>
            {/* Email list */}
            <div className="flex flex-col flex-1 overflow-hidden">
              <div className="flex items-center p-2 px-0 mt-6">
                {/* checkbox */}
                <div className="flex items-center flex-1 gap-2">
                  <Checkbox
                    onChange={() => {
                      if (
                        selectedAccountBusinesses.length > 0 &&
                        selectedAccountBusinesses.length ===
                          accountBusinessAcesses.length +
                            newlyAddedAccountBusinesses.length
                      ) {
                        setSelectedAccountBusinesses([]);
                      } else {
                        setSelectedAccountBusinesses([
                          ...accountBusinessAcesses.map((aba) => ({
                            businessId: aba.newlySelectedBusinessId,
                            accountId: aba.id,
                            email: aba.email,
                          })),
                          ...newlyAddedAccountBusinesses.map((aba) => ({
                            businessId: aba.newlySelectedBusinessId,
                            accountId: aba.id,
                            email: aba.email,
                          })),
                        ]);
                      }
                    }}
                    checked={
                      selectedAccountBusinesses.length > 0 &&
                      selectedAccountBusinesses.length ===
                        accountBusinessAcesses.length +
                          newlyAddedAccountBusinesses.length
                    }
                  />
                  <Typography className="text-sm font-medium">
                    Select all
                  </Typography>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    disabled={selectedAccountBusinesses.length === 0}
                    onClick={handleRemove}
                    className="rounded-full border border-[#475467] lg:px-4 lg:py-[10px] text-sm p-2 disabled:text-[#98A2B3] disabled:border-[#98A2B3]"
                  >
                    <div className="block lg:hidden">
                      <DeleteIcon
                        disabled={selectedAccountBusinesses.length === 0}
                      />
                    </div>
                    <p className="hidden lg:block">Remove</p>
                  </button>
                  <button
                    disabled={selectedAccountBusinesses.length === 0}
                    onClick={handleSendEmail}
                    className="text-[#499873] border disabled:text-[#98A2B3] disabled:border-[#98A2B3] border-[#499873] text-sm lg:px-4 lg:py-[10px] p-2 flex items-center rounded-full gap-2"
                  >
                    <EmailIcon
                      disabled={selectedAccountBusinesses.length === 0}
                    />
                    <p className="hidden lg:block">Send to Email & WhatsApp</p>
                  </button>
                </div>
              </div>
              <div
                className="flex-1 overflow-auto  border-t border-[#F2F4F7]"
                style={{
                  scrollbarWidth: "none",
                }}
              >
                {newlyAddedAccountBusinesses.map((account, index) => (
                  <AccountList
                    key={`${index}-${account.id}`}
                    account={account}
                    selectedAccountBusinesses={selectedAccountBusinesses}
                    sendIndividualEmail={sendIndividualEmail}
                    handleSendEmailUnregisterUser={
                      handleSendEmailUnregisterUser
                    }
                    // businessChangeHandler={(businessId: string) => {
                    //   const _newlyAddedAccountBusinesses = [
                    //     ...newlyAddedAccountBusinesses,
                    //   ];
                    //   _newlyAddedAccountBusinesses[
                    //     index
                    //   ].newlySelectedBusinessId = businessId;
                    //   setNewlyAddedAccountBusinesses(
                    //     _newlyAddedAccountBusinesses
                    //   );
                    // }}
                    onChange={() => {
                      if (
                        selectedAccountBusinesses.findIndex(
                          (sab) =>
                            (sab.accountId
                              ? sab.accountId === account.id
                              : sab.email === account.email) &&
                            sab.businessId === account.newlySelectedBusinessId
                        ) > -1
                      ) {
                        setSelectedAccountBusinesses((prev) =>
                          prev.filter(
                            (p) =>
                              !(
                                p.accountId === account.id &&
                                p.businessId === account.newlySelectedBusinessId
                              )
                          )
                        );
                      } else {
                        setSelectedAccountBusinesses((prev) => [
                          ...prev,
                          {
                            email: account.email,
                            accountId: account.id,
                            businessId: account.newlySelectedBusinessId,
                          },
                        ]);
                      }
                    }}
                  />
                ))}
                {accountBusinessAcesses.map((account, index) => (
                  <AccountList
                    key={`${index}-${account.id}`}
                    account={account}
                    selectedAccountBusinesses={selectedAccountBusinesses}
                    sendIndividualEmail={sendIndividualEmail}
                    handleSendEmailUnregisterUser={
                      handleSendEmailUnregisterUser
                    }
                    onChange={() => {
                      if (
                        selectedAccountBusinesses.findIndex(
                          (sab) =>
                            sab.businessId ===
                              account.newlySelectedBusinessId &&
                            sab.accountId === account.id
                        ) > -1
                      ) {
                        setSelectedAccountBusinesses((prev) =>
                          prev.filter(
                            (p) =>
                              !(
                                p.accountId === account.id &&
                                p.businessId === account.newlySelectedBusinessId
                              )
                          )
                        );
                      } else {
                        setSelectedAccountBusinesses((prev) => [
                          ...prev,
                          {
                            email: account.email,
                            accountId: account.id,
                            businessId: account.newlySelectedBusinessId,
                          },
                        ]);
                      }
                    }}
                    // businessChangeHandler={(businessId: string) => {
                    //   const _accountBusinessAcesses = [
                    //     ...accountBusinessAcesses,
                    //   ];
                    //   _accountBusinessAcesses[index].newlySelectedBusinessId =
                    //     businessId;
                    //   setAccountBusinessAcesses(_accountBusinessAcesses);
                    // }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-4">
            <CopyLinkButton
              url={`${
                window.location.href.split("/client")[0]
              }/catalog/${catalogId}/items`}
            />
            <button
              disabled={removeMutation.isPending || isSubmitting}
              onClick={handleApply}
              className="border bg-[#499873] px-8 py-[10px] flex items-center gap-2 rounded-full"
            >
              <Typography className="hidden text-sm text-white lg:block">
                {isPending ? "Loading...." : "Save Changes"}
              </Typography>
              <Typography className="block text-sm text-white lg:hidden">
                {isPending ? "Loading...." : "Apply"}
              </Typography>
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default InviteModal;

function AccountList({
  account,
  selectedAccountBusinesses,
  sendIndividualEmail,
  onChange,
  handleSendEmailUnregisterUser,
}: {
  account: IAccountBusiness;
  selectedAccountBusinesses: { businessId: string; accountId: string }[];
  sendIndividualEmail: (id: string) => void;
  handleSendEmailUnregisterUser: (emails: string[]) => void;
  onChange: () => void;
}) {
  const { t } = useTranslation();
  const business = account.businesses.find(
    (b) => b.id === account.newlySelectedBusinessId
  );
  return (
    <div
      key={account.id}
      className="flex items-center gap-2 lg:gap-4 p-2 px-0 border-b border-[#F2F4F7]"
    >
      <Checkbox
        checked={selectedAccountBusinesses.some(
          (sba) =>
            sba.accountId === account.id &&
            sba.businessId === account.newlySelectedBusinessId
        )}
        onChange={onChange}
      />
      <div className="flex flex-col flex-1">
        <div className="flex items-center gap-4">
          <div className="flex border font-bold  border-[#636B744D] items-center justify-center w-[32px] h-[32px] md:w-[50px] md:h-[50px] -ml-3 text-sm text-[#499873] uppercase bg-[#F1F8F4] rounded-full">
            {account.id && account.profile?.fullName
              ? getInitials(account.profile?.fullName)
              : getInitials(account.email)}
          </div>
          <div className="flex flex-col gap-1">
            {account.id && account.profile?.fullName && (
              <Typography
                className="text-sm font-semibold md:text-lg"
                color={"#101828"}
              >
                {account.profile?.fullName ?? "-"}
              </Typography>
            )}
            <Typography color={"#475467"} className="text-xs lg:text-base">
              {account.email}
            </Typography>
            {!account.profile?.fullName && (
              <Typography color={"#B41F1C"} className="text-sm">
                {t("commons.error.notRegisteredOnLinkzYet")}
              </Typography>
            )}
            <Typography color={"#101828"} className="text-sm font-semibold">
              {business?.companyName}
            </Typography>
            {/* <div className="block md:hidden">
              {account.id && account.businesses.length > 0 && (
                <select
                  className="w-[150px]"
                  value={account.newlySelectedBusinessId}
                  onChange={(e) => {
                    businessChangeHandler(e.target.value);
                  }}
                >
                  {account.businesses.map((business) => (
                    <option
                      value={business.id}
                      className="text-sm text-[#101828]"
                    >
                      {business.companyName ?? "-"}
                    </option>
                  ))}
                </select>
              )}
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="flex-1 hidden md:block">
        {account.id && account.businesses.length > 0 && (
          <select
            value={account.newlySelectedBusinessId}
            onChange={(e) => {
              businessChangeHandler(e.target.value);
            }}
          >
            {account.businesses.map((business) => (
              <option value={business.id} className="text-sm text-[#101828]">
                {business.companyName ?? "-"}
              </option>
            ))}
          </select>
        )}
      </div> */}
      <button
        onClick={() =>
          account.id
            ? sendIndividualEmail(account.id)
            : handleSendEmailUnregisterUser([account.email])
        }
      >
        <EmailIcon />
      </button>
    </div>
  );
}

const validateEmail = (email: string) => {
  const valid =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return valid.test(email.toLowerCase());
};
